import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi, resetLoggedUserDetail, setLoggedUserDetail } from "../../RTK/Login/userProfile";
import { Divider } from "primereact/divider";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import Axios from "axios";
import { ErrorMessage, Formik, Form, Field } from "formik";
import { Dropdown } from "primereact/dropdown";
import Swal from "sweetalert2";
import { fetchClientList, fetchUserList } from "../../RTK/Background/userProfileList";
import { fetchSiteList } from "../../RTK/Background/siteList";
import { fetchEmissionFactor } from "../../RTK/Background/emissionFactor";
import { resetOverallPendingData, resetPPFData } from "../../RTK/Background/pendingData";
import { API } from "../../constants/api_url";
import { fetchRFLibrary } from "../../RTK/Background/RFDCFLibrary";
import { DateTime } from "luxon";
import APIServices from "../../service/APIService";
import '../../Styles/login.scss'
const TVSLogin = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [rolelist, setRoleList] = useState([
        {
            name: "Admin",
        },
        {
            name: "User",
        },
    ]);
    const [roleselected, setRoleSelected] = useState({});
    const select = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);
    useEffect(() => {
        if (Object.keys(select).length !== 0) {

            if (select.role === "eisqradmin") {
                dispatch(fetchClientList());
                props.history.push("/config/add_new_client");
            }else    if (select.role === "consultantadmin") {
               
                props.history.push("/materiality_dashboard");
            } else {

                dispatch(fetchRFLibrary())
                dispatch(fetchEmissionFactor());
                dispatch(fetchUserList(admin_data.id));
                dispatch(fetchSiteList(admin_data.id));
                if (select.access === undefined || select.access === null) {
                    props.history.push("/client_home");
                } else if (select.access.navigos === true) {
                    props.history.push("/client_home")
                } else if (select.access.materiality === true) {
                    props.history.push("/materiality_dashboard")
                }
            }

        }
    }, [select]);
    useEffect(() => {
        // console.log(DateTime.fromISO("2023-12-09T10:35:37.007Z",{zone:'utc'}).toLocal().toFormat('yyyy LLL dd HH mm'))
        // localStorage.clear();
        localStorage.removeItem('token')
        dispatch(resetPPFData([]));
        dispatch(resetOverallPendingData([]));
        dispatch(resetLoggedUserDetail());

    }, []);

    const handleLogin = (values) => {
        // if (values.email === "admin@eisqr.com" && values.password === "ESJ@e!$Qu@6e") {
        //     let obj = {
        //         email: "admin@eisqr.com",
        //         role: "eisqradmin",
        //         information: {
        //             companyname: "Eisqr",
        //             config: [
        //                 {
        //                     admin: {
        //                         approver: false,
        //                     },
        //                 },
        //             ],
        //         },
        //     };

        //     localStorage.setItem("token", "eisqradmin");
        //     dispatch(setLoggedUserDetail(obj));
        // } else {
        APIServices.post(API.Login, {
            email: values.email.trim(),
            password: values.password.trim(),
        })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem("token", response.data.token);
                    dispatch(fetchApi(response.data.token));
                    // dispatch(setLoggedUserDetail({ name: 'Gopi' }))
                } else {
                    localStorage.removeItem("token");
                }
            })
            .catch((err) => {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: `Invalid Credentials`,
                    showConfirmButton: false,
                    timer: 1500,
                });
            });
        // }
    };

    const handleForgotPassword = (values) => {

        Swal.fire({
            title: "Enter your login mail ID",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Request",
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                return APIServices.post(API.ResetRequest, { email: login })
                    .then((response) => {
                        if (!response.statusText === 'OK') {

                            // throw new Error(response.error.message);
                        } else {
                            Swal.fire('Check your ' + login + ' inbox for reset link')
                        }


                    })
                    .catch((error) => {
                        console.log(error)
                        Swal.showValidationMessage(`Request failed: check given mail id is correct or contact admin if you issue persist `);
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        })
    };

    const validationsLogin = yup.object().shape({
        email: yup.string().email("Invalid Email ID").required("Email is mandatory"),
        password: yup.string().min(8, "Minimum 8 character required").required("Password is mandatory"),
        // role: yup
        //     .object().shape({ name: yup.string().required() })
        //     .required("Password is mandatory"),
        // companyid: yup
        //     .string()
        //     .min(1, "Enter Enterprise ID")
        //     .required("Enterprise ID is mandatory"),
    });

    const validationsRegister = yup.object().shape({
        email: yup.string().email("Invalid Email ID").required("Email is mandatory"),
        companyid: yup.string().min(1, "Enter Enterprise ID").required("Enterprise ID is mandatory"),
        password: yup.string().min(8, "Minimum 8 character required").required("Password is mandatory"),
        role: yup
            .object()
            .shape({
                name: yup.string().required(),
            })
            .required("Password is mandatory"),
        confirmation: yup
            .string()
            .oneOf([yup.ref("password"), null], "password not matches")
            .required("Confirmation of Password is required"),
    });

    return (
        <div className='col-12 font-lato p-0 flex justify-content-center align-items-center bg-white' style={{ marginTop: -10, width: '100vw', height: '100vh', position: 'relative' }}>
            <div style={{ position: 'absolute', top: 20, right: 20 }}>
                <div style={{ width: 180 }}>
                    <img src={require("../../assets/images/eisqr_logo_final_v1.png").default} alt="Login" className="w-full" />
                </div>
            </div>
            <div className="col-6 p-0">      <div >
                <img src={require("../../assets/tvs/tvs_login_admin.jpg").default} alt="Login" style={{ height: 'calc(100vh - 4px)' }} className="w-full" />
            </div></div>

            <div className="col-6 p-0  flex ">


                <div className="form-container  ml-7" style={{ width: '80%' }} >
                <h1 className="fs-26 fw-5 clr-navy" >Enterprise Admin </h1>
                    <div style={{ width: 70 }}>
                        <img src={require("../../assets/tvs/tvs_logo.png").default} alt="Login" className="w-full" />
                    </div>
                 
                  
                    <h1 className="fs-22 fw-5 mt-2 mb-2" >Welcome to Environment, Social and Governance </h1>
                   
                    <div style={{ width: '50%' }} >

                        <Formik initialValues={{}} onSubmit={handleLogin} validationSchema={validationsLogin}>
                            <Form  >

                                <div className="form-group mt-2">
                                    <label form="email" className='flex fs-16 fw-5 form-label-clr-white mb-2' > Email ID </label>

                                    <Field name="email" type="email" className="form-field p-3 " style={{ border: '1px solid #00000056 ', boxShadow: 'none' }} />

                                    <ErrorMessage component="span" name="email" className="form-error" />
                                </div>

                                <div className="form-group mt-2">
                                    <label className='flex fs-16 fw-5 form-label-clr-white mb-2' form="email"> Password </label> <Field name="password" type="password" style={{ border: '1px solid #00000056 ', boxShadow: 'none' }} className="form-field p-3 " />
                                    <ErrorMessage component="span" name="password" className="form-error" />
                                </div>
                                <button type="submit" className="sign-in-button mt-2"> Sign in as Admin</button>
                                <div style={{ display: "flex", justifyContent: "center", cursor: 'pointer' }} onClick={handleForgotPassword}>
                                    <p className="forgot-password">Forgot password?</p>
                                </div>


                            </Form>
                        </Formik>

                    </div>
                </div>
            </div>


        </div>
    );
};

export default TVSLogin;