import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi, resetLoggedUserDetail, setLoggedUserDetail } from "./RTK/Login/userProfile";
import { Divider } from "primereact/divider";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "./Styles/login.css";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import Axios from "axios";
import { ErrorMessage, Formik, Form, Field } from "formik";
import { Dropdown } from "primereact/dropdown";
import Swal from "sweetalert2";
import { fetchEF } from "./RTK/Emissions/emissionFactor";
import { fetchClientList, fetchUserList } from "./RTK/Background/userProfileList";
import { fetchSiteList } from "./RTK/Background/siteList";
import { fetchEmissionFactor } from "./RTK/Background/emissionFactor";
import { renderMonthlyReport } from "./components/BGHF/helper";
import { resetOverallPendingData, resetPPFData } from "./RTK/Background/pendingData";
import { API } from "./constants/api_url";
import { fetchRFLibrary } from "./RTK/Background/RFDCFLibrary";
import { DateTime } from "luxon";
import APIServices from "./service/APIService";
import '../src/Styles/login.scss'
const LoginNew = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [rolelist, setRoleList] = useState([
        {
            name: "Admin",
        },
        {
            name: "User",
        },
    ]);
    const [roleselected, setRoleSelected] = useState({});
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);
    useEffect(() => {
        if (Object.keys(login_data).length !== 0) {

            if (login_data.role === "eisqradmin") {
                dispatch(fetchClientList());
                props.history.push("/config/add_new_client");
            }else    if (login_data.role === "consultantadmin") {
               
                props.history.push("/materiality_dashboard");
            } else {
                // renderMonthlyReport(login_data.id, dispatch);
                dispatch(fetchRFLibrary())
                dispatch(fetchEmissionFactor());
                dispatch(fetchUserList(admin_data.id));
                dispatch(fetchSiteList(admin_data.id));
                if (login_data.access === undefined || login_data.access === null) {
                    props.history.push("/client_home");
                } else if (login_data.access.navigos === true) {
                    props.history.push("/client_home")
                } else if (login_data.access.materiality === true) {
                    props.history.push("/materiality_dashboard")
                }
            }

        }
    }, [login_data]);
    useEffect(() => {
        // console.log(DateTime.fromISO("2023-12-09T10:35:37.007Z",{zone:'utc'}).toLocal().toFormat('yyyy LLL dd HH mm'))
        // localStorage.clear();
        localStorage.removeItem('token')
        dispatch(resetPPFData([]));
        dispatch(resetOverallPendingData([]));
        dispatch(resetLoggedUserDetail());

    }, []);

    const handleLogin = (values) => {
        // if (values.email === "admin@eisqr.com" && values.password === "ESJ@e!$Qu@6e") {
        //     let obj = {
        //         email: "admin@eisqr.com",
        //         role: "eisqradmin",
        //         information: {
        //             companyname: "Eisqr",
        //             config: [
        //                 {
        //                     admin: {
        //                         approver: false,
        //                     },
        //                 },
        //             ],
        //         },
        //     };

        //     localStorage.setItem("token", "eisqradmin");
        //     dispatch(setLoggedUserDetail(obj));
        // } else {
        APIServices.post(API.Login, {
            email: values.email.trim(),
            password: values.password.trim(),
        })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem("token", response.data.token);
                    dispatch(fetchApi(response.data.token));
                    // dispatch(setLoggedUserDetail({ name: 'Gopi' }))
                } else {
                    localStorage.removeItem("token");
                }
            })
            .catch((err) => {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: `Invalid Credentials`,
                    showConfirmButton: false,
                    timer: 1500,
                });
            });
        // }
    };

    const handleForgotPassword = (values) => {

        Swal.fire({
            title: "Enter your login mail ID",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },  
            showCancelButton: true,
            confirmButtonText: "Request",
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                return APIServices.post(API.ResetRequest, { email: login })
                    .then((response) => {
                        if (!response.statusText === 'OK') {

                            // throw new Error(response.error.message);
                        } else {
                            Swal.fire('Check your ' + login + ' inbox for reset link')
                        }


                    })
                    .catch((error) => {
                        console.log(error)
                        Swal.showValidationMessage(`Request failed: check given mail id is correct or contact admin if you issue persist `);
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        })
    };

    const validationsLogin = yup.object().shape({
        email: yup.string().email("Invalid Email ID").required("Email is mandatory"),
        password: yup.string().min(8, "Minimum 8 character required").required("Password is mandatory"),
        // role: yup
        //     .object().shape({ name: yup.string().required() })
        //     .required("Password is mandatory"),
        // companyid: yup
        //     .string()
        //     .min(1, "Enter Enterprise ID")
        //     .required("Enterprise ID is mandatory"),
    });

    const validationsRegister = yup.object().shape({
        email: yup.string().email("Invalid Email ID").required("Email is mandatory"),
        companyid: yup.string().min(1, "Enter Enterprise ID").required("Enterprise ID is mandatory"),
        password: yup.string().min(8, "Minimum 8 character required").required("Password is mandatory"),
        role: yup
            .object()
            .shape({
                name: yup.string().required(),
            })
            .required("Password is mandatory"),
        confirmation: yup
            .string()
            .oneOf([yup.ref("password"), null], "password not matches")
            .required("Confirmation of Password is required"),
    });

    return (
        <div className='col-12 flex justify-content-center align-items-center' style={{ background: `url(${require('../src/assets/images/newui/login_bg.png').default})`,backgroundSize:'cover' , marginTop: -10, height: '100vh' }}>
        <div className="login-container">
            <div className="image-container">
                <img src={require("../src/assets/images/eisqr_logo_final_v1.png").default} alt="Login" className="login-image" />
            </div>
            <div className="form-container">
                <h1 className="login-header">Login</h1>
                <Formik initialValues={{}} onSubmit={handleLogin} validationSchema={validationsLogin}>
                    <Form  >

                        <div className="form-group">
                            <label form="email" className='fs-16 fw-5 form-label-clr-white' > Email ID </label>

                            <Field name="email" type="email" className="form-field" />

                            <ErrorMessage component="span" name="email" className="form-error" />
                        </div>

                        <div className="form-group">
                            <label   className='fs-16 fw-5 form-label-clr-white' form="email"> Password </label> <Field name="password" type="password" className="form-field"  />
                            <ErrorMessage component="span" name="password" className="form-error" />
                        </div>
                        <button type="submit" className="sign-in-button">Sign in</button>
                        <div style={{ display: "flex", justifyContent: "center", cursor: 'pointer' }} onClick={handleForgotPassword}>
                            <p className="forgot-password">Forgot username or password?</p>
                        </div>
                    

                    </Form>
                </Formik>
                {/* <div className="other-options">
                    <p className="no-account">Don't have an account?</p>
                    <p className="sign-up">SIGN UP</p>
                </div> */}
            </div>
        </div>
    </div>
    );
};

export default LoginNew;