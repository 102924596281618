import React, { useEffect, useState } from 'react'
import useForceUpdate from 'use-force-update'
import APIServices from '../../service/APIService'
import { API } from '../../constants/api_url'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { DateTime } from 'luxon'
import Swal from 'sweetalert2'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useSelector } from 'react-redux'


const AirPortConfig = () => {
    const forceUpdate = useForceUpdate()
    const selector = useSelector((state) => state.user.admindetail);

    const [data, setData] = useState([])
    const [obj, setObj] = useState({ title: '', long: '', lat: '', shortName: '' })
    const [visible, setVisible] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    useEffect(() => {
        APIServices.get(API.AirPortCode).then((res) => {
            setData(res.data)
        })
    }, [])
    useEffect(() => {
        if (visible) {
            document.addEventListener('paste', handlePaste);
        } else {
            document.removeEventListener('paste', handlePaste);
        }

        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, [visible]);

    const handlePaste = (event) => {
        const pastedData = event.clipboardData.getData('Text');
        const coords = pastedData.split(',').map(i => i.trim())
        if (coords.length === 2) {
            let loc = JSON.parse(JSON.stringify(obj))
            loc['lat'] = coords[0]
            loc['long'] = coords[1]
            setObj(loc)
            console.log(coords[0], coords[1], pastedData.trim().length)
        }

    }
    const updateObject = (obj, val) => {
        setObj((prev) => ({ ...prev, [obj]: val }))

    }
    const saveData = () => {
        setSubmitted(true)
        if (obj.lat.trim().length && obj.long.trim().length && obj.title.trim().length > 3 && obj.shortName.trim().length === 3) {
            let newObj = { title: obj.title, lat: obj.lat, long: obj.long, shortName: obj.shortName.trim().toLowerCase() }
            if (obj.id !== undefined) {

                APIServices.patch(API.AirPortCode_Edit(obj.id), { ...newObj, modified_on: DateTime.utc() }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(data))

                    let index = loc.findIndex(i => i.id === obj.id)
                    console.log(index)
                    if (index !== -1) {
                        loc[index] = { ...loc[index], ...newObj }
                        setData(loc)
                        setVisible(false)
                        setSubmitted(false)

                    }
                })
            } else {
                APIServices.post(API.AirPortCode, { ...newObj, created_on: DateTime.utc() }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(data))
                    loc.push(res.data)
                    setData(loc)
                    setVisible(false)
                    setSubmitted(false)
                })
            }
        }
    }
    const dialogFooter = (
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={() => { setVisible(false); setSubmitted(false) }} className="p-button-text" />
            <Button label="Save" icon="pi pi-check"
                onClick={() => {
                    saveData()
                }} />
        </div>
    );
    const editVal = (rowData) => {
        setObj(JSON.parse(JSON.stringify(rowData)))
        setVisible(true)
    }
    const deleteRow = (item) => {
        let loc = data
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                APIServices.delete(API.AirPortCode_Edit(item.id)).then((res) => {
                    let index = loc.findIndex((k) => { return k.id === item.id })
                    loc.splice(index, 1)
                    setData(loc)
                    forceUpdate()
                })
            }
        })


    }
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };
    const renderHeader = () => {
        return (
            <div className="table-header-container">


                <div className="table-header-actions py-4 d-flex justify-content-between">

                    <Button onClick={() => { setObj({ title: '', long: '', lat: '', shortName: '' }); setVisible(true); }} label="Add New" icon="pi pi-plus" className="p-button-primary" /* Add click event logic here */ />
                </div>
            </div>
        );
    };
    return (
        <div className="font-lato bg-white " style={{ height: "calc(100vh - 80px)" }}>
          {selector.role === 'eisqradmin' ?   <div className="card">        <h3> Airport IATA Code Configuration </h3>
                <DataTable
                    value={data}
                    dataKey="id"
                    header={renderHeader()}
                    //   header={edit === 1 && renderHeader()}
                    className="p-datatable-initiatives">

                    <Column
                        field="title"
                        header="Aiport Name"

                    />
                    <Column
                        field="shortName"
                        header="IATA Code"

                    />

                    <Column
                        field="lat"
                        header="Latitude"

                    />
                    <Column
                        field="long"
                        header="Longitude"

                    />
                    <Column
                        body={actionBodyTemplate}
                        header="Action"

                    />

                </DataTable>

                <Dialog header="Add Airport" visible={visible} style={{ width: '50vw' }} footer={dialogFooter} onHide={() => { setVisible(false) }} modal>

                    <div className="p-fluid row p-3">
                        <div className="p-field col-12  ">
                            <label htmlFor="title">Airport Name</label>
                            <InputText
                                id="title"
                                value={obj.title}
                                onChange={(e) => { updateObject("title", e.target.value) }}
                                placeholder='Name'
                            />

                            {submitted && obj.title.trim().length <= 3 && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Enter Airport Name
                                </small>
                            )}
                        </div>


                        <div className="p-field col-12 ">
                            <label htmlFor="description">Airport IATA Code </label>
                            <InputText id="description" value={obj.shortName} onChange={(e) => { updateObject('shortName', e.target.value) }} />

                            {submitted && obj.shortName.trim().length !== 3 && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Enter IATA Code
                                </small>
                            )}
                        </div>



                        <div className="p-field col-6 ps-0 pb-4">
                            <label htmlFor="targetReduction">Latitude</label>
                            <InputText id="targetReduction" value={obj.lat} onChange={(e) => { updateObject('lat', e.target.value) }} />

                            {submitted && !obj.lat.trim().length && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Latitude is required
                                </small>
                            )}
                        </div>

                        <div className="p-field col-6 ps-0 pb-4">
                            <label htmlFor="targetReduction">Longitude</label>
                            <InputText id="targetReduction" value={obj.long} onChange={(e) => { updateObject('long', e.target.value) }} />

                            {submitted && !obj.long.trim().length && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Longitude is required
                                </small>
                            )}
                        </div>

                    </div>
                </Dialog>
            </div>
            : (
                <div className="col-12 card">You have no rights to access this page </div>
            )
            }
        </div>
    )
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AirPortConfig, comparisonFn);