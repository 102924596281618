import React, { useEffect, useState, useRef, createRef } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import FormBuilder from "../../components/FormBuilder";
import { MultiSelect } from 'primereact/multiselect';
import { FaRegSquare, FaRegCheckSquare, FaMinusSquare } from "react-icons/fa";
import { MdPlayArrow, MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { TbCheckbox } from "react-icons/tb"
import { IoMdArrowDropright, IoMdCheckboxOutline } from "react-icons/io";
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import { Button } from "primereact/button";
import { Route, useHistory, useLocation } from "react-router-dom";
import { Tooltip } from 'primereact/tooltip'
import { TabView, TabPanel } from "primereact/tabview";
import { OrderList } from "primereact/orderlist";
import useForceUpdate from "use-force-update";
import { ListBox } from "primereact/listbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import moment from "moment";
import $ from 'jquery'
import axios from "axios";
import IATACodes from "../../assets/dummy/IATACodes";
import { AutoComplete as AC } from "primereact/autocomplete";
import { GoogleMap, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import readXlsxFile from "read-excel-file";

import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { setRef } from "@fullcalendar/core";
import { SplitButton } from "primereact/splitbutton";
import Swal from "sweetalert2";
import "react-image-crop/dist/ReactCrop.css";
import { API } from "../../constants/api_url";
import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";

import { useSelector } from "react-redux";

import cx from "classnames";

import { render } from "react-dom";
import { hardcoded } from "../../client/constants/hardcodedid";
import APIServices from "../../service/APIService";
import { DateTime } from "luxon";
const google = window.google;

window.jQuery = $;
window.$ = $;
require("jquery-ui-sortable");
require("formBuilder");
// (0.79/1000)

const RFLibrary = () => {
    let dtref = useRef(null)
    const hardcodedrf = [
        85, 89, 102, 104, 110,
        111, 112, 113, 114, 115,
        116, 117, 118, 121, 133, 134, 139, 140, 148, 149, 150, 151, 181, 182, 183
    ]
    const selector = useSelector((state) => state.user.admindetail)
    const forceUpdate = useForceUpdate()
    const his = useHistory()
    const [formfilter, setFormFilter] = useState('')
    const [formbk, setFormbk] = useState([])
    const [selectedform, setSelectedForm] = useState({ title: '', tags: [] })
    const [prevdialog, setPrevDialog] = useState(false);
    const [items, setItems] = useState([])
    const [searchstr, setSearch] = useState('')
    const [adddialog, setAddDialog] = useState(false)
    const [title, setTitle] = useState('')
    const [form, setForm] = useState([])
    const [catlist,setCatList] =useState([])
    const [toplist,setTopList] =useState([])
    const [submitted, setSubmitted] = useState(false)
    const [clienttag, setClientTag] = useState([])
    useEffect(() => {
        let category_string = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics" }] } }]


        }


        APIServices.get(API.RF).then((res) => {
            APIServices.get(API.Categories + `?filter=${encodeURIComponent(JSON.stringify(category_string))}`).then((res2) => {
                setCatList(res2.data.map(i=> ({title: i.title})))
                setTopList(res2.data.flatMap(i=> i.newTopics).map(i=> ({title: i.title})))
                const result = res2.data.flatMap(category => {
                    // If there are no newTopics, return category with placeholders
                    if (!category.newTopics || category.newTopics.length === 0) {
                        return [{ ...category, newTopic: null, newMetric: null }];
                    }

                    // Process each newTopic
                    return category.newTopics.flatMap(topic => {
                        // If there are no newMetrics, return combined category and topic with a placeholder for newMetric
                        if (!topic.newMetrics || topic.newMetrics.length === 0) {
                            return [{ ...category, newTopic: topic, newMetric: null }];
                        }

                        // Process each newMetric
                        return topic.newMetrics.map(metric => {
                            if (Array.isArray(metric.data1) && metric.data1[0].type === 1 && metric.data1[0].rf) {
                                return { category: category.title, topic: topic.title, metric: metric.title, rf: metric.data1[0].rf };
                            }

                        });
                    });
                }).filter(i => i)
                for (let i = 0; i < res.data.length; i++) {
                    console.log(res.data[i])
                    res.data[i].cattop = result.find(x => x.rf === res.data[i].id) 
                    res.data[i].category = result.find(x => x.rf === res.data[i].id) ? result.find(x => x.rf === res.data[i].id).category : ''
                     res.data[i].topic = result.find(x => x.rf === res.data[i].id) ? result.find(x => x.rf === res.data[i].id).topic : ''
                }
                console.log(res.data)
                setForm(res.data);
                setFormbk(res.data)
            })
        })
        APIServices.get(API.UserProfile).then((res) => {
            let locuser = [];

            res.data.forEach((item) => {
                if (item.role === "clientadmin" && item.information.enterpriseid !== undefined) {
                    locuser.push({
                        name: item.information.enterpriseid,
                        id: item.id,
                    });
                }
            });

            setClientTag(locuser);
        });

    }, []);
    const getCatTop = (id, cat) => {
        let text = ''



        return text
    }
    const removeHTMLTag = (html) => {
        return html.replace(/(<([^>]+)>)/gi, "")
            .replace(/\n/g, " ")
            .replace(/&nbsp;/g, " ")
    }
    const renderPreview = () => {


        if (prevdialog && selectedform.data1.length !== 0) {

            let data = selectedform.data1


            return data.map((field, ind) => {
                if (field.type === 'paragraph') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">

                            <p>{removeHTMLTag(field.label)}</p>
                        </div>
                    )
                } else if (field.type === 'date') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'date' + ind}>{removeHTMLTag(field.label)}</label>
                            <Calendar disabled showIcon ></Calendar>
                        </div>
                    )
                } else if (field.type === 'text') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'text' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputText disabled></InputText>
                        </div>
                    )
                } else if (field.type === 'textarea') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'textarea' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputTextarea disabled></InputTextarea>
                        </div>
                    )
                } else if (field.type === 'number') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'textno' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputText keyfilter={'num'} disabled></InputText>
                        </div>
                    )
                } else if (field.type === 'select') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'select' + ind}>{removeHTMLTag(field.label)}</label>
                            <Dropdown options={field.values} ></Dropdown>
                        </div>
                    )
                } else if (field.type === 'radio-group') {
                    return (
                        <>

                            <div className="card   flex-wrap justify-content-center gap-3">
                                <label htmlFor={'radio' + ind} style={{ marginBottom: 15 }}>{removeHTMLTag(field.label)}</label>
                                {field.values.map((option) => {
                                    return (
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient1" name={option.label} value={option.value} checked={option.selected} />
                                            <label htmlFor="ingredient1" className="ml-2">{option.label}</label>
                                        </div>
                                    )
                                })

                                }
                            </div>
                        </>
                    )
                } else if (field.type === 'checkbox-group') {
                    return (
                        <>

                            <div className="card   flex-wrap justify-content-center gap-3">
                                <label htmlFor={'checkbox' + ind} style={{ marginBottom: 15 }}>{removeHTMLTag(field.label)}</label>
                                {field.values.map((option) => {
                                    return (
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient1" name={option.label} value={option.value} checked={option.selected} />
                                            <label htmlFor="ingredient1" className="ml-2">{option.value}</label>
                                        </div>
                                    )
                                })

                                }
                            </div>
                        </>

                    )
                }
            })
        }
    }
    const exportExcel = async () => {
        let newObj = {}, json = []
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Enter Password to Export DF Library to Excel
          </div>`,
            input: 'password',
            inputLabel: 'Password',
            confirmButtonColor: 'red',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,
            inputAttributes: {
                maxlength: 10,
                autocapitalize: 'off',
                autocorrect: 'off'
            }
        })

        if (password === 'getdfnow') {
            formbk.forEach((item) => {
                newObj = {}
                newObj['Id'] = item.id
                newObj['Title'] = item.title
                newObj['Type'] = hardcoded.rf.includes(item.id.toString()) ? 'Automated' : 'Manual'
                newObj['DataPoints'] = item.data1.map(i => i.type !== 'paragraph' && i.name).join(',')
                newObj['Total_No_of_Quantitative_Fields'] = item.data1.filter(i => i.type === 'number').length
                newObj['Tag Type'] = (!item.tags || !item.tags.length) ? 'Default' : 'CSF'
                newObj['Tag'] = (!item.tags || !item.tags.length) ? '' : clienttag.filter(i => item.tags.includes(i.id)).map(i => i.name).join(', ')
                json.push(newObj)
            })
            const ws = XLSX.utils.json_to_sheet(json)
            let sheet_name = 'RF Listing_' + DateTime.utc().toLocal().toFormat('dd_LL_yyyy') + '_'
            const wb = {
                Sheets: { ['RF Listing']: ws },
                SheetNames: ['RF Listing'],
            };

            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

            const data = new Blob([excelBuffer], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            FileSaver.saveAs(data, sheet_name + ".xlsx");
        }
    }
    const checkDPStatus = (data) => {
        if (data === null || data === undefined) {
            return { result: false, data: '0/0' }
        } else if (data.length === 0) {
            return { result: false, data: '0/0' }
        } else if (data.length !== 0) {
            let total = 0, count = 0

            data.forEach((item) => {

                if (item.type !== 'paragraph' && item.type !== 'file' && item.type !== 'date') {
                    total = total + 1
                    if (item.name.startsWith('DP')) {
                        count = count + 1
                    }

                }
            })
            return { result: true, data: count + '/' + total }
        } else {
            return { result: false, data: '0/0' }
        }
    }
    const nameTemplate = (rowData) => {
        console.log(rowData.id)
        return (
            <>
                <span style={{ color: rowData.data1.length === 0 && 'red' }}> {rowData.title}</span>
            </>
        )
    }
    const representativesItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">

                <span>{option.title}</span>
            </div>
        );
    };
    const categoryFilterTemplate = (options) => {
        console.log(options.value)
        return <MultiSelect value={options.value} options={catlist} itemTemplate={representativesItemTemplate} onChange={(e) => options.filterCallback(e.value)} optionLabel="title" optionValue="title" placeholder="Category" className="p-column-filter" />;
    };
    const topicFilterTemplate = (options) => {
        console.log(toplist)
        return <MultiSelect value={options.value} options={toplist} itemTemplate={representativesItemTemplate} onChange={(e) => options.filterCallback(e.value)} optionLabel="title" optionValue="title" placeholder="Category" className="p-column-filter" />;
    };

    const formIDTemplate = (rowData) => {
        return (
            <>
                <span> DF{rowData.id}</span>
            </>
        )
    }
    const categoryTemplate = (rowData) => {
        return (
            <>
                <span> {rowData.cattop ? rowData.cattop.category : ''}</span>
            </>
        )
    }
    const topicTemplate = (rowData) => {
        return (
            <>
                <span> {rowData.cattop ? rowData.cattop.topic : ''}</span>
            </>
        )
    }

    const editDCF = (item) => {
        his.push({ pathname: 'edit_rf', state: item })
    }
    const cloneDF = async (item) => {
        let newObj = JSON.parse(JSON.stringify(item))
        newObj.title = item.title + ' _Cloned'
        delete newObj.updated
        delete newObj.id
        newObj.data2 = []
        delete newObj.modifier_id
        newObj.comments = ''
        newObj.suffix = 'DF'
        newObj.created = DateTime.utc()
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to clone "${item.title}" Form
  </div>`,

            confirmButtonColor: 'green',
            showCancelButton: true,

            confirmButtonText:
                'Clone',

        })
        if (accept) {
            APIServices.post(API.RF, newObj).then((res) => {
                if (res.status === 200) {
                    let loc = JSON.parse(JSON.stringify(form))
                    loc.push(res.data)
                    setFormbk(loc)
                    setForm(loc)
                    forceUpdate()
                    setTimeout(() => {
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Form Cloned Successfully",
                            showConfirmButton: false,
                            timer: 1000,
                        });
                    })



                }
            })
        }
    }
    const search = (event) => {

        setSearch(event)
        let _items = formbk.filter((k) => { return (k.title.trim().toLowerCase().includes(event.trim().toLowerCase()) || k.id.toString().includes(event.trim()) || k.tags && k.tags.length && clienttag.filter(i => k.tags.includes(i.id)).map(i => i.name.trim().toLowerCase()).some(i => i.includes(event.trim().toLowerCase())) || (k.cattop && (k.cattop.category.trim().toLowerCase().includes(event.trim().toLowerCase()) || k.cattop.topic.trim().toLowerCase().includes(event.trim().toLowerCase()))) ) })
        setForm(_items);
    }
    const dpidStatus = (rowData) => {

        return (
            <>
                <Tooltip target={".csf" + rowData.id}>
                    {
                        (!rowData.tags || !rowData.tags.length) ?
                            ''
                            :
                            clienttag.filter(i => rowData.tags.includes(i.id)).map(i => <>{i.name}  <br />  </>)

                    }
                </Tooltip>


                <span className={(!rowData.tags || !rowData.tags.length) ? 'status-tag-gray' : 'status-tag-navy csf' + rowData.id}  > {(!rowData.tags || !rowData.tags.length) ? 'Default' : 'CSF'}</span>
            </>
        )
    }
    const deleteDCF = async (item) => {
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete "${item.title}" from DF Library
          </div>`,
            input: 'password',
            inputLabel: 'Password',
            confirmButtonColor: 'red',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,
            inputAttributes: {
                maxlength: 10,
                autocapitalize: 'off',
                autocorrect: 'off'
            }
        })

        if (password === 'vadapooche') {

            APIServices.delete(API.RF + '/' + item.id).then((a) => {
                let loc = form;
                loc.splice(form.findIndex((k) => { return k.id === item.id }), 1);
                setFormbk(loc)
                setForm(loc)
                forceUpdate()
            })
        }

    }
    const actionBodyTemplate = (rowData) => {

        return (
            !hardcoded.rf.includes(rowData.id.toString()) ?
                <>
                    <div className="actions">
                        <Button
                            icon="pi pi-pencil"
                            className="mr-2" style={{ width: 30, height: 30, borderRadius: 15, background: 'dodgerblue' }}
                            onClick={() => {
                                editDCF(rowData)
                            }}
                        />
                        {rowData.data1.length !== 0 &&
                            <>
                                <Button
                                    icon="pi pi-copy"
                                    className="mr-2 " style={{ width: 30, height: 30, borderRadius: 15, background: 'gray' }}
                                    onClick={() => {
                                        cloneDF(rowData)
                                    }}
                                />
                                <Button
                                    icon="pi pi-eye"
                                    className="mr-2" style={{ width: 30, height: 30, borderRadius: 15, background: 'mediumseagreen' }}
                                    onClick={() => {
                                        window.open(window.location.origin + '/df_preview/' + rowData.id)
                                    }}
                                />
                                <Button
                                    icon="pi pi-download"
                                    className="mr-2 " style={{ width: 30, height: 30, borderRadius: 15, background: 'gray' }}
                                    onClick={() => {
                                        exportDF(rowData.data1, rowData.title + '.xlsx')
                                    }}
                                />
                            </>
                        }
                        <Button
                            icon="pi pi-trash"
                            className="mr-2 " style={{ width: 30, height: 30, borderRadius: 15, background: 'palevioletred' }}
                            onClick={() => {
                                deleteDCF(rowData)
                            }}
                        />
                    </div>
                </> :
                <>
                    Disabled for Hardcoded Forms
                </>

        )
    }
    const prevDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setPrevDialog(false) }} />
        </>
    );
    const submitFooter = (
        <>
            <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setAddDialog(false);
                }}
            />
            <Button
                label="Save & Exit"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    if (selectedform.title.trim().length !== 0) {
                        addNewRF()
                    }
                }}
            />
        </>
    );
    const exportDF = (data, fileName) => {

        const ws = XLSX.utils.aoa_to_sheet([]);
        let rowIndex = 0;
        let merges = [];

        // Add headers
        const headers = ["Type", "DPID", "Label", "Options"];
        XLSX.utils.sheet_add_aoa(ws, [headers], { origin: rowIndex++ });

        // Add data rows
        data.forEach(item => {
            const baseRow = [item.type, item.name, item.label.replace(/(<([^>]+)>)/gi, "")
                .replace(/\n/g, " ")
                .replace(/&nbsp;/g, " ").replace('&amp;', '&'), ''];

            if (item.values && item.values.length > 0) {
                item.values.forEach((val, valIndex) => {
                    const optionRow = baseRow.slice();
                    optionRow[3] = val.label;
                    XLSX.utils.sheet_add_aoa(ws, [optionRow], { origin: rowIndex++ });
                });

                // Merge the Type and Label cells
                merges.push({ s: { r: rowIndex - item.values.length, c: 0 }, e: { r: rowIndex - 1, c: 0 } });
                merges.push({ s: { r: rowIndex - item.values.length, c: 1 }, e: { r: rowIndex - 1, c: 1 } });
                merges.push({ s: { r: rowIndex - item.values.length, c: 2 }, e: { r: rowIndex - 1, c: 2 } });

            } else {
                XLSX.utils.sheet_add_aoa(ws, [baseRow], { origin: rowIndex++ });
            }
        });

        // Apply merges to the worksheet
        ws['!merges'] = merges;

        // Create workbook and add the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Write workbook and save to file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        FileSaver.saveAs(dataBlob, DateTime.utc().toLocal().toFormat('dd_LLL_yyyy_') + fileName);
    }
    const addNewRF = () => {
        APIServices.post(API.RF, { title: selectedform.title.trim(), tags: selectedform.tags, data1: [], suffix: "DF", curator_id: selector.id, comments: "", created: moment.utc() }).then((res) => {
            let loc = JSON.parse(JSON.stringify(form))
            loc.unshift(res.data)
            setAddDialog(false)
            setForm(loc)
            setFormbk(loc)
            forceUpdate()

        })
    }
    return (
        <div className="grid p-fluid">
            {selector.role === "eisqradmin" ? (
                <div className="col-12 md:col-12">
                    <div className="card ">
                        <div
                            className="col-12 md:col-12"
                            style={{
                                textDecoration: "underline",
                                textAlign: "center",
                                textDecorationColor: "green",
                            }}
                        >
                            <h5>DF Library</h5>
                        </div>

                        <div className="flex grid col-12 justify-content-between" style={{ marginBottom: 20, marginTop: 20 }}>
                            <div style={{ width: 400 }}>
                                <span className="p-input-icon-left" >
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search DF/Applicability/Category/Topic" value={searchstr} onChange={(e) => { search(e.target.value) }} />
                                </span>
                            </div>
                            <div>
                                <Button className="mr-2 " style={{ width: 100 }} label="Export" icon="pi pi-arrow-circle-down" onClick={exportExcel} />
                                <Button
                                    icon="pi pi-plus"
                                    className="mr-2"
                                    onClick={() => {
                                        setSelectedForm({ title: '', tags: [] })
                                        setAddDialog(true)
                                    }}
                                ></Button>
                            </div>
                        </div>
                        <DataTable

                            ref={dtref}
                            value={form.sort((a, b) => { return b.id - a.id }, 0)}
                            scrollable
                            filters={{category: { value: null, matchMode: 'in' },topic: { value: null, matchMode: 'in' }} }
                            paginator
                            rows={20}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} DF"

                            emptyMessage="No DF found."

                            responsiveLayout="scroll"
                        >


                            <Column
                                field="title"
                                header="Form Name"
                                body={nameTemplate}
                                sortable
                                headerStyle={{
                                    width: "40%",
                                    background: '#EEF2FF'
                                }}
                            >

                            </Column>
            
                            <Column
                                filterField="category"
                             
                                showFilterMatchModes={false}
                               
                                header="Category"
                                body={categoryTemplate}
                                filter
                                filterElement={categoryFilterTemplate}
                                headerStyle={{

                                    background: '#EEF2FF'
                                }}
                            >

                            </Column>

                            <Column
                              filterField="topic"
                             
                              showFilterMatchModes={false}
                                header="Topic"
                                body={topicTemplate}
                                filter
                                filterElement={topicFilterTemplate}
                                headerStyle={{

                                    background: '#EEF2FF'
                                }}
                            >

                            </Column>
                            <Column
                                field="id"
                                header="ID"
                                body={formIDTemplate}

                                headerStyle={{

                                    background: '#EEF2FF'
                                }}
                            >

                            </Column>
                            <Column
                                field="id"
                                header="Applicability"
                                body={dpidStatus}

                                headerStyle={{
                                    width: "5%",
                                    background: '#EEF2FF'
                                }}
                            >

                            </Column>
                            <Column
                                field="created"
                                header="Created On"
                                body={(rowData) => { return (<>{moment(rowData.created).local().format('DD MMM YYYY, hh:mm A')}</>) }}
                                headerStyle={{
                                 
                                    background: '#EEF2FF'
                                }}
                            >
                            </Column>
                            <Column
                                field="updated"
                                header="Last Modified"
                                body={(rowData) => { return (<>{moment(rowData.updated).local().format('DD MMM YYYY, hh:mm A')}</>) }}
                                headerStyle={{
                            
                                    background: '#EEF2FF'
                                }}
                            >

                            </Column>
                            <Column header="Action" headerStyle={{
                                background: '#EEF2FF', width: '15%'
                            }} body={actionBodyTemplate}> </Column>
                        </DataTable>
                        <Dialog
                            visible={adddialog}
                            header={'Add New DF'}
                            modal
                            style={{ width: '60%' }}
                            footer={submitFooter}
                            onHide={() => {
                                setAddDialog(false);
                            }}
                        >
                            <div className="col-12">
                                <div className="flex flex-column gap-2">
                                    <label className="m-2" htmlFor="username">DF Title</label>
                                    <InputText id="username" className="m-2" aria-describedby="username-help" value={selectedform.title} onChange={(e) => { setSelectedForm((prev) => ({ ...prev, title: e.target.value })) }} />
                                    <small id="username-help" className="m-2" style={{ color: 'red' }}>
                                        {selectedform.title.trim().length === 0 && 'please enter title '}
                                    </small>
                                </div>
                                <div className="flex flex-column gap-2">
                                    <label className="m-2" htmlFor="username">Tag</label>
                                    <MultiSelect value={selectedform.tags} optionValue="id" onChange={(e) => { setSelectedForm((prev) => ({ ...prev, tags: e.value })) }} style={{ width: '100%' }} options={clienttag} optionLabel="name" display="chip"
                                        placeholder="Select Applicability" maxSelectedLabels={3} className="w-full " />

                                </div>
                            </div>

                        </Dialog>
                    </div>
                </div>

            ) :
                <div className="card col-12">You have no rights to access this page</div>

            }
            <Dialog
                visible={prevdialog}
                style={{
                    width: "450px",
                }}
                header={selectedform.title + " preview"}
                modal
                className="p-fluid"
                footer={prevDialogFooter}
                onHide={() => { setPrevDialog(false) }}
            >
                {renderPreview()}
            </Dialog>
        </div>)
}
export default RFLibrary