import React, { useState, useEffect } from "react";
import "../reports/toc_style.css";
import { useHeadsObserver } from "./hooks";
import * as XLSX from "xlsx";
import useForceUpdate from "use-force-update";
import $ from "jquery";
import Axios from "axios";
import { API } from "../../constants/api_url";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { saveAs } from "file-saver";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Dropdown } from 'primereact/dropdown'
import APIServices from "../../service/APIService";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
// pdfMake.fonts = {
//     Roboto: {
//         normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
//         bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
//         italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
//         bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
//       }
// }
const { DateTime } = require("luxon");

window.jQuery = $;
window.$ = $;
const dcf_id = [11, 10, 15, 72, 16, 36];
const EcoVadisReport = () => {
    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const [headings, setHeadings] = useState([]);
    const { activeId } = useHeadsObserver();
    const [tableData, setTableData] = useState([]);
    const [workbook, setWorkbook] = useState(null);
    const [firstSheet, setFirstSheet] = useState(null);
    const [year, setYear] = useState(2022);
    const [rfData, setRFData] = useState({});
    const admin_data = useSelector((state) => state.user.admindetail);
    const emissionFactor = useSelector((state) => state.emissionfactor.emissionFactor);
    const locationList = useSelector((state) => state.sitelist.locationList);
    const siteList = useSelector((state) => state.sitelist.siteList);
    // const rflibrary = useSelector((state) => state.library.rf)

    const [dcfass, setDCFAss] = useState([]);
    const [dcflist, setDcfList] = useState([]);
    const [response, setResponse] = useState([]);
    const [report, setReport] = useState([]);
    const [reportEF, setReportEF] = useState([]);
    const [dpreport, setDpReport] = useState([]);
    let months_ = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const forceUpdate = useForceUpdate();
    function formatSubscript__(inputString, findArray, replaceArray) {
        let result = [];
        let currentIndex = 0;

        for (let i = 0; i < findArray.length; i++) {
            const findText = findArray[i];
            const replaceValue = replaceArray[i];
            const index = inputString.toLowerCase().indexOf(findText, currentIndex);

            if (index === -1) {
                // If the findText is not found, add the remaining text and break
                result.push(inputString.substring(currentIndex));
                break;
            }

            // Add the text before the found substring
            result.push(inputString.substring(currentIndex, index));

            // Add the subscripted replaceValue as an object
            result.push(...getResult(findText, replaceValue));

            // Update the currentIndex to continue searching
            currentIndex = index + findText.length;
        }

        // Add any remaining text after the last replacement
        if (currentIndex < inputString.length) {
            result.push(inputString.substring(currentIndex));
        }

        // Filter out empty strings
        result = result.filter((item) => item !== "");

        return result;
    }
    const handleNaNAndInfinity = (value) => {
        if (typeof value === 'number' && isFinite(value)) {
            return value.toFixed(0); // Return the original value if it's a valid number
        } else {
            return 0; // Return 0 for NaN or Infinity
        }
    }
    const getDataByDPArray = (dparr, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let d = 0
        dparr.forEach((dpid) => {
            dpreport_.filter((i) => { return i.dp === dpid }).forEach((k) => {


                if (checkYear(k.rp, yr) !== 0) {
                    d = d + (typeof k.value === 'number' ? k.value : parseFloat(k.value) >= 0 ? parseFloat(k.value) : 0 )

                }

            })
        })

        return isNaN(d) ? 0 : d.toFixed(0)
    }
    function formatSubscript(inputString, findArray, replaceArray) {
        return inputString;
    }
    function getResult(str, str2) {
        let arr = str.split(str2.toString());
        arr.splice(1, 0, { text: str2, fontSize: 7, baseline: -5 });
        return arr;
    }
    function ulOrOlToPdfMake(element) {
        const result = [];
        const listItems = Array.from(element.querySelectorAll("li"));

        listItems.forEach((li) => {
            const text = li.textContent.trim();
            if (text !== "") {
                result.push({ text });
            }
        });

        return result;
    }
    function customOlToPdfMake(element) {
        const result = [];
        const listItems = Array.from(element.querySelectorAll("li"));

        listItems.forEach((li) => {
            const text = li.textContent.trim();
            if (text !== "") {
                result.push({ text });
            }
        });

        return result;
    }
    async function exportTable2Excel(type) {
        let initialData = [
            {
                alignment: "center", // Center the text horizontally
                margin: [0, 250], // Adjust the top margin to vertically center the text
                text: [
                    { text: "EcoVadis REPORT" + "\n", fontSize: 40, color: "#315874", bold: true },
                    { text: "FY " + year + " - " + (year + 1).toString().substr(2, 3) + "\n", fontSize: 20, color: "#315874" },
                    { text: DateTime.local().toFormat("MMMM dd, yyyy"), fontSize: 20, color: "blue" }, // Customize the font size and color
                ],
                pageBreak: "after",
            },
            {
                toc: {
                    id: "sectionHeader",
                    title: { text: "Table of Content", style: "tdHead" },
                },
                pageBreak: "after",
            },
        ],
            data = [];
        const div = document.getElementById("main");
        for (let i = 0; i < div.children.length; i++) {
            if (div.childNodes[i].tagName.toLowerCase() === "sectionheader") {
                data.push({
                    table: {
                        widths: ["*"],
                        body: [[{ tocItem: "sectionHeader", text: formatSubscript(div.childNodes[i].textContent, ["tco2e", "n2o", "ch4", "co2"], ["2", "2", "4", "2"]), style: "secHead", border: [false, false, false, false] }]],
                    },
                });
                data.push({
                    text: "", // Empty text

                    margin: [10, 10], // Adjust the margin for horizontal space
                });
            } else if (div.childNodes[i].tagName.toLowerCase() === "sectionheader1") {
                data.push({ text: formatSubscript(div.childNodes[i].textContent, ["tco2e", "n2o", "ch4", "co2"], ["2", "2", "4", "2"]), style: "text-under" });
                data.push({
                    text: "", // Empty text

                    margin: [5, 5], // Adjust the margin for horizontal space
                });
            } else {
                if (div.childNodes[i].children.length !== 0) {
                    for (let child = 0; child < div.childNodes[i].children.length; child++) {
                        let tag = div.childNodes[i].childNodes[child].tagName;

                        if (tag) {
                            if (tag === "OL") {
                                data.push({ ol: ulOrOlToPdfMake(div.childNodes[i].childNodes[child]) });
                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 5], // Adjust the margin for horizontal space
                                });
                            } else if (tag === "UL") {
                                data.push({ ul: ulOrOlToPdfMake(div.childNodes[i].childNodes[child]) });
                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 5], // Adjust the margin for horizontal space
                                });
                            } else if (tag === "TABLE") {
                                let content = generatePdfMakeContentFromTable(div.childNodes[i].childNodes[child]);

                                data.push({
                                    table: {
                                        headerRows: 1,
                                        widths: JSON.parse(JSON.stringify(content))[0].map((a, b) => {
                                            return b == 0 ? "*" : "auto";
                                        }),
                                        body: content,
                                        style: "tableStyle",
                                    },
                                });
                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 10], // Adjust the margin for horizontal space
                                });
                            } else if (tag === "IMG") {
                                data.push({ image: div.childNodes[i].childNodes[child].src });

                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 5], // Adjust the margin for horizontal space
                                });
                            } else if (tag === "BR") {
                                let txt = `Definition: Operational Boundaries requires choosing the scope of emissions that will be reported. There are three scopes of emissions that can be reported:
                       \n Scope 1: Direct GHG Emissions from company owned or controlled sources
                       \n Scope 2: Indirect GHG Emissions from purchased electricity or steam.
                       \n According the GHG Protocol Corporate Reporting Standard, Scope 1 and Scope 2 emissions must be reported. Scope 3 emissions are voluntary`;
                                data.push({ text: txt });
                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 5], // Adjust the margin for horizontal space
                                });
                            } else {
                                data.push({ text: formatSubscript(div.childNodes[i].childNodes[child].textContent, ["tco2e", "n2o", "ch4", "co2"], ["2", "2", "4", "2"]) });
                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 5], // Adjust the margin for horizontal space
                                });
                            }
                        }
                    }
                } else {
                    data.push({ text: formatSubscript(div.childNodes[i].textContent, ["tco2e", "n2o", "ch4", "co2"], ["2", "2", "4", "2"]) });
                    data.push({
                        text: "", // Empty text

                        margin: [5, 5], // Adjust the margin for horizontal space
                    });
                }
            }
        }

        data.forEach((k) => {
            if (k.table) {
                if (!haveSameSubarrayLengths(k.table.body)) {
                    console.log(k.table.body);
                }
            }
        });

        let images = {
            clientlogo: document.getElementById("clientlogo").src,
        };

        const header = (currentPage, pageCount, pageSize) => {

            return {
                columns: [
                    {
                        text: `EcoVadis Report FY` + year.toString().substr(2, 3),
                        style: "headerText",
                        margin: [30, 20],
                        fit: [40, 40],
                        alignment: "left",
                    },
                    {
                        image: "clientlogo",
                        fit: [40, 40],
                        margin: [0, 5, 15, 0],
                        alignment: "right",
                    },
                ],
                // Add margins to the header
            };

        };
        const documentDefinition = {
            info: {
                title: "EcoVadis Report - " + DateTime.local().toFormat("MMMM dd, yyyy"),
                author: "Navigos",
                subject: "EcoVadis Report",
                keywords: "Dont share unless people within same organization",
                producer: "EiSqr",
            },
            // userPassword: 'Report@',
            // ownerPassword: '123456',
            permissions: {
                printing: "highResolution", //'lowResolution'
                modifying: false,
                copying: false,
                annotating: true,
                fillingForms: true,
                contentAccessibility: true,
                documentAssembly: true,
            },
            pageSize: "A4",
            pageMargins: [30, 70, 30, 30], // [left, top, right, bottom] margins
            header,
            footer: function (currentPage, pageCount) {
                return {
                    text: "Page " + currentPage + " of " + pageCount,
                    alignment: "center",
                    fontSize: 8,
                };
            },
            content: [...initialData, ...data],
            // defaultStyle: {
            //     font: 'Roboto'
            //   },

            images,
            styles: {
                tdHead: {
                    bold: true,
                    alignment: "center",
                    valign: "middle",
                    fillColor: "#315874",
                    color: "white",
                },

                secHead: {
                    bold: true,
                    fillColor: "#315874",
                    alignment: "center",
                    padding: [10, 10],
                    color: "white",
                },
                headerText: {
                    fontSize: 14,
                    bold: true,
                    color: "para", // Text color
                },
                "text-under": {
                    decoration: "underline",
                    color: "#315874",
                    bold: true,
                },
                boldBlue: {
                    color: "#315874",
                    bold: true,
                },
            },
        };
        console.log([...initialData, ...data]);


        if (type === 0) {
            const pdf = pdfMake.createPdf(documentDefinition);

            pdf.download("EcoVadisReport.pdf");

        } else {
            pdfMake.createPdf(documentDefinition).open({}, window.open('', '_blank'));

        }
    }
    function haveSameSubarrayLengths(data) {
        if (data.length < 2) {
            // If there are fewer than 2 subarrays, they are considered to have the same length.
            return true;
        }

        const firstSubarrayLength = data[0].length;

        for (let i = 1; i < data.length; i++) {
            if (data[i].length !== firstSubarrayLength) {
                return false;
            }
        }

        return true;
    }
    function generatePdfMakeContentFromTable(table) {
        if (!table) {
            console.error(`Table  not found.`);
            return [];
        }

        let contentArray = [],
            maxCol = 0;
        for (let i = 0; i < table.rows.length; i++) {
            if (maxCol <= table.rows[i].cells.length) {
                maxCol = table.rows[i].cells.length;
            }
        }

        for (let i = 0; i < table.rows.length; i++) {
            const rowArray = [];

            for (let j = 0; j < table.rows[i].cells.length; j++) {
                const cell = table.rows[i].cells[j];
                const colSpan = cell.getAttribute("colspan");
                const rowSpan = cell.getAttribute("rowspan");
                const cellText = cell.textContent.trim();

                const cellObject = { text: cellText };
                cellObject.colSpan = parseInt(colSpan);
                cellObject.rowSpan = parseInt(rowSpan);
                cellObject.style = cell.getAttribute("class");
                rowArray.push(cellObject);
                if (parseInt(colSpan) > 1) {
                    for (let j = 0; j < parseInt(colSpan) - 1; j++) {
                        rowArray.push({});
                    }
                }
            }

            contentArray.push(rowArray);
        }
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {
                if (contentArray[index - 1]) {
                    contentArray[index - 1].forEach((k, ind) => {
                        if (k.rowSpan) {
                            if (k.rowSpan > 1) {
                                if (k.colSpan === 1) {
                                    i.splice(ind, 0, { text: "", colSpan: k.colSpan, rowSpan: k.rowSpan - 1 });
                                } else {
                                    let newind = ind;
                                    for (let j = 0; j < parseInt(k.colSpan); j++) {
                                        i.splice(newind, 0, {});
                                        newind++;
                                    }
                                }
                            }
                        } else {
                            for (let j = 0; j < maxCol - Object.keys(i).length; j++) {
                                // i.push({id:1});
                            }
                        }
                    });
                }
            }
        });
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {
                let len = Object.keys(i).length;
                for (let j = 0; j < maxCol - len; j++) {
                    i.push({});
                }
            }
        });
        return contentArray;
    }

    const isMergedCell = (merge, rowIndex, colIndex) => {
        return merge.some((range) => rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c);
    };
    const getSum = (subset) => {
        let i = 0;
        subset.forEach((item) => {
            i = i + item[1];
        });
        return i;
    };
    const checkReportingPeriod = (rp, filter) => {
        let count = 0,
            rps = [];
        filter.forEach((item) => {
            if (rp.includes(item)) {
                count = count + 1;
                rps.push(item);
            }
        });
        return { result: count === rp.length, count: count, rps };
    };
    function getObjectsWithEmptyString(arr) {
        return arr
            .map((obj) => {
                const updatedObj = { ...obj };
                Object.keys(updatedObj).forEach((key) => {
                    if (typeof updatedObj[key] === "string" && updatedObj[key].trim() === "") {
                        delete updatedObj[key];
                    }
                });
                return updatedObj;
            })
            .filter((obj) => Object.values(obj).some((value) => value !== ""));
    }
    const getCellColSpan = (rowIndex, colIndex) => {
        const merge = workbook.Sheets[firstSheet]["!merges"] || [];
        for (const range of merge) {
            if (rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c) {
                return range.e.c - range.s.c + 1;
            }
        }
        return 1;
    };

    const getCellRowSpan = (rowIndex, colIndex) => {
        const merge = workbook.Sheets[firstSheet]["!merges"] || [];
        for (const range of merge) {
            if (rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c) {
                return range.e.r - range.s.r + 1;
            }
        }
        return 1;
    };
    function findValueByKey(object, key) {
        if (key in object) {
            return object[key];
        } else {
            return null; // or any other default value you want to return if the key is not found
        }
    }

    const getClassNameByRFResponse = (key, uid) => {
        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, key);
        if (key !== 0) {

        }
        return "";
    };

    const getAttachmentByRFResponse = (key, uid) => {
        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, key);

        if (result) {
            if (result[0].type === 0) {
                if (typeof uid === "string") {
                    let index = result[0].response.findIndex((k) => {
                        return k.name === uid;
                    });
                    if (index !== -1) {

                        return result[0].response[index].value === undefined ? [] : result[0].response[index].value;


                    } else {
                        return [];
                    }
                } else {
                    let str = "";
                    uid.forEach((id) => {
                        let index = result[0].response.findIndex((k) => {
                            return k.name === id;
                        });
                        if (index !== -1) {

                            str = str + " " + result[0].response[index].value;
                        }
                    });
                    if (str.trim().length !== 0) {
                        return str;
                    } else {
                        return 'NA';
                    }
                }
            } else {
                return []
            }
        }
        return [];
    };
    const updateDataByYear = (val) => {
        setYear(val);
        forceUpdate();
    };

    useEffect(() => {
        const elements = Array.from(document.querySelectorAll("sectionheader,sectionheader1,sectionheader2")).map((elem) => ({
            id: elem.id,
            text: elem.childNodes[0].textContent.trim(),
            level: Number(elem.nodeName.charAt(13)),
        }));

        let as = [],
            indx = 0;
        elements.forEach((item, ind) => {
            if (item.level === 0) {
                as[indx] = item;
                indx = indx + 1;
            } else if (elements[ind - 1].level === 0) {
                as[indx] = { item: [item], level: 1 };
                if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
                    indx = indx + 1;
                }
            } else {
                as[indx].item.push(item);
                if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
                    indx = indx + 1;
                }
            }
        });

        setHeadings(as);
    }, []);
    const groupArrayObject = (array, obj) => {
        return array.reduce(
            (group, arr) => {
                let key = arr[obj];

                group[key] = group[key] ?? [];

                group[key].push(arr);

                return group;
            },

            {}
        );
    };
    useEffect(() => {
        let gtaString = {
            include: ["newTargetsTwos", "newIndicatorTwos", "newInitiatives"],
        };
        let dcf_list = [],
            dcf_submitted = [],
            locloc = [];
        let category_string = {
            include: [{ relation: "newTopics", scope: { include: [{ relation: "newMetrics", scope: { include: [{ relation: "newDataPoints" }] } }] } }],
        };

        const promise1 = APIServices.get(API.DCF);
        const promise2 = APIServices.get(API.DCF_Submit_UP(admin_data.id));
        const promise3 = APIServices.get(API.QNDP_Report_UP(admin_data.id))
        const promise4 = APIServices.get(API.RF_Submit_UP(admin_data.id));
        const promise5 = APIServices.get(API.AssignDCFUser_UP(admin_data.id));
        Promise.all([promise1, promise2, promise3, promise4, promise5]).then(function (values) {
            setDCFAss(
                values[4].data
                    .filter((k) => {
                        return dcf_id.includes(k.dcfId) && k.type === 0;
                    })
                    .map((k) => {
                        return { dcfId: k.dcfId, site: k.site[0] };
                    })
            );
            dcf_list = values[0].data;
            setDcfList(values[0].data);
            dcf_submitted = values[1].data;
            setDpReport(values[2].data);
            let val = [],
                filterarr = groupArrayObject(values[2].data, "submitId");
            Object.keys(filterarr).forEach((item) => {
                val.push({ id: filterarr[item][0].submitId, rp: filterarr[item][0].rp, rp_: getRP_(filterarr[item][0].rp), year: filterarr[item][0].year, dcf: filterarr[item][0].dcfId, site: filterarr[item][0].site, response: Object.values(groupArrayObject(filterarr[item], "form_id")) });
            });

            setResponse(val);
            let rfid_group = groupArrayObject(values[3].data, "rfid");
            Object.keys(rfid_group).forEach((key) => {
                rfid_group[key].sort((a, b) => {
                    return b.id - a.id;
                });
            });

            setRFData(rfid_group);
            forceUpdate();
        });
    }, []);
    useEffect(() => {
        if (response.length !== 0) {
            let report_ = renderData(
                { location: { a: { name: "All", id: 0 }, b: { name: "All", id: 0 }, c: { name: "All", id: 0 } }, year: { name: "All", id: 0 }, month: null, indicator: { id: 0 }, timeline: [], from: getDateObjectByMonth_Year(4, year), to: getDateObjectByMonth_Year(3, year + 1) },
                1
            );
            let reportEF_ = renderDataEF(
                { location: { a: { name: "All", id: 0 }, b: { name: "All", id: 0 }, c: { name: "All", id: 0 } }, year: { name: "All", id: 0 }, month: null, indicator: { id: 0 }, timeline: [], from: getDateObjectByMonth_Year(4, year), to: getDateObjectByMonth_Year(3, year + 1) },
                1
            );

            // report_[`${year - 1}`] = renderData({ location: { a: { name: 'All', id: 0 }, b: { name: 'All', id: 0 }, c: { name: 'All', id: 0 } }, year: { name: 'All', id: 0 }, month: null, indicator: { id: 0 }, timeline: [], from: getDateObjectByMonth_Year(4, year - 1), to: getDateObjectByMonth_Year(3, year) }, 1)
            setReport(report_);
            setReportEF(reportEF_);
            forceUpdate();
        }
    }, [response, year]);

    const getScopeData = (year, area) => {
        if (report[year]) {
            return report[year]
                .filter((k) => {
                    return k.scope === area;
                })
                .map((j) => {
                    return j.ghg;
                })
                .reduce((a, b) => {
                    return a + b;
                }, 0)
                .toFixed(2);
        }
        return 0;
    };
    const checkYear = (rp, yr) => {
        let betweenMonths = [];

        let endDate = moment.utc(getDateObjectByMonth_Year(2, yr + 1)).local();
        let startDate = moment.utc(getDateObjectByMonth_Year(2, yr)).local();
        while (startDate.startOf("month") <= endDate.startOf("month")) {
            betweenMonths.push(startDate.format("MM-YYYY"));
            startDate.add(1, "month");
        }
        return betweenMonths.filter((i) => {
            return rp.includes(i);
        }).length === rp.length
            ? 1
            : betweenMonths.filter((i) => {
                return rp.includes(i);
            }).length;
    };
    const getDataByDP = (dpid, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport));
        let d = 0;

        dpreport_
            .filter((i) => {
                return i.dp === dpid;
            })
            .forEach((k) => {
                if (checkYear(k.rp, yr) !== 0) {
                    d = d + (typeof k.value === 'number' ? k.value : parseFloat(k.value) >= 0 ? parseFloat(k.value) : 0 )
;
                }
            });

        return d;
    };
    const checkScope = (arr) => {
        let index = dcfass.findIndex((l) => {
            return arr.includes(l.dcfId);
        });
        return index !== -1 ? true : false;
    };
    function concatenateArrayWithAnd(array) {
        if (array.length === 1) {
            return array[0];
        } else if (array.length > 1) {
            const lastElement = array.pop(); // Remove the last element
            return `${array.join(", ")} and ${lastElement}`;
        } else {
            return "NA"; // Return an empty string if the array is empty
        }
    }
    const getMCFuelUsed = () => {
        let result = [];

        report
            .filter((l) => {
                return l.dcfId === 15;
            })
            .forEach((i, j) => {
                !result.includes(i.mode) && result.push(i.mode);
            });

        return concatenateArrayWithAnd(result);
    };
    const getSCFuelUsed = () => {
        let result = [];

        report
            .filter((l) => {
                return l.dcfId === 11;
            })
            .forEach((i, j) => {
                !result.includes(i.fuel_type) && result.push(i.fuel_type);
            });
        return concatenateArrayWithAnd(result);
    };
    const getFEGasUsed = () => {
        let result = [];

        report
            .filter((l) => {
                return l.dcfId === 10;
            })
            .forEach((i, j) => {
                result.push(i.gastype);
            });
        return concatenateArrayWithAnd(result);
    };
    function removeDuplicatesByProperties(arr, keys) {
        const seen = new Set();
        return arr.filter((item) => {
            const key = JSON.stringify(keys.map((key) => item[key]));
            if (!seen.has(key)) {
                seen.add(key);
                return true;
            }
            return false;
        });
    }
    const renderFEGas = () => {
        let result = [];

        reportEF
            .filter((l) => {
                return l.dcfId === 10;
            })
            .forEach((i, j) => {
                result.push({ type: i.gastype, ghg: i.co2e_.toFixed(2) + " kg CO2e/kg" });
            });

        return result.length === 0 ? [{ type: "Not Found", ghg: 0 }] : result;
    };
    const renderSCFuel = () => {
        let result = [];

        reportEF
            .filter((l) => {
                return l.dcfId === 11;
            })
            .forEach((i, j) => {
                result.push({ type: i.fuel_type + "-" + i.unit, co2_: i.co2_.toFixed(2) + " kg CO2e/kg", n2o_: i.n2o_.toFixed(2) + " kg CO2e/kg", ch4_: i.ch4_.toFixed(2) + " kg CO2e/kg" });
            });
        return result.length === 0 ? [{ type: "Not Found", co2_: 0, ch4_: 0, n2o_: 0 }] : removeDuplicatesByProperties(result, ["co2_", "n2o_", "ch4", "unit", "fuel_type"]);
    };

    const renderMCFuel = () => {
        let result = [];

        reportEF
            .filter((l) => {
                return l.dcfId === 15;
            })
            .forEach((i, j) => {
                console.log("i", i);
                result.push({ type: i.mode + " - " + i.fuel_cat, ghg: i.co2e_.toFixed(2) + " kg CO2e /litre" });
            });

        return result.length === 0 ? [{ type: "Not Found", ghg: 0 }] : result;
    };
    const getScopeDataByDCF = (id) => {
        console.log(
            JSON.parse(JSON.stringify(report)).filter((i) => {
                return id.includes(i.dcfId);
            }),
            "report",
            id
        );
        let report_ = JSON.parse(JSON.stringify(report))
            .filter((i) => {
                return id.includes(i.dcfId);
            })
            .map((i) => {
                return i.ghg;
            })
            .reduce((a, b) => {
                return a + b;
            }, 0);
        return report_.toFixed(2);
    };
    const renderEmissionScope1 = (text) => {
        return (
            <>
                {checkScope([11, 10, 15]) && (
                    <>
                        {checkScope([11]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Stationary Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([11])}
                                </td>
                                <td colSpan="1" className="text-center"></td>
                            </tr>
                        )}
                        {checkScope([15]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Mobile Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([15])}
                                </td>
                                <td colSpan="1" className="text-center"></td>
                            </tr>
                        )}
                        {checkScope([10]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Fugitive Emissions
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([10])}
                                </td>
                                <td colSpan="1" className="text-center"></td>
                            </tr>
                        )}
                        <tr>
                            <td colspan="1" rowspan="1">
                                Subtotal Scope 1 Emissions
                            </td>
                            <td colspan="1" rowspan="1"></td>
                            <td colSpan="1" className="text-center">
                                {getScopeDataByDCF([10, 11, 15])}
                            </td>
                        </tr>
                    </>
                )}
            </>
        );
    };
    const renderEmissionScope3 = (text) => {
        return (
            <>
                {checkScope([16, 36]) && (
                    <>
                        {checkScope([16]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 1 - Purchased goods and services
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([16])}
                                </td>
                                <td colSpan="1" className="text-center"></td>
                            </tr>
                        )}
                        {checkScope([36]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 6 - Business travel
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([36])}
                                </td>
                                <td colSpan="1" className="text-center"></td>
                            </tr>
                        )}
                        <tr>
                            <td colspan="1" rowspan="1">
                                Subtotal Scope 3 Emissions
                            </td>
                            <td colspan="1" rowspan="1"></td>
                            <td colSpan="1" className="text-center">
                                {getScopeDataByDCF([36, 16])}
                            </td>
                        </tr>
                    </>
                )}
            </>
        );
    };
    const renderEmissionScope1_ = (text) => {
        return (
            <>
                {checkScope([11, 10, 15]) && (
                    <>
                        {checkScope([11]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Stationary Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([11])}
                                </td>
                            </tr>
                        )}
                        {checkScope([15]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Mobile Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([15])}
                                </td>
                            </tr>
                        )}
                        {checkScope([10]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Fugitive Emissions
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([10])}
                                </td>
                            </tr>
                        )}
                    </>
                )}
            </>
        );
    };
    const renderEmissionScope3_ = (text) => {
        return (
            <>
                {checkScope([16, 36]) && (
                    <>
                        {checkScope([16]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 1 - Purchased goods and services
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([16])}
                                </td>
                            </tr>
                        )}
                        {checkScope([36]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 6 - Business travel
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([36])}
                                </td>
                            </tr>
                        )}
                    </>
                )}
            </>
        );
    };
    const renderEmissionScope1_1 = (text) => {
        return (
            <>
                {checkScope([11, 10, 15]) && (
                    <>
                        {checkScope([11]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Stationary Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                            </tr>
                        )}
                        {checkScope([15]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Mobile Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                            </tr>
                        )}
                        {checkScope([10]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Fugitive Emissions
                                </td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                            </tr>
                        )}
                    </>
                )}
            </>
        );
    };
    const renderEmissionScope3_1 = (text) => {
        return (
            <>
                {checkScope([16, 36]) && (
                    <>
                        {checkScope([16]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 1 - Purchased goods and services
                                </td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                            </tr>
                        )}
                        {checkScope([36]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 6 - Business travel
                                </td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                            </tr>
                        )}
                    </>
                )}
            </>
        );
    };
    const renderEmissionByScopeTable = (text) => {
        return (
            <div class="gridlines-container">
                <table class="gridlines">
                    <tbody>
                        <tr>
                            <td colspan="1" rowspan="1" className="tdHead">
                                Scope of Emission
                            </td>
                            <td colspan="1" rowspan="1" className="tdHead">
                                tCO2e
                            </td>
                        </tr>
                        {checkScope([11, 10, 15]) && (
                            <>
                                <tr>
                                    <td colspan="1" rowspan="1">
                                        Scope 1- Direct Emissions
                                    </td>
                                    <td colspan="1" rowspan="1">
                                        {getScopeDataByDCF([11, 10, 15])}
                                    </td>
                                </tr>
                            </>
                        )}
                        {checkScope([72]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Scope 2- Indirect Emission
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([72])}
                                </td>
                            </tr>
                        )}
                        {checkScope([16, 36]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Scope 3- Indirect emissions
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([16, 36])}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    };
    const renderEmissionByCategoryTable = () => {
        return (
            <div class="gridlines-container">
                <table class="gridlines">
                    <tbody>
                        <tr>
                            <td colspan="1" rowspan="1" className="tdHead">
                                Emission Profile
                            </td>
                            <td colspan="1" rowspan="1" className="tdHead">
                                tCO2e
                            </td>
                        </tr>
                        {checkScope([11, 10, 15]) && (
                            <>
                                {checkScope([11]) && (
                                    <tr>
                                        <td colspan="1" rowspan="1">
                                            Stationary Combustion (Fuel Used)
                                        </td>
                                        <td colspan="1" rowspan="1">
                                            {getScopeDataByDCF([11])}
                                        </td>
                                    </tr>
                                )}
                                {checkScope([15]) && (
                                    <tr>
                                        <td colspan="1" rowspan="1">
                                            Mobile Combustion (Owned Vehicles)
                                        </td>
                                        <td colspan="1" rowspan="1">
                                            {getScopeDataByDCF([15])}
                                        </td>
                                    </tr>
                                )}
                                {checkScope([10]) && (
                                    <tr>
                                        <td colspan="1" rowspan="1">
                                            Fugitive Emissions (Refrigerants)
                                        </td>
                                        <td colspan="1" rowspan="1">
                                            {getScopeDataByDCF([10])}
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}
                        {checkScope([72]) && (
                            <>
                                <tr>
                                    <td colspan="1" rowspan="1">
                                        Emissions from purchased energy (Grid Electricity)
                                    </td>
                                    <td colspan="1" rowspan="1">
                                        {getScopeDataByDCF([72])}
                                    </td>
                                </tr>
                            </>
                        )}
                        {checkScope([16, 36]) && (
                            <>
                                {checkScope([16]) && (
                                    <tr>
                                        <td colspan="1" rowspan="1">
                                            Purchased Goods and Services
                                        </td>
                                        <td colspan="1" rowspan="1">
                                            {getScopeDataByDCF([16])}
                                        </td>
                                    </tr>
                                )}
                                {checkScope([36]) && (
                                    <tr>
                                        <td colspan="1" rowspan="1">
                                            Business Travel
                                        </td>
                                        <td colspan="1" rowspan="1">
                                            {getScopeDataByDCF([36])}
                                        </td>
                                    </tr>
                                )}
                                {/* <tr>
                            <td colspan="1" rowspan="1" >Employee Commute
                            </td>
                            <td colspan="1" rowspan="1" >
                            </td>
                        </tr> */}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        );
    };
    const getDataByDCFDPID = (dcfid, dpid, yr) => {
        let response_ = JSON.parse(JSON.stringify(response));
        let d = 0;

        response_.forEach((k) => {
            if (k.dcf === dcfid) {
                if (checkYear(k.rp, yr) !== 0) {
                    let result = k.response.filter((k) => {
                        return k.name === dpid;
                    });
                    if (result.length !== 0) {
                        d = d + parseInt(result[0].value.match(/\d+/)[0]);
                    }
                }
            }
        });

        return d;
    };

    function getDateObjectByMonth_Year(month, year) {
        if (isNaN(month) || isNaN(year)) {
            throw new Error("Invalid month or year");
        }

        const normalizedMonth = Math.max(1, Math.min(12, month));

        const date = DateTime.fromObject({ year, month: normalizedMonth, day: 1 });

        return date.toJSDate();
    }
    const getRP_ = (rp) => {
        if (rp.length === 1) {
            return months_[parseInt(rp[0].split("-")[0]) - 1] + "-" + rp[0].split("-")[1].slice(-2);
        } else {
            return months_[parseInt(rp[0].split("-")[0]) - 1] + "-" + rp[0].split("-")[1].slice(-2) + "to" + months_[parseInt(rp[rp.length - 1].split("-")[0]) - 1] + "-" + rp[rp.length - 1].split("-")[1].slice(-2);
        }
    };
    const getClassName = (level) => {
        switch (level) {
            case 1:
                return "head1";
            case 2:
                return "head2";
            case 3:
                return "head3";
            default:
                return null;
        }
    };

    const checkSite = (id, filter) => {
        let idlist = [];

        siteList.forEach((country) => {
            if (filter.a.id === 0 || filter.a.id === country.id) {
                country.locationTwos.forEach((city) => {
                    if (filter.b.id === 0 || filter.b.id === city.id) {
                        city.locationThrees.forEach((loc) => {
                            if (filter.c.id == 0 || filter.c.id === loc.id) {
                                idlist.push(loc.id);
                            }
                        });
                    }
                });
            }
        });

        return idlist.includes(id);
    };
    const groupArrayObject_3_Keys = (array, obj1, obj2, obj3) => {
        return array.reduce((result, arr) => {
            let key1 = arr[obj1];
            let key2 = arr[obj2];
            let key3 = arr[obj3];

            const key = `${key1}-${key2}-${key3}`;

            if (!result[key]) {
                result[key] = [];
            }

            result[key].push(arr);

            return result;
        }, {});
    };
    const renderData = (search) => {
        let betweenMonths = [],
            betweenYears = [];
        let monthly_fg = [];

        let endDate = moment.utc(search.to).local();
        let startDate = moment.utc(search.from).local();
        let year = moment(moment.utc()).format("YYYY");

        if (search.to !== null && search.from !== null) {
            while (startDate.startOf("month") <= endDate.startOf("month")) {
                betweenMonths.push(startDate.format("MM-YYYY"));
                !betweenYears.includes(startDate.format("YYYY")) && betweenYears.push(startDate.format("YYYY"));

                startDate.add(1, "month");
            }

            let res = JSON.parse(JSON.stringify(response));

            res.forEach((report, rind) => {
                let sc_total = 0,
                    fg_total = 0;

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => {
                        return loc.id === report.site;
                    })[0].name;
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {
                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dp === "DPA0285";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let item_class = fg.filter((i) => {
                                    return i.dp === "DPA0287";
                                })[0].value.name;
                                let item_qty = fg.filter((i) => {
                                    return i.dp === "DPA0288";
                                })[0].value;
                                let price_per_item = fg.filter((i) => {
                                    return i.dp === "DPA0289";
                                })[0].value;

                                let total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.item_classification === item_class;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    item_qty *
                                    price_per_item;

                                fg_total = total + fg_total;

                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class });
                                } else {
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                }
                            }
                        });
                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {
                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dp === "DPA0290";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let total = 0;

                                let travel_mode = fg.filter((i) => {
                                    return i.dp === "DPA0291";
                                })[0].value.name;
                                let passenger = fg.filter((i) => {
                                    return i.dp === "DPA0292";
                                })[0].value;

                                if (travel_mode.toLowerCase() === "air") {
                                    total =
                                        fg.filter((i) => {
                                            return i.dp === "DP_co2e_mt";
                                        })[0].value * passenger;

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                } else if (travel_mode.toLowerCase() === "road") {
                                    let veh_cat = fg.filter((i) => {
                                        return i.dp === "DPA0295";
                                    })[0].value.name;
                                    let veh_type = fg.filter((i) => {
                                        return i.dp === "DPA0337";
                                    })[0].value;
                                    let fuel = fg.filter((i) => {
                                        return i.dp === "DPA0338";
                                    })[0].value;
                                    let km = fg.filter((i) => {
                                        return i.dp === "DP_KM";
                                    })[0].value;
                                    if (veh_cat.includes("Cars") || veh_cat.includes("Motor")) {
                                        if (veh_cat.includes("Cars")) {
                                            total =
                                                (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                    return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === "km";
                                                })[0]["co2e_in_kg"] /
                                                    1000) *
                                                km *
                                                passenger;
                                        } else {
                                            total =
                                                (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                    return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === "km";
                                                })[0]["co2e_in_kg"] /
                                                    1000) *
                                                km *
                                                passenger;
                                        }
                                    } else {
                                        total =
                                            (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                return k.vehicle_category === veh_cat && k.unit === "passenger.km";
                                            })[0]["co2e_in_kg"] /
                                                1000) *
                                            km *
                                            passenger;
                                    }

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                } else if (travel_mode.toLowerCase() === "rail") {
                                    total =
                                        fg.filter((i) => {
                                            return i.dp === "DP_KM";
                                        })[0].value *
                                        passenger *
                                        0.00116;

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                }
                            }
                        });
                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {
                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

                        let total = 0,
                            co2 = 0,
                            ch4 = 0,
                            n2o = 0,
                            sc_data = [];
                        report.response.forEach((fg, ind) => {
                            let fuel_cat = fg.filter((i) => {
                                return i.dp === "DPA0130";
                            })[0].value.name;
                            let fuel_type = fg.filter((i) => {
                                return i.dp === "DPA0131";
                            })[0].value.name;
                            let unit = fg.filter((i) => {
                                return i.dp === "DPA0132";
                            })[0].value.name;
                            let consumed = fg.filter((i) => {
                                return i.dp === "DPA0336";
                            })[0].value;
                            let fuel_cat_ind = sc_data.findIndex((k) => {
                                return k.fuel_type === fuel_type;
                            });

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => {
                                    return i.dp === "DPA0134";
                                })[0].value;
                                let cv = fg.filter((i) => {
                                    return i.dp === "DPA0133";
                                })[0].value;
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv;
                                    total = (gj * 0.000004184 * consumed) / 1000;
                                    fg["value"] = ["Emission Factor-", 3.664, " ,EnergyProduced-", 0.000004184];
                                    co2 = 0;
                                    ch4 = 0;
                                    n2o = 0;
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4 });
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                    }
                                } else {
                                    total =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["co2e_in_kg"] /
                                            1000) *
                                        consumed;
                                    co2 =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["co2_in_kg"] /
                                            1000) *
                                        consumed;
                                    ch4 =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["ch4_in_kg"] /
                                            1000) *
                                        consumed;
                                    n2o =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["n2o_in_kg"] /
                                            1000) *
                                        consumed;
                                    fg["value"] = ["co2-", co2 / consumed, " ,ch4-", ch4 / consumed, " ,n2o-", n2o / consumed];
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4 });
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                    }
                                }
                            } else {
                                total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    consumed;
                                co2 =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["co2_in_kg"] /
                                        1000) *
                                    consumed;
                                ch4 =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["ch4_in_kg"] /
                                        1000) *
                                    consumed;
                                n2o =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["n2o_in_kg"] /
                                        1000) *
                                    consumed;
                                fg["value"] = ["co2-", co2 / consumed, " ,ch4-", ch4 / consumed, " ,n2o-", n2o / consumed];
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4 });
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                    sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                    sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                    sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                }
                            }
                        });
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, "MM-YYYY").toDate();
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.fuel_type === item.fuel_type;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({
                                        scope: 1,
                                        year: moment(date).format("YYYY"),
                                        month: moment(date).format("MMM-YYYY"),
                                        ghg: item.ghg / addedMonth.count,
                                        dcf: report.dcf,
                                        site: report.site,
                                        fuel_type: item.fuel_type,
                                        co2: (item.co2 * 1000) / addedMonth.count,
                                        n2o: (item.n20 * 1000) / addedMonth.count,
                                        ch4: (item.ch4 * 1000) / addedMonth.count,
                                    });
                                } else {
                                    monthly_fg[updateind].co2 = ((item.co2 * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].co2;
                                    monthly_fg[updateind].n2o = ((item.n2o * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].n2o;
                                    monthly_fg[updateind].ch4 = ((item.ch4 * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].ch4;
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                                }
                            });
                        });
                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = [];

                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dp === "DPA0137";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let gastype = fg.filter((i) => {
                                    return i.dp === "DPA0136";
                                })[0].value.name;
                                let gasrefilled = fg.filter((i) => {
                                    return i.dp === "DPA0138";
                                })[0].value;
                                let total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => {
                                        return k.gas_type === gastype;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    gasrefilled;

                                fg_total = total + fg_total;

                                fg["gas"] = gastype;
                                fg["gasfilled"] = gasrefilled;
                                fg["value"] = ["EmissionFactor-", total / gasrefilled];
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({ scope: 1, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total });
                                } else {
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                }
                            }
                        });
                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {
                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY");
                                });
                                let monthind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY");
                                });
                                let dcfind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY") && i.dcf === report.dcf;
                                });
                                let siteind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                                });

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]["kwh_in_tco2e"];

                                let renewable =
                                    report.response[0].filter((i) => {
                                        return i.dp === "DPA0156";
                                    })[0].value / report.rp.length;
                                let nonrenewable =
                                    report.response[0].filter((i) => {
                                        return i.dp === "DPA0157";
                                    })[0].value / report.rp.length;

                                if (yearind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (monthind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (dcfind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (siteind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                }
                            }
                        });
                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {
                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData;
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData;

                        let total = 0,
                            co2 = 0,
                            ch4 = 0,
                            n2o = 0,
                            mc_data = [];

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => {
                                return i.dp === "DP_MODE";
                            })[0].value
                                ? "by distance"
                                : "by fuel";
                            let fuel_cat = fg.filter((i) => {
                                return i.dp === "DPA0140";
                            })[0].value;
                            let fuel_type = fg.filter((i) => {
                                return i.dp === "DPA0139";
                            })[0].value;
                            let fuel = fg.filter((i) => {
                                return i.dp === "DPA0141";
                            })[0].value.name;
                            let unit = fg.filter((i) => {
                                return i.dp === "DPA0339";
                            })[0].value;
                            let km = fg.filter((i) => {
                                return i.dp === "DPA0144";
                            })[0].value;
                            let fuel_filled = fg.filter((i) => {
                                return i.dp === "DPA0143";
                            })[0].value;
                            let fuel_cat_ind = mc_data.findIndex((k) => {
                                return k.fuel === fuel;
                            });

                            if (mode === "by distance") {
                                total = ef_by_distance.filter((k) => {
                                    return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name;
                                })[0]["co2e_in_kg"];

                                if (fuel_cat_ind === -1) {
                                    mc_data.push({ mode: fuel, ghg: total * km, fuel_cat: fuel_cat.name });
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                                }
                            } else {
                                total = ef_by_fuel.filter((k) => {
                                    return k.fuel.trim() === fuel.trim();
                                })[0]["co2e_in_kg"];

                                if (fuel_cat_ind === -1) {
                                    mc_data.push({ mode: fuel, ghg: total * fuel_filled });
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                                }
                            }
                        });
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, "MM-YYYY").toDate();

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode;
                                });

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode;
                                    });
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = item.ghg / addedMonth.count + monthly_fg[updateind2].ghg;
                                    } else {
                                        monthly_fg.push({ scope: 1, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: 0, dcf: report.dcf, site: report.site, ghg: item.ghg / addedMonth.count, mode: item.mode, fuel_cat: item.fuel_cat });
                                    }
                                } else {
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                                }
                            });
                        });
                    }
                }
            });

            let scope12_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 1 || i.scope === 2;
                }),
                "month"
            );
            let scope3_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 3;
                }),
                "month"
            );
            let scope1_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 1;
                }),
                "month"
            );
            let scope2_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 2;
                }),
                "month"
            );
            let pie = [];
            monthly_fg
                .filter((i) => {
                    return i.scope === 1;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });
            monthly_fg
                .filter((i) => {
                    return i.scope === 2;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });
            monthly_fg
                .filter((i) => {
                    return i.scope === 3;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });

            let index1 = pie.findIndex((j) => {
                return j.name === "Scope 1";
            });
            let index2 = pie.findIndex((j) => {
                return j.name === "Scope 2";
            });
            let index3 = pie.findIndex((j) => {
                return j.name === "Scope 3";
            });

            if (index1 === -1) {
                pie.push({ name: "Scope 1", y: 0 });
            }
            if (index2 === -1) {
                pie.push({ name: "Scope 2", y: 0 });
            }
            if (index3 === -1) {
                pie.push({ name: "Scope 3", y: 0 });
            }

            if (scope1_array.length !== 0) {
                let scope1_tier2 = [],
                    final = [];
                Object.keys(scope1_array).forEach((key) => {
                    scope1_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;

                        if (
                            scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope1_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,

                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope1_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope1_tier2[ind].y = scope1_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope1_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope1_tier2[ind].subset[subind][1] = scope1_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });

                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope1_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
            if (scope2_array.length !== 0) {
                let scope2_tier2 = [],
                    final = [];
                Object.keys(scope2_array).forEach((key) => {
                    scope2_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;
                        if (
                            scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope2_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope2_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope2_tier2[ind].y = scope2_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope2_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope2_tier2[ind].subset[subind][1] = scope2_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });
                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope2_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
            if (scope3_array.length !== 0) {
                let scope3_tier2 = [],
                    final = [];
                Object.keys(scope3_array).forEach((key) => {
                    scope3_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;
                        if (
                            scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope3_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope3_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope3_tier2[ind].y = scope3_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope3_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope3_tier2[ind].subset[subind][1] = scope3_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });
                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope3_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
        }

        return monthly_fg;
    };
    const renderDataEF = (search) => {
        let betweenMonths = [],
            betweenYears = [];
        let monthly_fg = [];

        let endDate = moment.utc(search.to).local();
        let startDate = moment.utc(search.from).local();
        let year = moment(moment.utc()).format("YYYY");

        if (search.to !== null && search.from !== null) {
            while (startDate.startOf("month") <= endDate.startOf("month")) {
                betweenMonths.push(startDate.format("MM-YYYY"));
                !betweenYears.includes(startDate.format("YYYY")) && betweenYears.push(startDate.format("YYYY"));

                startDate.add(1, "month");
            }

            let res = JSON.parse(JSON.stringify(response));

            res.forEach((report, rind) => {
                let sc_total = 0,
                    fg_total = 0;

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => {
                        return loc.id === report.site;
                    })[0].name;
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {
                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dp === "DPA0285";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let item_class = fg.filter((i) => {
                                    return i.dp === "DPA0287";
                                })[0].value.name;
                                let item_qty = fg.filter((i) => {
                                    return i.dp === "DPA0288";
                                })[0].value;
                                let price_per_item = fg.filter((i) => {
                                    return i.dp === "DPA0289";
                                })[0].value;

                                let total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.item_classification === item_class;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    item_qty *
                                    price_per_item;

                                fg_total = total + fg_total;

                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class });
                                } else {
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                }
                            }
                        });
                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {
                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dp === "DPA0290";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let total = 0;

                                let travel_mode = fg.filter((i) => {
                                    return i.dp === "DPA0291";
                                })[0].value.name;
                                let passenger = fg.filter((i) => {
                                    return i.dp === "DPA0292";
                                })[0].value;

                                if (travel_mode.toLowerCase() === "air") {
                                    total =
                                        fg.filter((i) => {
                                            return i.dp === "DP_co2e_mt";
                                        })[0].value * passenger;

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                } else if (travel_mode.toLowerCase() === "road") {
                                    let veh_cat = fg.filter((i) => {
                                        return i.dp === "DPA0295";
                                    })[0].value.name;
                                    let veh_type = fg.filter((i) => {
                                        return i.dp === "DPA0337";
                                    })[0].value;
                                    let fuel = fg.filter((i) => {
                                        return i.dp === "DPA0338";
                                    })[0].value;
                                    let km = fg.filter((i) => {
                                        return i.dp === "DP_KM";
                                    })[0].value;
                                    if (veh_cat.includes("Cars") || veh_cat.includes("Motor")) {
                                        if (veh_cat.includes("Cars")) {
                                            total =
                                                (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                    return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === "km";
                                                })[0]["co2e_in_kg"] /
                                                    1000) *
                                                km *
                                                passenger;
                                        } else {
                                            total =
                                                (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                    return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === "km";
                                                })[0]["co2e_in_kg"] /
                                                    1000) *
                                                km *
                                                passenger;
                                        }
                                    } else {
                                        total =
                                            (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                return k.vehicle_category === veh_cat && k.unit === "passenger.km";
                                            })[0]["co2e_in_kg"] /
                                                1000) *
                                            km *
                                            passenger;
                                    }

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                } else if (travel_mode.toLowerCase() === "rail") {
                                    total =
                                        fg.filter((i) => {
                                            return i.dp === "DP_KM";
                                        })[0].value *
                                        passenger *
                                        0.00116;

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                }
                            }
                        });
                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {
                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

                        let total = 0,
                            co2 = 0,
                            ch4 = 0,
                            n2o = 0,
                            sc_data = [];
                        report.response.forEach((fg, ind) => {
                            let fuel_cat = fg.filter((i) => {
                                return i.dp === "DPA0130";
                            })[0].value.name;
                            let fuel_type = fg.filter((i) => {
                                return i.dp === "DPA0131";
                            })[0].value.name;
                            let unit = fg.filter((i) => {
                                return i.dp === "DPA0132";
                            })[0].value.name;
                            let consumed = fg.filter((i) => {
                                return i.dp === "DPA0336";
                            })[0].value;
                            let fuel_cat_ind = sc_data.findIndex((k) => {
                                return k.fuel_type === fuel_type && k.unit === unit;
                            });

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => {
                                    return i.dp === "DPA0134";
                                })[0].value;
                                let cv = fg.filter((i) => {
                                    return i.dp === "DPA0133";
                                })[0].value;
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv;
                                    total = (gj * 0.000004184 * consumed) / 1000;
                                    fg["value"] = ["Emission Factor-", 3.664, " ,EnergyProduced-", 0.000004184];
                                    co2 = 0;
                                    ch4 = 0;
                                    n2o = 0;
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4, unit, co2_: 0, n2o_: 0, ch4_: 0 });
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                    }
                                } else {
                                    total =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["co2e_in_kg"] /
                                            1000) *
                                        consumed;
                                    co2 =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["co2_in_kg"] /
                                            1000) *
                                        consumed;
                                    ch4 =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["ch4_in_kg"] /
                                            1000) *
                                        consumed;
                                    n2o =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["n2o_in_kg"] /
                                            1000) *
                                        consumed;
                                    let co2_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["co2_in_kg"];
                                    let ch4_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["ch4_in_kg"];
                                    let n2o_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["n2o_in_kg"];
                                    fg["value"] = ["co2-", co2 / consumed, " ,ch4-", ch4 / consumed, " ,n2o-", n2o / consumed];
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, unit, co2, n2o, ch4, co2_, n2o_, ch4_ });
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                    }
                                }
                            } else {
                                total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    consumed;
                                co2 =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["co2_in_kg"] /
                                        1000) *
                                    consumed;
                                ch4 =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["ch4_in_kg"] /
                                        1000) *
                                    consumed;
                                n2o =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["n2o_in_kg"] /
                                        1000) *
                                    consumed;
                                let co2_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                    return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                })[0]["co2_in_kg"];
                                let ch4_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                    return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                })[0]["ch4_in_kg"];
                                let n2o_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                    return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                })[0]["n2o_in_kg"];

                                fg["value"] = ["co2-", co2 / consumed, " ,ch4-", ch4 / consumed, " ,n2o-", n2o / consumed];
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4, unit, co2_, n2o_, ch4_ });
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                    sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                    sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                    sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                }
                            }
                        });
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, "MM-YYYY").toDate();
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.fuel_type === item.fuel_type && i.unit === item.unit;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({
                                        scope: 1,
                                        year: moment(date).format("YYYY"),
                                        month: moment(date).format("MMM-YYYY"),
                                        ghg: item.ghg / addedMonth.count,
                                        dcf: report.dcf,
                                        site: report.site,
                                        fuel_type: item.fuel_type,
                                        unit: item.unit,
                                        co2: (item.co2 * 1000) / addedMonth.count,
                                        n2o: (item.n20 * 1000) / addedMonth.count,
                                        ch4: (item.ch4 * 1000) / addedMonth.count,
                                        co2_: item.co2_,
                                        ch4_: item.ch4_,
                                        n2o_: item.n2o_,
                                    });
                                } else {
                                    monthly_fg[updateind].co2 = ((item.co2 * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].co2;
                                    monthly_fg[updateind].n2o = ((item.n2o * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].n2o;
                                    monthly_fg[updateind].ch4 = ((item.ch4 * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].ch4;
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                                }
                            });
                        });
                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = [];

                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dp === "DPA0137";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let gastype = fg.filter((i) => {
                                    return i.dp === "DPA0136";
                                })[0].value.name;
                                let gasrefilled = fg.filter((i) => {
                                    return i.dp === "DPA0138";
                                })[0].value;
                                let total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => {
                                        return k.gas_type === gastype;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    gasrefilled;
                                let co2e_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => {
                                    return k.gas_type === gastype;
                                })[0]["co2e_in_kg"];
                                fg_total = total + fg_total;

                                fg["gas"] = gastype;
                                fg["gasfilled"] = gasrefilled;
                                fg["value"] = ["EmissionFactor-", total / gasrefilled];
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({ scope: 1, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total, co2e_ });
                                } else {
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                }
                            }
                        });
                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {
                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY");
                                });
                                let monthind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY");
                                });
                                let dcfind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY") && i.dcf === report.dcf;
                                });
                                let siteind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                                });

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]["kwh_in_tco2e"];

                                let renewable =
                                    report.response[0].filter((i) => {
                                        return i.dp === "DPA0156";
                                    })[0].value / report.rp.length;
                                let nonrenewable =
                                    report.response[0].filter((i) => {
                                        return i.dp === "DPA0157";
                                    })[0].value / report.rp.length;

                                if (yearind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, co2e_: ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (monthind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, co2e_: ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (dcfind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, co2e_: ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (siteind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, dcf: report.dcf, co2e_: ef, site: report.site, renewable, nonrenewable });
                                }
                            }
                        });
                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {
                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData;
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData;

                        let total = 0,
                            co2 = 0,
                            ch4 = 0,
                            n2o = 0,
                            mc_data = [];

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => {
                                return i.dp === "DP_MODE";
                            })[0].value
                                ? "by distance"
                                : "by fuel";
                            let fuel_cat = fg.filter((i) => {
                                return i.dp === "DPA0140";
                            })[0].value;
                            let fuel_type = fg.filter((i) => {
                                return i.dp === "DPA0139";
                            })[0].value;
                            let fuel = fg.filter((i) => {
                                return i.dp === "DPA0141";
                            })[0].value.name;
                            let unit = fg.filter((i) => {
                                return i.dp === "DPA0339";
                            })[0].value;
                            let km = fg.filter((i) => {
                                return i.dp === "DPA0144";
                            })[0].value;
                            let fuel_filled = fg.filter((i) => {
                                return i.dp === "DPA0143";
                            })[0].value;
                            let fuel_cat_ind = mc_data.findIndex((k) => {
                                return k.fuel === fuel;
                            });

                            if (mode === "by distance") {
                                total = ef_by_distance.filter((k) => {
                                    return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name;
                                })[0]["co2e_in_kg"];

                                if (fuel_cat_ind === -1) {
                                    mc_data.push({ mode: fuel, ghg: total * km, co2e_: total, fuel_cat: fuel_cat.name });
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                                }
                            } else {
                                total = ef_by_fuel.filter((k) => {
                                    return k.fuel.trim() === fuel.trim();
                                })[0]["co2e_in_kg"];

                                if (fuel_cat_ind === -1) {
                                    mc_data.push({ mode: fuel, ghg: total * fuel_filled, co2e_: total, fuel_cat: "" });
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                                }
                            }
                        });
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, "MM-YYYY").toDate();

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode;
                                });

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode;
                                    });
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = item.ghg / addedMonth.count + monthly_fg[updateind2].ghg;
                                        // monthly_fg[updateind2]['fuel_cat'] = item.fuel_cat
                                    } else {
                                        console.log("EF", item);
                                        monthly_fg.push({ scope: 1, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: 0, dcf: report.dcf, site: report.site, ghg: item.ghg / addedMonth.count, mode: item.mode, fuel_cat: item.fuel_cat, co2e_: item.co2e_ });
                                    }
                                } else {
                                    // monthly_fg[updateind]['fuel_cat'] = item.fuel_cat
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                                }
                            });
                        });
                    }
                }
            });

            let scope12_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 1 || i.scope === 2;
                }),
                "month"
            );
            let scope3_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 3;
                }),
                "month"
            );
            let scope1_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 1;
                }),
                "month"
            );
            let scope2_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 2;
                }),
                "month"
            );
            let pie = [];
            monthly_fg
                .filter((i) => {
                    return i.scope === 1;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });
            monthly_fg
                .filter((i) => {
                    return i.scope === 2;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });
            monthly_fg
                .filter((i) => {
                    return i.scope === 3;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });

            let index1 = pie.findIndex((j) => {
                return j.name === "Scope 1";
            });
            let index2 = pie.findIndex((j) => {
                return j.name === "Scope 2";
            });
            let index3 = pie.findIndex((j) => {
                return j.name === "Scope 3";
            });

            if (index1 === -1) {
                pie.push({ name: "Scope 1", y: 0 });
            }
            if (index2 === -1) {
                pie.push({ name: "Scope 2", y: 0 });
            }
            if (index3 === -1) {
                pie.push({ name: "Scope 3", y: 0 });
            }

            if (scope1_array.length !== 0) {
                let scope1_tier2 = [],
                    final = [];
                Object.keys(scope1_array).forEach((key) => {
                    scope1_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;

                        if (
                            scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope1_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,

                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope1_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope1_tier2[ind].y = scope1_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope1_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope1_tier2[ind].subset[subind][1] = scope1_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });

                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope1_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
            if (scope2_array.length !== 0) {
                let scope2_tier2 = [],
                    final = [];
                Object.keys(scope2_array).forEach((key) => {
                    scope2_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;
                        if (
                            scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope2_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope2_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope2_tier2[ind].y = scope2_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope2_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope2_tier2[ind].subset[subind][1] = scope2_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });
                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope2_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
            if (scope3_array.length !== 0) {
                let scope3_tier2 = [],
                    final = [];
                Object.keys(scope3_array).forEach((key) => {
                    scope3_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;
                        if (
                            scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope3_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope3_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope3_tier2[ind].y = scope3_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope3_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope3_tier2[ind].subset[subind][1] = scope3_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });
                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope3_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
        }

        return monthly_fg;
    };
    const showSite = () => {
        let site_ids = [],
            site_names = [];
        dcfass.forEach((i) => {
            !site_ids.includes(i.site) && site_ids.push(i.site);
        });

        site_ids.forEach((i, j) => {
            site_names.push(
                locationList.find((k) => {
                    return k.id === i;
                }).title
            );
        });

        return site_names;
    };

    const getTableContent = (a, b, c) => {
        return (
            <div class="gridlines-container">
                <table class="gridlines">
                    <tbody>
                        <tr>
                            <td colspan="1" rowspan="1" className="text-center">
                                {a}
                            </td>
                            <td colspan="1" rowspan="1" className="text-center" >
                                {b}
                            </td>
                            <td colspan="1" rowspan="1" className="text-center">
                                {c}
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        )
    }
    return (
        <div>
            <div className="col-12" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div className="col-3 p-card" style={{ margin: 5, height: "calc(100vh - 9rem)", overflow: "scroll" }}>
                    <nav>
                        {headings.map((heading, ind) => {
                            let indexes = [];
                            return (
                                <>
                                    {heading.level === 0 ? (
                                        <label key={heading.id} style={{ display: "flex", margin: 5, fontWeight: activeId === heading.id ? "bold" : "normal", textDecoration: heading.text.includes("SECTION") && "underline" }} className={getClassName(heading.level)}>
                                            <a target="_blank"
                                                href={`#${heading.id}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    document.querySelector(`#${heading.id}`).scrollIntoView({
                                                        behavior: "smooth",
                                                        block: "start",
                                                        inline: "nearest",
                                                    });
                                                }}
                                                style={{
                                                    fontWeight: activeId === heading.id ? "bold" : "normal",
                                                }}
                                            >
                                                {heading.text}
                                            </a>
                                        </label>
                                    ) : (
                                        <ul>
                                            {heading.item.map((item, ind2) => {
                                                return (
                                                    <li key={item.id} className={getClassName(item.level)}>
                                                        <a target="_blank"
                                                            href={`#${item.id}`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                document.querySelector(`#${item.id}`).scrollIntoView({
                                                                    behavior: "smooth",
                                                                });
                                                            }}
                                                            style={{
                                                                fontWeight: activeId === item.id ? "bold" : "normal",
                                                            }}
                                                        >
                                                            {item.text}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </>
                            );
                        })}
                    </nav>
                </div>
                <div className="col-9 p-card" style={{ margin: 5, height: "calc(100vh - 9rem)", overflow: "scroll", color: "white" }}>
                    <div className="col-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button icon='pi pi-eye' style={{ marginRight: 10 }} rounded text raised aria-label="Filter" onClick={() => { exportTable2Excel(1) }}> </Button>

                        <Button icon='pi pi-cloud-download' rounded text raised aria-label="Filter" onClick={() => { exportTable2Excel(0) }}> </Button>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* <img id='ass' src='https://mpower-s3.s3-ap-southeast-1.amazonaws.com/uploads/hazards/1690426362525Pushing%20and%20Pulling.png' width={'100%'} /> */}
                        <div>
                            <div className="col-12 grid" style={{ margin: 5, color: "white" }}>
                                <div>
                                    <label
                                        style={{
                                            color: "black",
                                            padding: 15,
                                            justifyContent: "flex-start",
                                            display: "flex",
                                        }}
                                    >
                                        Select Year :
                                    </label>
                                </div>
                                <div className="col-4">
                                    <Dropdown options={[{ name: 2022 }, { name: 2023 }]} value={year} optionLabel="name" optionValue="name" onChange={(e) => { setYear(e.value) }} />
                                </div>

                            </div>

                        </div>
                        <div id="main" className="EcoVadis" style={{ flexDirection: "column", display: "flex" }}>

                            {getTableContent("Mandatory", "GEN3034", "Low Impact on the Theme Score ")}
                            <div className="para m-3">
                                <label className="fb5" >Has your company obtained accreditation from the Association for Assessment and Accreditation of Laboratory Animal Care (AAALAC)?
                                </label>
                                <ul className="pad30">

                                    <li className={getClassNameByRFResponse(0, '')}>Yes, my company performs animal testing and has obtained accreditation
                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No, my company performs animal testing but not obtained accreditation</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>My company does not perform animal testing
                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "GEN3037", "Low Impact on the Theme Score ")}
                            <div className="para m-3">
                                <label className="fb5" > Does your company formally and publicly adhere to any external CSR initiatives or set of principles?
                                </label>
                                <ul className="pad30">

                                    <li className={getClassNameByRFResponse(0, '')}> United Nations Global Compact (UNGC)
                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}> Science Based Targets Initiative (SBTi)
                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}> Responsible Care Global Charter                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>Pharmaceutical Supply Chain Initiative (PSCI)                            </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>AMR Industry Alliance   </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}> Other external CSR initiative or set of principles
                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>No endorsement/Do not know

                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "GEN5004", "Medium Impact on the Theme Score ")}
                            <div className="para m-3">
                                <label className="fb5" > Has your company been audited by an independent external auditor regarding sustainability issue(s) (e.g. environment, labor & human rights, ethics, sustainable procurement), at one or more of your company's locations? If yes, upload the audit report and/or Corrective Action Plan Report that show the latest non-compliance result. Each report's publication date must be within two years of your questionnaire's submission date.                                </label>
                                <ul className="pad30">

                                    <li className={getClassNameByRFResponse(0, '')}>Yes, virtual audit
                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>
                                        Yes, on-site audit
                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}> No audit                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "GEN600", "Medium Impact on the Theme Score ")}

                            <div className="para m-3">
                                <label className="fb5" >Which of the following applies to your company’s reporting on quantitative indicators on CSR topics?

                                </label>
                                <ul className="pad30">



                                    <li className={getClassNameByRFResponse(0, '')}> The indicators are externally verified by an independent third-party

                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>The Key Performance Indicators are formally aligned with a sustainability reporting standard (e.g. GRI, SASB, other - please specify
                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}> A materiality analysis was conducted to identify relevant indicators
                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}> My company reports on quantitative indicators on CSR but none of the above applies
                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>My company does not report on any quantitative CSR indicators/Do not know

                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "GEN4001", "Medium Impact on the Theme Score ")}
                            <div className="para m-3">
                                <label className="fb5" > Does your company communicate progress towards the Sustainable Development Goals (SDGs)?

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Yes,</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>

                            </div>

                            {getTableContent("Mandatory", "LAB100", "High Impact on the Theme Score")}

                            <div className="para m-3">
                                <label className="fb5" > Does your company have a policy regarding labor practices or human rights issues?


                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Employees health and safety (e.g. management of employees health and safety issues)</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Working Conditions (e.g. wages, benefits, working hours, two-way communication on working conditions)</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Labor Relations (e.g. structured relations with employee representatives/trade unions)</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Career Management (e.g. management of recruitment, training & career development)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Child and Forced Labor (e.g. engagements or measures taken to prevent or eradicate child or forced labor)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Diversity, Equity & Inclusion</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>External stakeholder human rights</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>No policies</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>

                            </div>

                            {getTableContent("Mandatory", "LAB1001", "High Impact on the Theme Score")}

                            <div className="para m-3">
                                <label className="fb5" > Does your company's health & safety policy also cover activities carried out by your subcontractors? (e.g. at construction sites, on chartered vessels)

                                </label>

                                <ul className="pad30">





                                    <li className={getClassNameByRFResponse(0, '')}>Yes</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>No</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>No subcontractors working on the company premises or on construction sites</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>

                            </div>

                            {getTableContent("Mandatory", "LAB1101", "High Impact on the Theme Score")}

                            <div className="para m-3">
                                <label className="fb5" >Has your company formalized a process to assess and document employee health and safety risks considering start-up of new operations, change of operations and/or periodic review of risks related to current activities?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Yes</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>No</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Do not Know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                </ul>


                            </div>


                            {getTableContent("Mandatory", "LAB310", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" >What actions are in place regarding employee health and safety?
                                </label>
                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Employee health and safety risk assessment</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Health and safety emergency action plan</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Training of all employees on health and safety risks and good working practices</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Complaints procedure for employees to report on occupational health and safety incidents, risks, and concerns</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Equipment safety inspections or audits</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Regular employee health check-up</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Health and safety training for subcontractors working on premises (if applicable)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Preventive actions for repetitive strain injury (RSI)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Actions to address stress and psychological wellbeing in the workplace</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Actions to control hazardous substance exposure</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>Other actions on employee health & safety not included elsewhere in this question</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}




                                    <li className={getClassNameByRFResponse(0, '')}>No actions yet/Do not know</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "LAB3210", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" >  What actions are in place regarding working conditions?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Two-way communication system in place to facilitate employee voice regarding working conditions</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>Compensation for extra or atypical working hours</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>Family Friendly programs (FFPs) implemented (e.g. parental or care leaves, childcare services or allowances)</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>Flexible organization of work (eg. remote work, flexi-time)</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>Health care coverage of employees in place</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>Communication to all employees of remuneration process (e.g. salary grid, procedure for salary advancement)</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>Employee satisfaction survey</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>Employee stock ownership plan (not restricted to executive level)</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>Bonus scheme related to company performance</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to ensure good working conditions not included elsewhere in this question</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>No actions / do not know</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}


                                </ul>
                            </div>

                            {getTableContent("Mandatory", "LAB330", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > What actions are in place regarding social dialogue?

                                </label>

                                <ul className="pad30">

                                    <li className={getClassNameByRFResponse(0, '')}>Employee representatives or employee representative body (e.g. works council)</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>European Works Council in place</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Collective agreement on employees’ health & safety</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Collective agreement on working conditions</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Collective agreement on training & career management</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Collective agreement on diversity, discrimination and/or harassment</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to promote structured social dialogue not included elsewhere in this question</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No actions yet/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>


                            </div>

                            {getTableContent("Mandatory", "LAB340", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > What actions are in place regarding training and career management?

                                </label>

                                <ul className="pad30">

                                    <li className={getClassNameByRFResponse(0, '')}>Transparent recruitment process (communicated clearly and formally to all candidates)</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Regular assessment (at least once a year) of individual performance</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Setting of Individual development and career plan for all employees</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Official measures promoting career mobility</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Provision of skills development training</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Official measures to anticipate or reduce layoffs and associated negative impacts (e.g. financial compensation, outplacement</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>service)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions on career management & training not included elsewhere in this question</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No specific mechanisms in place</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "LAB3601", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" >
                                    What actions are in place to promote diversity, equity & inclusion?

                                </label>
                                <ul className="pad30">

                                    <li className={getClassNameByRFResponse(0, '')}>Actions to prevent discrimination during recruitment phase</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Actions to prevent discrimination in professional development and promotion processes</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Awareness training on diversity, discrimination and/or harassment issues</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Actions to prevent workplace harassment</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Affinity or other support groups for minorities/vulnerable groups</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Women's development, mentorship, and/or sponsorship programs in place</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Actions to promote the inclusion of employees with disabilities</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Grievance mechanism on discrimination and/or harassment issues</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Remediation procedure in place for victims of discrimination and/or harassment</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Actions to promote wage equality in the workplace (e.g. equal pay monitoring)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to promote the inclusion of minority/vulnerable groups in the workplace</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to promote gender inclusion in the workplace</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No actions/ Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>

                            </div>

                            {getTableContent("Mandatory", "LAB3707", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" >What actions are in place to ensure respect for external stakeholder human rights (e.g local communities, civil society)?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Human rights impact assessments performed to identify potential or actual impacts on external stakeholders</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Stakeholder consultation with local populations in line with the principle of Free, Prior and Informed Consent (FPIC)</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>Awareness training specific to external stakeholder human rights performed</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Formal collaboration/partnerships with local NGOs and/or local authorities to address external stakeholder human rights issues</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Grievance mechanism for external and internal stakeholders to report external human rights impacts</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Remediation procedure in place for violations of external stakeholders’ human rights</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions on external stakeholder human rights not included elsewhere in this question</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No actions/ Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "LAB383")}
                            <div className="para m-3">
                                <label className="fb5" > Do workers have the right to join labor unions, workers’ councils, or other collective bargaining organizations?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Yes, the right to join labor unions, workers' councils, or other collective bargaining organizations is granted</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Partially yes, the right to join labor unions, workers' councils, or other collective bargaining organizations is granted, but restricted in</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>compliance with applicable law</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No, the right to join labor unions, workers' councils, or other collective bargaining organizations is not granted, but the company</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>fullfils local law or regulations</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No, the right to join labor unions, workers' councils, or other collective bargaining organizations is not granted</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "LAB1007")}
                            <div className="para m-3">
                                <label className="fb5" >Does your company employ migrant workers?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Yes
                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>I don't know
                                    </li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>

                            </div>

                            {getTableContent("Mandatory", "LAB7101", "Medium Impact on the Theme Score ")}
                            <div className="para m-3">
                                <label className="fb5" > Has your company obtained any labor and human rights management system certification?
                                </label>
                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>ISO 45001</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>MASE</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>RCMS (Responsible Care Management System)</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>RC14001 (Responsible Care 14001)</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Fair Wage Network (Fair Wage Certification or Living Wage Certification)</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Others</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No/Do not know</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "LAB4501", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Please identify the coverage (in %) of your company's actions throughout all company operations/workforce on one or more of the following items:
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>% of all operational sites for which an employee health & safety risk assessment has been conducted (please specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>% of the total workforce across all locations represented in formal joint management-worker health & safety committees</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>% of the total workforce across all locations who are covered by formal collective agreements concerning working conditions (please</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>% of the total workforce across all locations who are covered by formal collective agreements concerning working conditions (please</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>% of the total workforce across all locations who received regular performance and career development reviews (please specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>% of the total workforce across all locations who received career- or skills-related training (please specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>% of the total workforce across all locations who received training on diversity, discrimination and/or harassment</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>% of all operational sites that have been subject to human rights reviews or human rights impact assessments (please specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other indicator on the % of the deployment of labor or human rights actions throughout all company operations/workforce (please</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No labor or human rights actions in place</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "LAB720", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > What is the percentage of operational facilities that are certified ISO 45001 or against other labor or human rights
                                    management standard?

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>0-20%</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>21-40%</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>41-60%</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>61-80%</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>81-100%</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Not applicable (if only one operational site)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Not Monitored</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "LAB601", "Medium Impact on the Theme Score ")}
                            <div className="para m-3">
                                <label className="fb5" > On which of the following topics does your company report Key Performance Indicators (KPIs)?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Employee Health & Safety</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Working Conditions</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Social Dialogue</li>


                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Career Management & Training</li>


                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Child Labor, Forced Labor and Human Trafficking (if applicable)</li>


                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Diversity, Equity & Inclusion</li>


                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>External Stakeholder Human Rights (if applicable)
                                    </li>


                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                    <li className={getClassNameByRFResponse(0, '')}>None of the above/Do not know

                                    </li>


                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "LAB610", "Medium Impact on the Theme Score ")}
                            <div className="para m-3">
                                <label className="fb5" > Please report on the following KPIs related to employee health & safety (please specify the year)

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Lost time injury (LT I) frequency rate for direct workforce - (total number of lost time injury events) x 1,000,000 / total hours worked</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>company wide</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Lost time injury (LT I) severity rate for direct workforce - (number of days lost due to injuries) x 1,000 / total hours worked</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Not monitored/Do not know</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "LAB620", "Medium Impact on the Theme Score ")}
                            <div className="para m-3">
                                <label className="fb5" > Please report on the following KPI related to career management & training (please specify the year)
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}> Average hours of training provided per employee
                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Not monitored/Do not know
                                    </li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>


                            </div>

                            {getTableContent("Mandatory", "LAB561", "Medium Impact on the Theme Score ")}
                            <div className="para m-3">
                                <label className="fb5" > Please report on the following KPIs related to workplace diversity
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Percentage of women employed in the whole organization</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Percentage of women in top executive positions (excluding boards of directors)</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Percentage of women within the organization’s board</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Average unadjusted gender pay gap</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Percentage of employees from minority and/or vulnerable groups in the whole organization (if applicable)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Percentage of employees from minority and/or vulnerable groups in top executive positions (excluding boards of directors) (if</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>applicable)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Not monitored/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Optional", "LAB800")}
                            <div className="para m-3">
                                <label className="fb5" > Additional comments regarding Labor and Human Rights topics of your company's management system. Please also indicate any CSR-related external recognition obtained by your company within the last five years, such as CSR-related awards, or listing in CSR rankings or indexes.

                                </label>
                                <ul>
                                    <li className={getClassNameByRFResponse(0, '')}>Please specify
                                    </li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV100", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Does your company have a formalized environmental policy? (for suppliers environmental issues, see section SUSTAINABLE PROCUREMENT)

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Energy Consumption and Greenhouse gases</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Water</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Biodiversity</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Air Pollution</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Materials, Chemicals and Waste</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Environmental impacts from use of products</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Environmental impacts from Products End-of-Life (e.g. recycling of products)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Customer Health and Safety</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Promotion of Sustainable Consumption</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No Policies</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV101", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Does your company have a formalized policy on the appropriate levels of discharges of API (active pharmaceutical ingredients) to the environment from manufacturing operations?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Yes, my company has a formalized policy to limit the discharges of API (active pharmaceutical ingredients)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV2301", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Has your company formalized a process to assess and document environmental risks considering start-up of new operations, change of operations and/or periodic review of risks related to current activities?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Yes, my company has a formalized process to assess and document environmental risks
                                    </li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV2301", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > What actions are in place regarding the reduction of energy consumption and the emissions of GHG?
                                </label>
                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Training of employees on energy conservation/climate actions</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Purchase(s) and/or generation of renewable energy</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Purchase(s) of verified carbon offset credits</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Energy and/or carbon audit</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Improvement of energy efficiency through technology or equipment upgrades</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Use of waste heat recovery system(s) or combined heat and power unit(s)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Fuel switch to achieve higher energy efficiency and/or lower carbon emission intensity</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Use of carbon capture and storage technology (CCS)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to reduce energy consumption/GHG emissions not included elsewhere in this question</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No actions/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV3228", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > What actions are in place regarding water management?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Technologies or practices to recycle or reuse water</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Water accounting or auditing performed</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Adoption of cooling systems with reduced or recycled water consumption</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Reduction of water consumption through technology or equipment not mentioned above</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Water-stress assessment or mapping performed to identify exposure to water-related risks</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>On-site or off-site wastewater treatment facilities</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Wastewater quality assessment</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Implementation of a rainwater harvesting system</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Control measures to prevent contamination of groundwater</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Removal of Active Pharmaceutical Ingredient (APIs) from wastewater</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to manage water efficiency or wastewater discharge not included elsewhere in this question</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No actions/ Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV3499", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" >
                                    What actions are in place regarding air pollution?

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Actions to prevent emissions of atmospheric pollutants and other environmental nuisances (e.g. noise, odor, vibration, road and light)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Work processes or technologies implemented to mitigate emissions of dust and/or particulate matter</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Periodical analysis on the volumes of major air pollutants or ambient air quality monitoring (testing levels of PM, NOx, SO2, VOC or</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>heavy metals)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Work processes or technologies implemented to mitigate emissions of VOC, SO2, NOx or heavy metals</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Regular noise measuring campaign (site boundary noise measurements undertaken)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Work processes or technologies implemented to mitigate noise</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Work processes or technologies implemented to mitigate odor</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Use of biofilters to minimize odor generated in plant operations</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to mitigate air pollution not included elsewhere in this question</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No actions/ Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>



                            </div>

                            {getTableContent("Mandatory", "ENV3525", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > What actions are in place regarding hazardous materials?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Reduction of material consumption through process optimization</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Use of recovered input materials</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Use of eco-friendly or bio-based input materials</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Environmental emergency measures in place</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Actions for labelling, storing, handling and transporting hazardous substances</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Training employees to safely handle and manage hazardous substances</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Specialized treatment and safe disposal of hazardous substances</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Use of alternative, less hazardous substances in operations</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Reduction of hazardous substance generation or toxicity</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Actions to reduce or eliminate the use and release of Persistent Organic Pollutants (if applicable)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to ensure safe management of hazardous substances not included elsewhere in this question</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No actions/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV3553", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > What actions are in place regarding waste management?

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Reduction of internal wastes through material reuse, recovery or repurpose</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Actions or training to raise employee awareness on waste reduction & sorting</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Internal sorting & disposal of waste according to waste streams</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Mapping of waste streams</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Actions to restrict the transboundary movement of hazardous waste</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to manage waste not included elsewhere in this question</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No actions/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV3872", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > What actions are in place regarding protection of customer/consumer health and safety?

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Company awareness program and detailed information to customers on health and safety issues associated with your</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>products/services</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Provision of information on product composition</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Product recall process</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Provision of information on potential health impacts of products/services (e.g. external or internal analyses of health and safety</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>issues)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Provision of Safety Data Sheet (SDS)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Formalized process in place to assess and document risks related to customer health and safety (e.g. considering start-up of new</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>operations, change of operations and/or periodic review of risks related to current activities)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Measures in place to assess the potential adverse impacts of nanotechnologies or other emerging technologies</li>  {getClassNameByRFResponse(0, '') === 'check' &&




                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to ensure customer health & safety not included elsewhere in this question</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No specific mechanisms</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV3901")}
                            <div className="para m-3">
                                <label className="fb5" > What is the situation of your company regarding the REACH regulation?

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>REACH may apply to some of the chemical substances my company manufactures or buys but my company only has operations</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>outside of the EU (please specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>None of the chemicals substances imported or manufactured in the EU need to be registered (please specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV391", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Which actions have been implemented to meet requirements of the REACH regulation?

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Registration of substances to the ECHA (including via "Only Representative")</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Provision of safety data sheets (SDS) adapted for the REACH regulation</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Communication to downstream users regarding the use of dangerous substances and/or substances of very high concern (SVHC), if</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>applicable</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to ensure compliance with the REACH regulation not included elsewhere in this question</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No actions implemented</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV411", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > What actions are in place to reduce environmental impacts from product end of life?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Packaging designed for easy dismantling / recyclability (please specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Company collection programs for end-of-life product (e.g. formal partnership established)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to manage direct environmental impacts generated from the end-of-life of the company’s products not included</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>elsewhere in this question</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No specific mechanisms in place</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV6313")}
                            <div className="para m-3">
                                <label className="fb5" > Does your company have sites/operations located in or near to biodiversity-sensitive areas where your activities negatively affect those areas?

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>My company doesn't have sites/operations located in or near biodiversity-sensitive areas</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>My company has sites/operations located in or near biodiversity-sensitive areas but my activities do not negatively affect those areas</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>My company have sites/operations located in or near biodiversity-sensitive areas and my activities negatively affect those areas</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV7109", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Has your company obtained any environmental management system certification?

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>ISO 14001</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>EMAS</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>RCMS (Responsible Care Management System)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>RC14001 (Responsible Care 14001)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Others (please specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV5001", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Please identify the coverage (in %) of your company's actions throughout all company operations on one or more of the following items:
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>% of the total workforce across all locations who received training (internally or externally) on environmental issues (please specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>% of all operational sites for which an environmental risk assessment has been conducted (please specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other indicator on the % of the deployment of environmental actions throughout all company operations (please specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No environmental actions in place</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV720", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Percentage of operational facilities certified ISO 14001, EMAS or against other environmental management standard?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>0-20%</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>21-40%</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>41-60%</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>61-80%</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>81-100%</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Not applicable (if only one operational site)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Not monitored</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV600", "Medium Impact on the Theme Score ")}
                            <div className="para m-3">
                                <label className="fb5" > On which of the following topics does your company report Key Performance Indicators (KPIs)?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Energy Consumption & GHGs</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Water</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Biodiversity</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Air Pollution</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Materials, Chemicals & Waste</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Product Use</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Product End-of-Life</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Customer Health & Safety</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Environmental Services & Advocacy</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>None of the above/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV6001", "Medium Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" >Is your company a respondent to CDP's Climate Change Questionnaire? If so, please upload the latest response you have provided to CDP

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Yes</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>

                            </div>

                            {getTableContent("Mandatory", "ENV630", "Medium Impact on the Theme Score ")}
                            <div className="para m-3">
                                <label className="fb5" > Please report on the following KPIs regarding GHGs emissions:
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Total Scope 1 GHG emissions in metric tons CO2e</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Total Scope 2 GHG emissions in metric tons CO2e</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Total gross Scope 3 GHG emissions</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Total gross Scope 3 Downstream GHG emissions</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Total gross Scope 3 Upstream GHG emissions</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Not monitored/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV6332", "Medium Impact on the Theme Score ")}
                            <div className="para m-3">
                                <label className="fb5" > Please report on the following environmental KPIs

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Total energy consumption in MWh</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Total renewable energy consumption</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Total water consumption</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Total weight of pollutants emitted to water</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Total weight of hazardous waste in tons</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Total weight of non-hazardous waste in tons</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Total weight of waste recovered</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Not monitored/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "ENV6760", "Medium Impact on the Theme Score ")}
                            <div className="para m-3">
                                <label className="fb5" > Please report on the following KPI regarding the collection of unused or expired medicine by your company (please specify the year)

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}> Reporting year </li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}> Amount of unused or expired medicines collected for recycling or waste treatment as a percentage of the total volume of products
                                        sold</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}> Not Monitored/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Optional", "ENV800",)}
                            <div className="para m-3">
                                <label className="fb5" >Additional comments regarding environmental topics of your company's management system. Please also indicate any CSRrelated external recognition obtained by your company within the last five years, such as CSR-related awards, or listing in CSR rankings or indexes.
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Describe your entity's GHG reduction targets (select all that apply)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We publicly announce our targets</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We have an absolute scope 1 reduction target</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We have an absolute scope 2 reduction target</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We have an absolute scope 3 reduction target</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We have an intensity reduction target</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We have committed to or our targets approved by SBT i</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No formal targets yet/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "CAR101", "Low Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Describe your entity's plan to achieve GHG reductions (select all that apply)
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>We have a time-bound action plan to reduce GHG emissions</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We use LCA as an analytical tool for planning</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We have a time-bound action plan to transform into a low carbon business model</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No action plan yet/Do not know</li>
                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "CAR102", "Medium  Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > How does your entity's management structure facilitate GHG emissions reduction? (select all that apply)
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>We have a dedicated budget for GHG management</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We have a management team dedicated to GHG emissions reduction</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Our management team's compensation is linked to progress towards GHG reduction targets</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>GHG emissions reduction is not embedded in the management structure/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "CAR104", "Medium  Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Describe your entity's GHG emissions inventory (select all that apply)

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>We have a GHG inventory at the corporate level in accordance with GHG protocol or other GHG accounting standards</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We have GHG data at the product level in accordance with the GHG Protocol or other GHG accounting standards</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We update our inventory at least once per year</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No GHG inventory yet/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "CAR105", "Medium  Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > What is the scope of the GHG emissions monitoring in your entity? (applicable to scope 1 and 2 emissions only. Select the most suitable answer)
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>We monitor relevant GHG emissions for our entire scope
                                    </li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We monitor GHG emissions with significant exclusions
                                    </li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No GHG inventory yet/Do not know
                                    </li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "CAR107", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Describe your entity's progress towards GHG reduction targets (Select the most suitable answer)

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Our latest review showed that we are falling behind</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Our latest review showed that we are on track to meet our targets</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Our latest review showed that we are ahead</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No review/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "CAR108", "Medium  Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Describe your entity's GHG reporting practices (select all that apply)

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>We share GHG emissions with internal stakeholders</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Our GHG emissions report is publicly available</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We respond to CDP</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We verify our GHG emissions through a third party</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We do not disclose our emissions/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>

                            </div>

                            {getTableContent("Mandatory", "SUP104", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Does your company have a formal policy covering any of the topics below (please select from the options provided)?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(169, 'radio-group-1697527008198-0')}>Sustainable procurement policy on environmental issues</li>

                                    {getClassNameByRFResponse(169, 'file-1697527075824-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(169, 'file-1697527075824-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(169, 'radio-group-1697527051223-0')}>Sustainable procurement policy on labor practices and human rights</li>

                                    {getClassNameByRFResponse(169, 'radio-group-1697527051223-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(169, 'file-1697527075824-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(169, 'radio-group-1697526972770-0')}>No policies</li>

                                    {getClassNameByRFResponse(169, 'radio-group-1697526972770-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(169, 'file-1697527075824-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "SUP300", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > What actions are in place regarding the integration of social or environmental factors within procurement?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(166, 'radio-group-1696418944398-0')}>Supplier CSR code of conduct in place</li>

                                    {getClassNameByRFResponse(166, 'radio-group-1696418944398-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(166, 'file-1697529947356-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(170, 'radio-group-1697530534530-0')}>Integration of social or environmental clauses into supplier contracts</li>

                                    {getClassNameByRFResponse(170, 'radio-group-1697530534530-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(170, 'file-1697530573292-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(174, 'radio-group-1697989031928-0')}>CSR risk analysis (i.e. prior to supplier assessments or audits)</li>

                                    {getClassNameByRFResponse(174, 'radio-group-1697989031928-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(174, 'file-1697989068421-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(171, 'radio-group-1698652069938-0')}>Supplier assessment (e.g. questionnaire) on environmental or social practice</li>

                                    {getClassNameByRFResponse(171, 'radio-group-1698652069938-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(171, 'file-1697530897414-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(173, 'radio-group-1698652357454-0')}>Training of buyers on social and environmental issues within the supply chain</li>

                                    {getClassNameByRFResponse(173, 'radio-group-1698652357454-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(173, 'file-1697988713741-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(174, 'radio-group-1697989129697-0')}>On-site audits of suppliers on environmental or social issues</li>

                                    {getClassNameByRFResponse(174, 'radio-group-1697989129697-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(174, 'file-1697989238169-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(174, 'radio-group-1698652535149-0')}>Capacity building of suppliers on environmental or social issues (e.g. corrective actions, training)</li>

                                    {getClassNameByRFResponse(174, 'radio-group-1698652535149-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(174, 'file-1697988839319-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(175, 'radio-group-1697989391397-0')}>Performing suppliers on environmental and social issues have access to unique incentives (e.g. supplier awards, preferred supplierprogram, access to RFPs)</li>

                                    {getClassNameByRFResponse(175, 'radio-group-1697989391397-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(175, 'file-1697989423308-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    
                                    <li className={getClassNameByRFResponse(175, 'radio-group-1697989491383-0')}>Sustainable procurement objectives integrated into buyer performance reviews</li>

                                    {getClassNameByRFResponse(175, 'radio-group-1697989491383-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(175, 'file-1697989549645-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(176, 'radio-group-1697989713157-0')}>Worker voice surveys or other advanced supplier monitoring practices (e.g. second tier audits)</li>

                                    {getClassNameByRFResponse(176, 'radio-group-1697989713157-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(176, 'file-1697989742684-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to promote sustainable procurement not included elsewhere in this question</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No actions in place/ Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "SUP320", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Do you have a supplier diversity program in place or other actions designed to advance diversity in the supply chain?

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(177, 'radio-group-1704565604812-0')}>Actions to work with women-owned businesses in the supply chain</li>

                                    {getClassNameByRFResponse(177, 'radio-group-1704565604812-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(177, 'file-1704566077686-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(177, 'radio-group-1704565654141-0')}>Actions to work with businesses owned by minorities/vulnerable groups in the supply chain</li>

                                    {getClassNameByRFResponse(177, 'radio-group-1704565654141-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(177, 'file-1698174466570-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(177, 'radio-group-1704565879728-0')}>Actions to advance diversity, equity and inclusion in the workforce of suppliers</li>

                                    {getClassNameByRFResponse(177, 'radio-group-1704565879728-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(177, 'file-1704565955437-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions designed to advance diversity in the supply chain not included elsewhere in this question</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No actions/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "SUP321")}
                            <div className="para m-3">
                                <label className="fb5" > Does your supplier diversity program apply to all your suppliers globally?

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(177, 'radio-group-1698174589254-0')}> Yes, my company has a supplier diversity program in place that applies to all suppliers globally
                                    </li>

                                    {getClassNameByRFResponse(177, 'radio-group-1698174589254-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(177, 'file-1698652863430-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}> No action/ Do not know </li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>


                            </div>

                            {getTableContent("Mandatory", "SUP613", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Has your company performed a formal assessment of its suppliers progress with regards to the REACH regulation?

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(184, 'radio-group-1698655642333-0')}>Yes, my company assesses its suppliers' progress with regards to the REACH requirements</li>

                                    {getClassNameByRFResponse(184, 'radio-group-1698655642333-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(184, 'file-1698655746487-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(184, 'radio-group-1698655642333-0')}>No</li>

                                    {getClassNameByRFResponse(184, 'radio-group-1698655642333-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(184, 'file-1698655746487-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(184, 'radio-group-1698655642333-0')}>Not applicable (no article or substance impacted by the REACH regulation used in processes) (please specify)</li>

                                    {getClassNameByRFResponse(184, 'radio-group-1698655642333-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(184, 'file-1698655746487-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(184, 'radio-group-1698655642333-0')}>Not applicable (e.g. company located outside of the EU and no business with the EU) (please specify)</li>

                                    {getClassNameByRFResponse(184, 'radio-group-1698655642333-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(184, 'file-1698655746487-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "SUP2034", "Medium  Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Has your company obtained any certification regarding sustainable procurement?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(178, 'radio-group-1698174704074-0')}>Yes (please specify)
                                    </li>

                                    {getClassNameByRFResponse(178, 'radio-group-1698174704074-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(178, 'file-1698174726736-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(178, 'radio-group-1698174704074-0')}>No/Do not know</li>

                                    {getClassNameByRFResponse(178, 'radio-group-1698174704074-0') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(178, 'file-1698174726736-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}

                                </ul>
                            </div>

                            {getTableContent("Mandatory", "SUP6100", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Please report on the following KPIs related to sustainable procurement (please specify the year)

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Reporting year</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Percentage of targeted suppliers that have signed the sustainable procurement charter/supplier code of conduct</li>
                                    <label className="" > {handleNaNAndInfinity((getDataByDP('DPK0083',year)/getDataByDP('DPE0001',year))*100)} </label>
                               
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(166, 'file-1697529947356-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>
                                    <li className={getClassNameByRFResponse(0, '')}>Percentage of targeted suppliers with contracts that include clauses on environmental, labor, and human rights requirements</li>
                                    <label className="" > {handleNaNAndInfinity((getDataByDP('DPE0002',year)/getDataByDP('DPE0003',year))*100)} </label>
                                 
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(170, 'file-1697530573292-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>
                                    <li className={getClassNameByRFResponse(0, '')}>Percentage of targeted suppliers that have gone through a CSR assessment (e.g. questionnaire)</li>
                                    <label className="" > {handleNaNAndInfinity((getDataByDP('DPE0005',year)/getDataByDP('DPE0004',year))*100)} </label>

                              
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(171, 'file-1697530897414-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>
                                    <li className={getClassNameByRFResponse(0, '')}>Percentage of targeted suppliers that have gone through a CSR on-site audit</li>
                                    <label className="" > {handleNaNAndInfinity((getDataByDP('DPR0076',year)/getDataByDP('DP0006',year))*100)} </label>

                                
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(174, 'file-1697989238169-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>
                                    <li className={getClassNameByRFResponse(0, '')}>Percentage of buyers across all locations who have received training on sustainable procurement</li>
                                    <label className="" > {handleNaNAndInfinity((getDataByDP('DPR0077',year)/getDataByDP('DPE0007',year))*100)} </label>

                                 
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(173, 'file-1697988713741-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>
                                    <li className={getClassNameByRFResponse(0, '')}>Percentage or number of audited/assessed suppliers engaged in corrective actions or capacity building</li>
                                    <label className="" > {handleNaNAndInfinity(getDataByDP('DPE0008',year))} </label>

                         
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(174, 'file-1697988839319-0').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>
                                    <li className={getClassNameByRFResponse(0, '')}>Other CSR KPIs on suppliers</li>
                                    <label className="" > {handleNaNAndInfinity((getDataByDP('DPE0002',year)/getDataByDP('DPE0003',year))*100)} </label>

                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>
                                    <li className={getClassNameByRFResponse(0, '')}>Not monitored/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "SUP710", "Medium  Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" > Is there any tin, tantalum, tungsten, or gold remaining in the product that your company manufactures, subcontracts, or
                                    sells?
                                </label>
                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Yes</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>T in, tantalum, tungsten or gold only originate from scrap or recycled sources</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No tin, tantalum, tungsten, or gold remaining in any product</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Optional", "SUP800")}
                            <div className="para m-3">
                                <label className="fb5" >Additional comments regarding Sustainable Procurement of your company's management system. Please also indicate any CSR-related external recognition obtained by your company within the last five years, such as CSR-related awards, or listing in CSR rankings or indexes.
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>How does your entity reduce GHG emissions in the supply chain? (select all that apply)</li>


                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We engage suppliers in climate action</li>


                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We select suppliers based on GHG emissions intensity or GHG reduction efforts</li>


                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We partner with suppliers to help them achieve GHG emissions reduction</li>


                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We took other actions to reduce GHG emissions in the supply chain</li>


                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No actions yet/Do not know</li>


                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "CAR106", "Medium  Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" >How does your entity monitor scope 3 emissions? (select all that apply)

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>We screen scope 3 categories to identify the most relevant for our activity</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We use industry-average-data to estimate our scope 3 emissions</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We collect primary scope 3 data from internal stakeholders</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We collect primary scope 3 data from suppliers</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>We do not monitor scope 3 emissions yet/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "FB100", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" >Has your company implemented a formal policy covering any of the following topics?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Corruption</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Conflict of interest</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Fraud</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Money laundering</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Anti-competitive practices</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Information security</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No policy</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "FB3102", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" >What actions are in place to prevent corruption and bribery?

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Awareness training performed to prevent corruption and bribery</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Anti-corruption due diligence program on third parties in place</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Whistleblower procedure for stakeholders to report corruption and bribery</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Corruption risk assessments performed</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Audits of control procedures (e.g. accounting, purchasing etc.) to prevent corruption and bribery</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Specific approval procedure for sensitive transactions (e.g. gifts, travel)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to prevent any form of corruption not included elsewhere in this question</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No actions in place/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "FB3201", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" >What actions are in place to prevent anticompetitive practices?

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Awareness training performed to prevent anti-competitive practices</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Whistleblower procedure for stakeholders to report anti-competitive practices</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Anti-competitive practices risk assessments performed</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Audits of control procedures to prevent anti-competitive practices</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Provision of competitor interaction guidelines to key employees</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to prevent anticompetitive practices not included elsewhere in this question</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No actions in place/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "FB3301", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" >What actions are in place regarding information security?
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Awareness training to prevent information security breaches</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Information security due diligence program on third parties in place</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Whistleblower procedure for stakeholders to report information security concerns</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Information security risk assessments performed</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Audits of control procedures to prevent information security breaches</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Incident response procedure (IRP) to manage breaches of confidential information</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Implementation of a records retention schedule</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Measures to protect third party data from unauthorized access or disclosure</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Measures for gaining stakeholder consent regarding the processing, sharing and retention of confidential information</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other actions to ensure information security not included elsewhere in this question</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No actions in place/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "FB4501", "Medium  Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" >Has your company obtained external certifications related to business ethics issues (e.g. anti-corruption, information security)?

                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>ISO 27000</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>RCMS (Responsible Care Management System)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>RC14001 (Responsible Care 14001)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other anti-corruption certification schemes (e.g. Ethic Intelligence, Trace, etc) (please specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other information security certification schemes (e.g. Cyber Essentials etc.) (please specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Others (please specify)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>No/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Mandatory", "FB610", "High Impact on the Theme Score")}
                            <div className="para m-3">
                                <label className="fb5" >Please report on the following Key Performance Indicators (KPIs) related to ethics (please specify the year)
                                </label>

                                <ul className="pad30">
                                    <li className={getClassNameByRFResponse(0, '')}>Reporting year</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Percentage of total workforce trained (e.g. through e-learning) on business ethics issues</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Number of reports related to whistleblower procedure</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Number of confirmed corruption incidents</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Number of confirmed information security incidents</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Percentage of all operational sites for which an internal audit/risk assessment concerning business ethics issues has been conducted</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Percentage of all operational sites with certified anti-corruption management system</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Percentage of all operational sites with an information security management system (ISMS) certified to ISO 27000 (or other</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>equivalent/similar standard)</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Other KPIs on ethics</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                    <li className={getClassNameByRFResponse(0, '')}>Not monitored/Do not know</li>

                                    {getClassNameByRFResponse(0, '') === 'check' &&
                                        <>
                                            <label className="fb5" >Attachment</label>
                                            <ol>
                                                {getAttachmentByRFResponse(0, '').map((i, j) => {

                                                    return (

                                                        <li>
                                                            <a target="_blank" href={API.Docs + i.originalname}>{i.originalname.substring(13)}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </>}
                                </ul>
                            </div>

                            {getTableContent("Optional", "FB800")}
                            <div className="para m-3">
                                <label className="fb5" >Additional comments regarding Ethics of your company's management system. Please also indicate any CSR-related external recognition obtained by your company within the last five years, such as CSR-related awards, or listing in CSR rankings or indexes.

                                </label>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(EcoVadisReport, comparisonFn);
