import React, { useState, useEffect } from "react";
import APIServices from "../../service/APIService";
import { API, baseurl } from "../../constants/api_url";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DateTime } from "luxon";
import useForceUpdate from "use-force-update";
import { Button } from "primereact/button";
import ChartDL from "../../assets/js/chartjsplugin";
import { useHistory } from 'react-router-dom'
import * as chartTrendline from "chartjs-plugin-trendline";
import chart, { Chart } from "chart.js/dist/chart";
import { registerables } from "chart.js";
import { Dialog } from "primereact/dialog";

import Swal from "sweetalert2";
Chart.register(chartTrendline);
Chart.register(...registerables);

const Approver = () => {
    let color = ['#D7E7EF', '#93D3F1', '#41C7F3', '#0797D6', '#1F60AB', '#09276D']
    const [indicator, setIndicator] = useState([{ title: 'HR' }, { title: 'Energy & Emissions' }])
    const emissionFactor = useSelector((state) => state.emissionfactor.emissionFactor)
    let months = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"];
    const navigate = useHistory()
    const [selected, setSelected] = useState({ year: 2022, country: 0, city: 0, site: 0 })
    const [siteRawList, setRawSitelist] = useState([])
    const [selectedSite, setSelectedSite] = useState(null)
    const [selectedList, setSelectedList] = useState({ country: [], city: [], site: [] })
    const [active, setActive] = useState(0)
    const [status, setStatus] = useState({ one: false, two: false, three: false, four: false })
    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data = useSelector((state) => state.user.admindetail)
    const [approvedList, setApprovedList] = useState([])
    const [pendingList, setPendingList] = useState([])
    const [showappdialog, setShowAppDialog] = useState(false)
    const [showpendialog, setShowPenDialog] = useState(false)
    const [approvedListBk, setApprovedListBk] = useState([])
    const userList = useSelector(state => state.userlist.userList)
    const [dcfresponse, setDCFResponse] = useState([])
    const [dcflist, setDCFList] = useState([])
    const [sitelist, setSiteList] = useState([])
    const [userdcfass, setUserDCFAss] = useState([])
    const forceUpdate = useForceUpdate()

    useEffect(() => {

        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]
        }
        let uriString3 = {
            "include": [{ "relation": "newDataPoints" }]
        }
        let uriString2 = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]
        }
        let sub = admin_data.id === 140 ?   (baseurl+'user-profiles/' + admin_data.id + '/submit-dcfs') : API.DCF_Submit_UP(admin_data.id)
        let dcf_list = [], dcf_submitted = [], locloc = []
        let site_url = API.LocationOne_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;
        const promise0 = APIServices.get(site_url)
        const promise1 = APIServices.get(API.DCF)
        const promise2 = APIServices.get(sub)
        const promise3 = APIServices.get(API.AssignDCFUser_UP(admin_data.id))
        let promise4 = APIServices.get(API.RF)
        let promise5 = APIServices.get(API.RF_Submit_UP(admin_data.id))
        let promise6 = APIServices.get(API.Report_Name_Twos)
        let promise7 = APIServices.get(API.QL_Listing_Filter_UP(admin_data.id))

        let Overall = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;

        let url = API.Metric + `?filter=${encodeURIComponent(JSON.stringify(uriString3))}`;
        let promise8 = APIServices.get(Overall)
        let promise9 = APIServices.get(API.RF_User_UP(admin_data.id))
        let promise10 = APIServices.get(API.AssignDCFClient_UP(admin_data.id))
        Promise.all([promise0, promise1, promise2, promise3]).then(function (values) {
            // let site_list = values[0].data.flatMap(i =>  i.locationTwos !== undefined &&  i.locationTwos.flatMap(j => j.locationThrees !== undefined &&  j.locationThrees.map(k => { return { ...k, country: i.name, country_id: i.id, city_id: j.id, city: j.name } } )))
            const shapedSite = values[0].data.map(item => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(locationTwo =>
                        locationTwo.locationThrees && locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            }).filter(item => item.locationTwos && item.locationTwos.length > 0)
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            shapedSite.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                i.locationTwos.forEach((j) => {
                    city.push({ name: j.name, id: j.id })
                    j.locationThrees.forEach((k) => {
                        site.push({ name: k.name, id: k.id })
                    })
                })
            })

            setSelectedList({ country, city, site })
            setRawSitelist(shapedSite)
            let site_list = values[0].data.flatMap(i =>
                (i.locationTwos ?? []).flatMap(j =>
                    (j.locationThrees ?? []).map((k) => { return { ...k, country: i.name, country_id: i.id, city_id: j.id, city: j.name } })
                )
            );
            console.log(site_list)
            let site_ids = values[0].data.flatMap(item =>
                (item.locationTwos ?? []).flatMap(locationTwo =>
                    (locationTwo.locationThrees ?? []).map((k) => { return k.id })
                )
            );
            setSiteList([{ name: 'All', id: 0 }, ...site_list])
            let dcf_ids = values[1].data.map(i => i.id)
            dcf_list = values[1].data
            setDCFList(dcf_list)
            setDCFResponse(values[2].data)

            // console.log(splitDataByMonth(sify,0))
            let approved_data = values[2].data.filter(i => { return (site_ids.includes(i.site) && i.type === 1 && dcf_ids.includes(i.dcf)) })
            approved_data.forEach(item => {
                item.dcf_ = dcf_list.find(i => i.id === item.dcf)
                item.site_ = site_list.find(i => i.id === item.site)
                item.rp = getRPMonth(item.reporting_period)
            })
            console.log(approved_data)
            setApprovedListBk(approved_data)
            setUserDCFAss(values[3].data.filter(i => { return (site_ids.includes(i.site[0]) && i.type === 0 && dcf_ids.includes(i.dcfId)) }))
            setSelectedSite(0)
        })

    }, [])
    const getRPMonth = (item) => {

        if (item.length !== 0) {
            if (item.length >= 2) {
                const startDate = DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('MMM-yyyy');
                const endDate = DateTime.fromFormat(item[item.length - 1], 'MM-yyyy').toFormat('MMM-yyyy');
                return `${startDate} to ${endDate}`;
            } else {
                return DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('MMM-yyyy');
            }
        }
    }
    const updatePUEGraph = (sid, yr) => {
        let site_ids = sid
        let loc_approvedList = approvedListBk.filter(i => { return site_ids.includes(i.site) && i.type === 1 && checkRPbyFY(i.reporting_period[0], yr) && [257].includes(i.dcf) })
        setApprovedList(loc_approvedList)
        let sify = filterResponseBySIFY(loc_approvedList, 1, site_ids, [257], yr, 0)


        let month_obj = splitDataByMonth(sify, 0)
        console.log(month_obj)
        let r = [null, null, null, null, null, null, null, null, null, null, null, null]
        let nr = [null, null, null, null, null, null, null, null, null, null, null, null]
        let ic = [null, null, null, null, null, null, null, null, null, null, null, null]

        let total_ic = [null, null, null, null, null, null, null, null, null, null, null, null]
        let it_ic = [null, null, null, null, null, null, null, null, null, null, null, null]
        let total_load = [null, null, null, null, null, null, null, null, null, null, null, null]
        let it_Load = [null, null, null, null, null, null, null, null, null, null, null, null]
        let both_ic = [null, null, null, null, null, null, null, null, null, null, null, null]
        let pending = []
        let added_month = []
        Object.keys(month_obj).forEach(key => {
            added_month.push(key.split('-')[0].toLowerCase())
            site_ids.forEach(site_id => {
                if (month_obj[key].filter(i => i.site === site_id && i.dcf === 257).length === 0) {
                    pending.push({ dcf: dcflist.find(i => i.id === 257), site: sitelist.find(i => i.id === site_id), reporting_period: key, overdue: (DateTime.utc().diff(DateTime.fromFormat(key, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }
            })
            let month_ind = months.findIndex(i => i.toLowerCase() === key.split('-')[0].toLowerCase())
            ic[month_ind] = month_obj[key].filter(i => i.dcf === 257).length === (site_ids.length)
            total_load[month_ind] = month_obj[key].flatMap(i => i.dcf === 257 && i.response.map(j => j.DPAN098)).reduce((a, b) => { return a + b }, 0)

            both_ic[month_ind] = month_obj[key].filter(i => i.dcf === 257).length === (site_ids.length)
            total_ic[month_ind] = month_obj[key].filter(i => i.dcf === 257).length === (site_ids.length)


            month_obj[key].forEach((i) => {
                let total = 0
                console.log(i)
                i.response.forEach((j) => {

                    total += j.DPAN098
                    if (j.DPAN096 === 468) {
                        r[month_ind] = r[month_ind] === null ? j.DPAN098 : r[month_ind] + j.DPAN098
                    } else if (j.DPAN096 === 469) {
                        nr[month_ind] = nr[month_ind] === null ? j.DPAN098 : nr[month_ind] + j.DPAN098
                    }
                })

            })
        })

        months.forEach((i) => {

            if (!added_month.includes(i.toLowerCase())) {

                site_ids.forEach((j) => {
                    console.log(sitelist.find(i => i.id === j), j, sitelist)
                    pending.push({ dcf: dcflist.find(i => i.id === 257), site: sitelist.find(i => i.id === j), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })

                })

            }
        })

        setPendingList(pending)
        const { renew, nonrenew } = getPercentageOf2Array(r, nr)
        let datasets1 = [{
            label: 'Renewable',
            data: renew,
            backgroundColor: color[2],

        },
        {
            label: 'Non-renewable',
            data: nonrenew,
            backgroundColor: color[4],

        }]
        let datasets2 = [{
            label: 'Renewable',
            data: r.map(i => i / 1000),
            backgroundColor: color[2],

        },
        {
            label: 'Non-renewable',
            data: nr.map(i => i / 1000),
            backgroundColor: color[4],


        }]

        let loc = { one: false, two: false, three: false, four: false }
        if (both_ic.slice(0, 3).filter(i => i === true).length === 3) {
            loc.one = true
        }
        if (both_ic.slice(3, 6).filter(i => i === true).length === 3) {
            loc.two = true
        }
        if (both_ic.slice(6, 9).filter(i => i === true).length === 3) {
            loc.three = true
        }
        if (both_ic.slice(9, 12).filter(i => i === true).length === 3) {
            loc.four = true
        }
        console.log(it_ic, total_ic)
        setStatus(loc)

        setTimeout(() => {
            showStackedBarChartScope2(datasets1, 's2_1', { measure: '%', labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"], incomplete: ic })
            showStackedBarChartIncomplete(datasets2, 's2_2', { measure: 'MWh', labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"], incomplete: ic })

        }, 500)

    }
    const updatePUEGraph_ = (sid, yr) => {
        let site_ids = sid
        let loc_approvedList = approvedListBk.filter(i => { return site_ids.includes(i.site) && i.type === 1 && checkRPbyFY(i.reporting_period[0], yr) && [257].includes(i.dcf) })
        setApprovedList(loc_approvedList)
        let sify = filterResponseBySIFY(loc_approvedList, 1, site_ids, [257], yr, 0)

        console.log()
        let month_obj = splitDataByMonth(sify, 0)
        console.log(month_obj)
        let r = [null, null, null, null, null, null, null, null, null, null, null, null]
        let nr = [null, null, null, null, null, null, null, null, null, null, null, null]
        let ic = [null, null, null, null, null, null, null, null, null, null, null, null]

        let total_ic = [null, null, null, null, null, null, null, null, null, null, null, null]
        let it_ic = [null, null, null, null, null, null, null, null, null, null, null, null]
        let total_load = [null, null, null, null, null, null, null, null, null, null, null, null]
        let it_Load = [null, null, null, null, null, null, null, null, null, null, null, null]
        let both_ic = [null, null, null, null, null, null, null, null, null, null, null, null]
        let pending = []
        let added_month = []
        Object.keys(month_obj).forEach(key => {
            added_month.push(key.split('-')[0].toLowerCase())
            site_ids.forEach(site_id => {
                if (month_obj[key].filter(i => i.site === site_id && i.dcf === 257).length === 0) {
                    pending.push({ dcf: dcflist.find(i => i.id === 257), site: sitelist.find(i => i.id === site_id), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }
            })
            let month_ind = months.findIndex(i => i.toLowerCase() === key.split('-')[0].toLowerCase())
            ic[month_ind] = month_obj[key].filter(i => i.dcf === 257).length === (site_ids.length)
            total_load[month_ind] = month_obj[key].flatMap(i => i.dcf === 257 && i.response.map(j => j.DPAN098)).reduce((a, b) => { return a + b }, 0)

            both_ic[month_ind] = month_obj[key].filter(i => i.dcf === 257).length === (site_ids.length)
            total_ic[month_ind] = month_obj[key].filter(i => i.dcf === 257).length === (site_ids.length)


            month_obj[key].forEach((i) => {
                let total = 0
                console.log(i)
                i.response.forEach((j) => {

                    total += j.DPAN098
                    if (j.DPAN096 === 468) {
                        r[month_ind] = r[month_ind] === null ? j.DPAN098 : r[month_ind] + j.DPAN098
                    } else if (j.DPAN096 === 469) {
                        nr[month_ind] = nr[month_ind] === null ? j.DPAN098 : nr[month_ind] + j.DPAN098
                    }
                })

            })
        })

        months.forEach((i) => {

            if (!added_month.includes(i.toLowerCase())) {

                site_ids.forEach((j) => {
                    console.log(sitelist.find(i => i.id === j), j, sitelist)
                    pending.push({ dcf: dcflist.find(i => i.id === 257), site: sitelist.find(i => i.id === j), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })

                })

            }
        })

        setPendingList(pending)
        const { renew, nonrenew } = getPercentageOf2Array(r, nr)
        let datasets1 = [{
            label: 'Renewable',
            data: renew,
            backgroundColor: color[2],

        },
        {
            label: 'Non-renewable',
            data: nonrenew,
            backgroundColor: color[4],

        }]
        let datasets2 = [{
            label: 'Renewable',
            data: r.map(i => i / 1000),
            backgroundColor: color[2],

        },
        {
            label: 'Non-renewable',
            data: nr.map(i => i / 1000),
            backgroundColor: color[4],


        }]

        let loc = { one: false, two: false, three: false, four: false }
        if (both_ic.slice(0, 3).filter(i => i === true).length === 3) {
            loc.one = true
        }
        if (both_ic.slice(3, 6).filter(i => i === true).length === 3) {
            loc.two = true
        }
        if (both_ic.slice(6, 9).filter(i => i === true).length === 3) {
            loc.three = true
        }
        if (both_ic.slice(9, 12).filter(i => i === true).length === 3) {
            loc.four = true
        }
        console.log(it_ic, total_ic)
        setStatus(loc)

        setTimeout(() => {
            showStackedBarChartScope2_(datasets1, 's2_1', { measure: '%', labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"], incomplete: ic })
            showStackedBarChartIncomplete_(datasets2, 's2_2', { measure: 'MWh', labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"], incomplete: ic })

        }, 500)

    }
    const updateSCGraph = (sid, yr) => {
        let site_ids = sid
        let loc_approvedList = approvedListBk.filter(i => { return site_ids.includes(i.site) && i.type === 1 && checkRPbyFY(i.reporting_period[0], yr) && [11].includes(i.dcf) })

        let sify = filterResponseBySIFY(loc_approvedList, 1, site_ids, [11], yr, 0)
        let month_obj = splitDataByMonth(sify, 0)
        console.log(month_obj)
        let r = [null, null, null, null, null, null, null, null, null, null, null, null]
        let nr = [null, null, null, null, null, null, null, null, null, null, null, null]
        let sc_ic = [null, null, null, null, null, null, null, null, null, null, null, null]
        let fuel_type = [], fuel_data = []
        let pending = []
        let added_month = []
        Object.keys(month_obj).forEach(key => {
            added_month.push(key.toUpperCase())
            site_ids.forEach(site_id => {
                if (month_obj[key].filter(i => i.site === site_id && i.dcf === 11).length === 0) {
                    pending.push({ dcf: dcflist.find(i => i.id === 11), site: sitelist.find(i => i.id === site_id), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }
            })
            let month_ind = months.findIndex(i => i.toLowerCase() === key.split('-')[0].toLowerCase())
            sc_ic[month_ind] = month_obj[key].filter(i => i.dcf === 11).length === site_ids.length
            month_obj[key].forEach((i) => {
                let r_energy = 0, nr_energy = 0
                console.log(i)
                i.response.forEach((j) => {


                    !fuel_type.includes(j.DPA0131.name) && fuel_type.push(j.DPA0131.name)
                    let ftindex = fuel_data.findIndex(i => { return i.name === j.DPA0131.name && i.order === month_ind })
                    if (ftindex !== -1) {
                        fuel_data[ftindex].qty = fuel_data[ftindex].qty + parseFloat(j.DPA0336)
                    } else {
                        fuel_data.push({ name: j.DPA0131.name, qty: parseFloat(j.DPA0336), order: month_ind })
                    }
                    if (j.DPA0131.name.includes('Coal (industrial)')) {
                        let energy = (parseFloat(j.DPA0336) * 5400 * 4.187) / 1000000000
                        nr[month_ind] = nr[month_ind] === null ? energy : nr[month_ind] + energy

                    } else if (j.DPA0131.name.includes('Diesel (100% mineral diesel)')) {
                        let energy = (parseFloat(j.DPA0336) * 0.82 * 10800 * 4.187) / 1000000000
                        nr[month_ind] = nr[month_ind] === null ? energy : nr[month_ind] + energy

                    } else if (j.DPA0131.name.includes('Wood chips')) {
                        let energy = (parseFloat(j.DPA0336) * 4200 * 4.187) / 1000000000
                        r[month_ind] = r[month_ind] === null ? energy : r[month_ind] + energy
                        // nr[month_ind] = nr[month_ind] === null ? parseFloat(j.DPA0336): nr[month_ind] +  parseFloat(j.DPA0336)
                    } else if (j.DPA0131.name.includes('Fuel oil')) {
                        let energy = (parseFloat(j.DPA0336) * 0.96 * 10500 * 4.187) / 1000000000
                        nr[month_ind] = nr[month_ind] === null ? energy : nr[month_ind] + energy

                    }
                })

            })
        })

        months.forEach((i) => {

            if (!added_month.includes(i)) {

                site_ids.forEach((j) => {

                    pending.push({ dcf: dcflist.find(i => i.id === 11), site: sitelist.find(i => i.id === j), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })

                })

            }
        })
        fuel_type.forEach((item) => {
            let indexes = fuel_data.filter(i => i.name === item).map(j => j.order)
            for (let i = 0; i < 12; i++) {
                if (!indexes.includes(i)) {
                    fuel_data.push({ name: item, order: i, qty: 0 })
                }
            }
        })
        let colors = ['#D7E7EF', '#93D3F1', '#41C7F3', '#0797D6', '#1F60AB']
        let datasets1 = []
        fuel_type.forEach((label, ind) => {
            datasets1.push({
                label: label,
                data: fuel_data.filter(i => i.name === label).sort((a, b) => { return a.order - b.order }).map(j => j.qty),
                backgroundColor: colors[ind]
            })
        })
        console.log(datasets1)
        let datasets2 = [{
            label: 'Renewable',
            data: r,
            backgroundColor: colors[3]
        },
        {
            label: 'Non-renewable',
            data: nr,
            backgroundColor: colors[4]
        }]

        let loc = { one: false, two: false, three: false, four: false }
        if (sc_ic.slice(0, 3).filter(i => i === true).length === 3) {
            loc.one = true
        }
        if (sc_ic.slice(3, 6).filter(i => i === true).length === 3) {
            loc.two = true
        }
        if (sc_ic.slice(6, 9).filter(i => i === true).length === 3) {
            loc.three = true
        }
        if (sc_ic.slice(9, 12).filter(i => i === true).length === 3) {
            loc.four = true
        }
        console.log(sc_ic, datasets1, datasets2)
        setStatus(loc)

        setTimeout(() => {
            showVeritcalGroupBarChart(datasets1, 's1_1', { labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"], incomplete: sc_ic, type: 0 })
            showVeritcalGroupBarChart(datasets2, 's1_2', { labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"], incomplete: sc_ic, type: 0 })
       
        }, 500)

    }
    const getEmissionFactor = (month, dcfid, response) => {
        console.log(response)
        if (dcfid === 11) {
            let total = 0, co2 = 0, ch4 = 0, n2o = 0, sc_data = []
            response.forEach((fg, ind) => {

                let fuel_cat = fg["DPA0130"].name
                let fuel_type = fg["DPA0131"].name
       
                let consumed = parseFloat(fg["DPA0336"])
                let fuel_cat_ind = sc_data.findIndex((k) => { return k.fuel_cat === fuel_cat })

                if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                    let carbon = parseFloat(fg["DPA0134"])
                    let cv = parseFloat(fg["DPA0133"])
                    if (carbon > 0 && cv > 0) {
                        let gj = (carbon * 3.664 * 1000) / cv
                        total = (gj * 0.000004184 * consumed) / 1000
                        fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
                        co2 = 0
                        ch4 = 0
                        n2o = 0
                        if (fuel_cat_ind === -1) {
                            sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                        } else {
                            sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                        }

                    } else {
                        let unit = fg["DPA0132"].name
                        total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                        co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                        ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                        n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                        fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                        if (fuel_cat_ind === -1) {
                            sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                        } else {
                            sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                        }
                    }

                } else {
                    let unit = fg["DPA0132"].name
                    total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                    co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                    ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                    n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                    fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                    if (fuel_cat_ind === -1) {
                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                    } else {
                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                    }
                }


            })
            return sc_data.map(i => i.ghg).reduce((a, b) => { return a + b }, 0)
        } else if (dcfid === 10) {
            let fg_data = [], fg_total = 0
            response.forEach((fg, ind) => {

                let gastype = fg["DPA0136"].name
                let gasrefilled = parseFloat(fg["DPA0138"])
                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled

                fg_total = total + fg_total

                fg['gas'] = gastype
                fg['gasfilled'] = gasrefilled
                fg['value'] = ['EmissionFactor-', total / gasrefilled]
                let updateind = fg_data.findIndex((i) => { return i.gastype === gastype })

                if (updateind === -1) {

                    fg_data.push({ gastype: gastype, ghg: total })



                } else {

                    fg_data[updateind].ghg = fg_data[updateind].ghg + total
                }


            })
            console.log(fg_data)
            return fg_data.map(i => i.ghg).reduce((a, b) => { return a + b }, 0)
        } else if (dcfid === 15) {
            let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData
            let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData

            let total = 0, co2 = 0, ch4 = 0, n2o = 0, mc_data = []

            response.forEach((fg, ind) => {
                console.log(fg)
                let mode = fg['DP_MODE'] ? 'by distance' : 'by fuel'
                let fuel_cat = fg['DPA0140'].name
                let fuel_type = fg['DPA0139'].name
                let fuel = fg["DPA0141"].name
                let unit = fg["DPA0339"].name
                let fuel_cat_ind = mc_data.findIndex((k) => { return k.mode === mode })
                if (mode === 'by distance') {

                    total = ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat && k.vehicle_type === fuel_type && k.fuel_type === fuel && k.unit === unit })[0]['co2e_in_kg'] * parseFloat(fg['DPA0144'])
                    if (fuel_cat_ind === -1) {

                        mc_data.push({ mode: mode, ghg: total })
                    } else {
                        mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                    }
                } else {
                    total = ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2e_in_kg'] * parseFloat(fg['DPA0143'])

                    if (fuel_cat_ind === -1) {

                        mc_data.push({ mode: mode, ghg: total })
                    } else {
                        mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                    }
                }

            })
            console.log(mc_data)
            return mc_data.map(i => i.ghg).reduce((a, b) => { return a + b }, 0)
        }
    }
    const updateS1Graph = (sid, yr) => {
        let site_ids = sid
        let loc_approvedList = approvedListBk.filter(i => { return site_ids.includes(i.site) && i.type === 1 && checkRPbyFY(i.reporting_period[0], yr) && [11, 10, 15].includes(i.dcf) })
        setApprovedList(loc_approvedList)
        let sify = filterResponseBySIFY(loc_approvedList, 1, site_ids, [11, 10, 15], yr, 0)
        let month_obj = splitDataByMonth(sify, 0)
        console.log(month_obj)
        let sc = [null, null, null, null, null, null, null, null, null, null, null, null]
        let fg = [null, null, null, null, null, null, null, null, null, null, null, null]
        let mc = [null, null, null, null, null, null, null, null, null, null, null, null]
        let sc_ic = [null, null, null, null, null, null, null, null, null, null, null, null]
        let fg_ic = [null, null, null, null, null, null, null, null, null, null, null, null]
        let mc_ic = [null, null, null, null, null, null, null, null, null, null, null, null]
        let fuel_type = [], fuel_data = []
        let pending = []
        let added_month = []
        Object.keys(month_obj).forEach(key => {
            added_month.push(key.toUpperCase())
            site_ids.forEach(site_id => {
                if (month_obj[key].filter(i => i.site === site_id && i.dcf === 11).length === 0) {
                    pending.push({ dcf: dcflist.find(i => i.id === 11), site: sitelist.find(i => i.id === site_id), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }
                if (month_obj[key].filter(i => i.site === site_id && i.dcf === 10).length === 0) {
                    pending.push({ dcf: dcflist.find(i => i.id === 10), site: sitelist.find(i => i.id === site_id), reporting_period: key + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(key + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })
                }
            })
            let month_ind = months.findIndex(i => i.toLowerCase() === key.split('-')[0].toLowerCase())
            sc_ic[month_ind] = month_obj[key].filter(i => i.dcf === 11).length === site_ids.length
            fg_ic[month_ind] = month_obj[key].filter(i => i.dcf === 10).length === site_ids.length
            mc_ic[month_ind] = month_obj[key].filter(i => i.dcf === 15).length === site_ids.length
            month_obj[key].forEach((i) => {
                let ef = getEmissionFactor(key, i.dcf, i.response)
                if (i.dcf === 11) {
                    sc[month_ind] = sc[month_ind] === null ? ef : sc[month_ind] + ef
                } else if (i.dcf === 10) {
                    fg[month_ind] = fg[month_ind] === null ? ef : fg[month_ind] + ef
                } else if (i.dcf === 15) {
                    mc[month_ind] = mc[month_ind] === null ? ef : mc[month_ind] + ef
                }

            })
        })

        months.forEach((i) => {

            if (!added_month.includes(i)) {

                site_ids.forEach((j) => {

                    pending.push({ dcf: dcflist.find(i => i.id === 11), site: sitelist.find(i => i.id === j), reporting_period: i + '-' + yr, overdue: (DateTime.utc().diff(DateTime.fromFormat(i + '-' + yr, 'LLL-yyyy').setZone('local'), 'days').days).toFixed(0) })

                })

            }
        })


        let datasets1 = []

        console.log(sc_ic, fg_ic, mc_ic)
        let datasets2 = [{
            label: 'Stationary',
            data: sc,
            backgroundColor: color[0]
        },
        {
            label: 'Fugitive',
            data: fg,
            backgroundColor: color[2]
        },
        {
            label: 'Mobile',
            data: mc,
            backgroundColor: color[4]
        }
        ]


        let s1_ = 0, s2_ = 0, s3_ = 0
        let a = sc.reduce((a, b) => { return a + b }, 0), b = fg.reduce((a, b) => { return a + b }, 0), c = mc.reduce((a, b) => { return a + b }, 0)
        let total = a + b + c
        if (total !== 0) {
            s1_ = ((a / total) * 100).toFixed(0)
            s2_ = ((b / total) * 100).toFixed(0)
            s3_ = ((c / total) * 100).toFixed(0)
        }
        setTimeout(() => {
            showPieChart('s1_t1', [s1_, s2_, s3_], ['Stationary', 'Fugitive', 'Mobile'])
            showVeritcalGroupBarChart(datasets2, 's1_t2', { labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"], sc_ic, fg_ic, mc_ic, type: 1 })
        }, 500)

    }
    const onSelectedChange = (obj, val) => {
        let site_ids = []
        siteRawList.forEach((i) => {

            if (i.id === selected.country || selected.country === 0) {
                i.locationTwos.forEach((j) => {

                    if (j.id === selected.city || selected.city === 0) {
                        j.locationThrees.forEach((k) => {
                            if (k.id === selected.site || selected.site === 0) {
                                site_ids.push(k.id)
                            }




                        })
                    }
                })
            }
        })
        if (indicator[0].selected === true) {

            updateHRData(site_ids, val)
        } else if (indicator[1].selected === true) {
            if (selected.site === 142 && val === 2023) {
                updatePUEGraph_([142], val)
            } else {
                updatePUEGraph(site_ids, val)
            }

            updateSCGraph(site_ids, val)
            updateS1Graph(site_ids, val)
        }
        setSelected((state) => { return { ...state, [obj]: val } })
    }
    const submittedDateTemplate = (rowData) => {

        return (
            <span>{DateTime.fromISO(rowData.modified_on !== null ? rowData.modified_on : rowData.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs</span>
        )
    }
    const actionTemplate = (rowData) => {

        return (<span className="cur-pointer fw-5 fs-16" onClick={() => { rowData.form_type === 2 ? navigate.push({ pathname: "/cf_input_approve/" + rowData.dcf, state: rowData }) : navigate.push({ pathname: "/data_input_approve/" + rowData.dcf, state: rowData }) }}>Approve</span>)

    }
    const dcfTemplate = (rowData) => {
        return (<span className="cur-pointer text-underline" onClick={() => { rowData.form_type === 2 ? navigate.push({ pathname: "/cf_input_approve/" + rowData.dcf, state: rowData }) : navigate.push({ pathname: "/data_input_approve/" + rowData.dcf, state: rowData }) }}>{rowData.dcf_.title}</span>)

    }
    const submitterTemplate = (rowData) => {
        let text = 'Not Found'
        let index = userList.findIndex(i => i.id === rowData.submitted_by)
        if (index !== -1) {
            text = userList[index].information.empname
        }
        return (
            <span>{text}</span>
        )
    }
    const submitPUE = (item) => {
        Swal.fire({
            html: '<span class="fs-16 fw-5">Are you sure you want to authorize data from this submission? You will not be able to make any further changes or edits.</span>',
            showCancelButton: true,
            backdrop: false,
            confirmButtonColor: 'green',

        })
    }
    const showStackedBarChartIncomplete = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        img.src = require('../../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []

        datasets = chartdata
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()


                let highestArray = []
                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {

                        ds.data.forEach((j, indx) => {

                            let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                            let y = chart.getDatasetMeta(ind).data[indx].y - 6

                            let hindex = highestArray.findIndex((hi) => { return hi.label === indx })
                            if (hindex === -1) {
                                highestArray.push({ label: indx, array: [{ x, y }] })
                            } else {
                                highestArray[hindex].array.push({ x, y })
                            }
                            //    ctx.fillText(indx, chart.getDatasetMeta(ind).data[indx].x - (30 / 2), chart.getDatasetMeta(ind).data[indx].y - 25, 35)



                        })

                    }
                })
                highestArray.forEach((item, ind) => {

                    let filtered_y = item.array.sort((a, b) => { return a.y - b.y })[0]

                    if (custom.incomplete[ind] === null || custom.incomplete[ind] === false) {
                        // ★
                        ctx.fillStyle = "red";
                        ctx.font = "12px Arial";
                        ctx.fillText('●', filtered_y.x, filtered_y.y)
                    }



                })



            }
        }

        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };

        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x',
                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },

                scales: {
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        type: 'linear',
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        }, grid: {
                            drawOnChartArea: true
                        },

                    },
                    x: {

                        stacked: true,
                        grid: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            maxTicksLimit: 12,
                            maxRotation: 60,
                            minRotation: 60,
                        }
                    }
                },
                legend: {
                    display: false,

                },

                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                        // labels: {
                        //     // Define your custom legend labels here
                        //     generateLabels: function(chart) {
                        //       const cdata = chart.data;
                        //       let clabels =[]
                        //       clabels =   cdata.datasets.map(i => i.label) 

                        //       clabels.push('InComplete')

                        //       if (cdata.labels.length && cdata.datasets.length) {
                        //         return clabels.map(function(label, index) {

                        //             const dataset = chart.data.datasets[index];
                        //             if(label === 'InComplete'){
                        //                 return {
                        //                     text: '●',
                        //                     color: 'red',

                        //                     // You can add additional properties as needed
                        //                   };
                        //             }else{
                        //                 return {
                        //                     text: label,
                        //                     fillStyle: dataset.backgroundColor[index],
                        //                     strokeStyle: dataset.borderColor[index],
                        //                     lineWidth: dataset.borderWidth,
                        //                     // You can add additional properties as needed
                        //                   };
                        //             }

                        //         });
                        //       }
                        //       return [];
                        //     }
                        //   }
                    },


                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL, incomplete],
        })

    }
    const showStackedBarChartIncomplete_ = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        img.src = require('../../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []

        datasets = chartdata
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()


                let highestArray = []
                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {

                        ds.data.forEach((j, indx) => {

                            let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                            let y = chart.getDatasetMeta(ind).data[indx].y - 6

                            let hindex = highestArray.findIndex((hi) => { return hi.label === indx })
                            if (hindex === -1) {
                                highestArray.push({ label: indx, array: [{ x, y }] })
                            } else {
                                highestArray[hindex].array.push({ x, y })
                            }
                            //    ctx.fillText(indx, chart.getDatasetMeta(ind).data[indx].x - (30 / 2), chart.getDatasetMeta(ind).data[indx].y - 25, 35)



                        })

                    }
                })
                highestArray.forEach((item, ind) => {

                    let filtered_y = item.array.sort((a, b) => { return a.y - b.y })[0]
                    if (ind === 0 || ind === 1 || ind === 2) {
                        ctx.fillStyle = "deepskyblue";
                        ctx.font = "12px Arial";
                        ctx.fillText('✓', filtered_y.x, filtered_y.y)
                    } else if (custom.incomplete[ind] === null || custom.incomplete[ind] === false) {
                        // ★
                        ctx.fillStyle = "red";
                        ctx.font = "12px Arial";
                        ctx.fillText('●', filtered_y.x, filtered_y.y)
                    }



                })



            }
        }

        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };

        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x',
                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },

                scales: {
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        type: 'linear',
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        }, grid: {
                            drawOnChartArea: true
                        },

                    },
                    x: {

                        stacked: true,
                        grid: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            maxTicksLimit: 12,
                            maxRotation: 60,
                            minRotation: 60,
                        }
                    }
                },
                legend: {
                    display: false,

                },

                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                        // labels: {
                        //     // Define your custom legend labels here
                        //     generateLabels: function(chart) {
                        //       const cdata = chart.data;
                        //       let clabels =[]
                        //       clabels =   cdata.datasets.map(i => i.label) 

                        //       clabels.push('InComplete')

                        //       if (cdata.labels.length && cdata.datasets.length) {
                        //         return clabels.map(function(label, index) {

                        //             const dataset = chart.data.datasets[index];
                        //             if(label === 'InComplete'){
                        //                 return {
                        //                     text: '●',
                        //                     color: 'red',

                        //                     // You can add additional properties as needed
                        //                   };
                        //             }else{
                        //                 return {
                        //                     text: label,
                        //                     fillStyle: dataset.backgroundColor[index],
                        //                     strokeStyle: dataset.borderColor[index],
                        //                     lineWidth: dataset.borderWidth,
                        //                     // You can add additional properties as needed
                        //                   };
                        //             }

                        //         });
                        //       }
                        //       return [];
                        //     }
                        //   }
                    },


                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL, incomplete],
        })

    }
    function getPercentageOf2Array(r, nr) {
        const updatedR = [];
        const updatedNR = [];

        for (let i = 0; i < r.length; i++) {
            if (r[i] !== null || nr[i] !== null) {
                const total = r[i] + nr[i];
                updatedR.push(Math.round((r[i] / total) * 100));
                updatedNR.push(Math.round((nr[i] / total) * 100));
            } else {
                updatedR.push(null);
                updatedNR.push(null);
            }
        }

        return { renew: updatedR, nonrenew: updatedNR };
    }
    const submitterTemplate_ = (rowData) => {
        console.log(userdcfass, rowData)
        let text = 'Not Found'
        let index = userdcfass.findIndex(i => (i.site[0] === rowData.site.id && i.dcfId === rowData.dcf.id))

        if (index !== -1) {
            let index2 = userList.findIndex(i => i.id === userdcfass[index].user_id)
            if (index2 !== -1) {
                text = userList[index2].information.empname
            }
        }
        return (
            <span>{text}</span>
        )
    }
    const filterResponseBySIFY = (array, type, siteIds, dcfIds, year, rpIndex) => {
        return array.filter(item => {
            // Check if the item matches the type
            if (item.type !== type) {
                return false;
            }

            // Check if the site is included in siteIds
            if (!siteIds.includes(item.site)) {
                return false;
            }

            // Check if the dcf is included in dcfIds
            if (!dcfIds.includes(item.dcf)) {
                return false;
            }

            // Get the reporting period based on rpIndex
            const reportingPeriod = rpIndex === 0 ? item.reporting_period[0] : item.reporting_period[item.reporting_period.length - 1];

            // Convert the reporting period string to a Luxon DateTime object
            const reportingPeriodDate = DateTime.fromFormat(reportingPeriod, 'MM-yyyy');

            // Calculate the start date (March of the specified year) and end date (April of the following year)
            const startDate = DateTime.fromObject({ year: year - 1, month: 4 });
            const endDate = DateTime.fromObject({ year: year, month: 3 });
            console.log(startDate.toFormat('LLL-yyyy'), endDate.toFormat('LLL-yyyy'))
            // Check if the reporting period falls within the specified range
            if (reportingPeriodDate >= startDate && reportingPeriodDate <= endDate) {
                return true;
            }

            return false;
        });
    }
    const checkRPbyFY = (rp, year) => {
        const reportingPeriodDate = DateTime.fromFormat(rp, 'MM-yyyy');
        // Calculate the start date (March of the specified year) and end date (April of the following year)
        const startDate = DateTime.fromObject({ year: year - 1, month: 3 });
        const endDate = DateTime.fromObject({ year: year, month: 4 });

        // Check if the reporting period falls within the specified range
        if (reportingPeriodDate >= startDate && reportingPeriodDate <= endDate) {
            return true;
        }
        return false
    }
    const splitDataByMonth = (data, rp_index = 0) => {
        const monthData = {};

        // Iterate over the data array
        data.forEach(item => {
            // Determine the reporting period based on rp_index or default to the first element
            const rp = DateTime.fromFormat(item.reporting_period[rp_index], 'LL-yyyy').toFormat('LLL-yy') || DateTime.fromFormat(item.reporting_period[0], 'LL-yyyy').toFormat('LLL-yy')

            // Initialize an array for the month if it doesn't exist yet
            if (!monthData[rp]) {
                monthData[rp] = [];
            }

            // Check if an item with the same dcf, site, and reporting_period already exists in the array
            const existingItem = monthData[rp].find(existingItem =>
                existingItem.dcf === item.dcf &&
                existingItem.site === item.site &&
                existingItem.reporting_period.toString() === item.reporting_period.toString()
            );

            // If the item does not already exist, push it to the array
            if (!existingItem) {
                monthData[rp].push(item);
            }
        });

        return monthData;
    }
    const updateHRData = (sid, yr) => {
        console.log(sid)
        let site_ids = sid
        let loc_approvedList = approvedListBk.filter(i => { return site_ids.includes(i.site) && i.type === 1 && checkRPbyFY(i.reporting_period[0], yr) && [262, 263].includes(i.dcf) })
        setApprovedList(loc_approvedList)
        let sify = filterResponseBySIFY(loc_approvedList, 1, site_ids, [262, 263], yr, 0)
        let month_obj = splitDataByMonth(sify, 0)
        console.log(month_obj)
        let added = [null, null, null, null, null, null, null, null, null, null, null, null]
        let lastIndex = -1, lastIndex2 = -1

        Object.keys(month_obj).forEach((key, ind) => {
            let month_ind = months.findIndex(i => i.toLowerCase() === key.split('-')[0].toLowerCase())
            if (month_obj[key].filter(i => i.dcf === 262).length !== 0) {
                if (lastIndex < month_ind) {
                    lastIndex = month_ind
                }
            }
            if (month_obj[key].filter(i => i.dcf === 263).length !== 0) {
                console.log(month_obj[key].filter(i => i.dcf === 263))
                if (lastIndex2 < month_ind) {
                    lastIndex2 = month_ind
                }
            }
        })
        if (lastIndex !== -1) {
            let Male_u30 = 0, Male_t50 = 0, Male_a50 = 0, Female_u30 = 0, Female_t50 = 0, Female_a50 = 0
            let last_month_ind = Object.keys(month_obj).findIndex(i => i.split('-')[0].toLowerCase() === months[lastIndex].toLowerCase())
            console.log(Object.values(month_obj)[last_month_ind])
            let dcfindex = Object.values(month_obj)[last_month_ind].findIndex(i => i.dcf === 262)
            let recent_employee_data = Object.values(month_obj)[last_month_ind][dcfindex].response.flatMap(i => i.type2.flatMap(j => j.type3.map(k => k)))
            let male = 0, female = 0

            recent_employee_data.forEach(i => {
                if (i.title === 'Male') {
                    Male_u30 += Object.values(i.data[0])[0]
                    Male_t50 += Object.values(i.data[1])[0]
                    Male_a50 += Object.values(i.data[2])[0]
                    male += i.data.reduce((total, obj) => {
                        const values = Object.values(obj);
                        return total + (values.length > 0 ? values[0] : 0);
                    }, 0);
                } else if (i.title === "Female") {
                    Female_u30 += Object.values(i.data[0])[0]
                    Female_t50 += Object.values(i.data[1])[0]
                    Female_a50 += Object.values(i.data[2])[0]
                    female += i.data.reduce((total, obj) => {
                        const values = Object.values(obj);
                        return total + (values.length > 0 ? values[0] : 0);
                    }, 0);
                }

            })
            let dataset1 = [{
                label: 'Male',
                backgroundColor: color[3],

                data: [Male_a50, Male_t50, Male_u30]
            },
            {
                label: 'Female',
                backgroundColor: color[1],

                borderWidth: 1,
                data: [Female_a50, Female_t50, Female_u30]
            }]
            console.log(dataset1)
            let avg = (male + female)
            setTimeout(() => {
                showDoughnutChart('hr1', [((male / avg) * 100).toFixed(1), ((female / avg) * 100).toFixed(1)], ['Male', 'Female'])
                showHorizontalBarChart(dataset1, 'hr2', { labels: ['Over 50yrs', '30 - 50yrs', 'Under 30yrs'] })
            }, 200)

            console.log(recent_employee_data, male, female)
        } else {

            setTimeout(() => {
                showDummyGraph('hr1', 'No Data Found')
                showDummyGraph('hr2', 'No Data Found')
            }, 200)
        }
        console.log(lastIndex2)
        if (lastIndex2 !== -1) {
            let Male_u30 = 0, Male_t50 = 0, Male_a50 = 0, Female_u30 = 0, Female_t50 = 0, Female_a50 = 0
            let last_month_ind = Object.keys(month_obj).findIndex(i => i.split('-')[0].toLowerCase() === months[lastIndex2].toLowerCase())
            let dcfindex = Object.values(month_obj)[last_month_ind].findIndex(i => i.dcf === 263)
            let recent_employee_data = Object.values(month_obj)[last_month_ind][dcfindex].response.flatMap(i => i.type2)
            let male = 0, female = 0, dataset2_ = [[], [], []]
            Object.values(month_obj)[last_month_ind][dcfindex].response.forEach((item, ind) => {
                if (ind === 0) {
                    dataset2_[0] = [...item.type2[0].data.map(obj => Object.values(obj)[0]), ...item.type2[1].data.map(obj => Object.values(obj)[0])]
                } else if (ind === 1) {
                    dataset2_[1] = [...item.type2[0].data.map(obj => Object.values(obj)[0]), ...item.type2[1].data.map(obj => Object.values(obj)[0])]
                } else if (ind === 2) {
                    dataset2_[2] = [...item.type2[0].data.map(obj => Object.values(obj)[0]), ...item.type2[1].data.map(obj => Object.values(obj)[0])]
                } else if (ind === 3) {
                    let array1 = [...item.type2[0].data.map(obj => Object.values(obj)[0]), ...item.type2[1].data.map(obj => Object.values(obj)[0])]
                    let array2 = dataset2_[2]
                    dataset2_[2] = array1.map((val, index) => val + array2[index])
                }
            })

            recent_employee_data.forEach(i => {

                if (i.title === 'Male') {
                    Male_u30 += Object.values(i.data[0])[0]
                    Male_t50 += Object.values(i.data[1])[0]
                    Male_a50 += Object.values(i.data[2])[0]
                    male += i.data.reduce((total, obj) => {
                        const values = Object.values(obj);
                        return total + (values.length > 0 ? values[0] : 0);
                    }, 0);
                } else if (i.title === "Female") {
                    Female_u30 += Object.values(i.data[0])[0]
                    Female_t50 += Object.values(i.data[1])[0]
                    Female_a50 += Object.values(i.data[2])[0]
                    female += i.data.reduce((total, obj) => {
                        const values = Object.values(obj);
                        return total + (values.length > 0 ? values[0] : 0);
                    }, 0);
                }

            })
            let dataset1 = [{
                label: 'Male',
                backgroundColor: color[3],

                data: [Male_a50, Male_t50, Male_u30]
            },
            {
                label: 'Female',
                backgroundColor: color[1],

                data: [Female_a50, Female_t50, Female_u30]
            }]
            let dataset2 = [{
                label: 'Senior Management',
                backgroundColor: color[0],

                data: dataset2_[0]
            },
            {
                label: 'Mid-Management',
                backgroundColor: color[1],

                data: dataset2_[1]
            }, {
                label: 'Entry-Level',
                backgroundColor: color[3],

                data: dataset2_[2]
            },
            ]
            console.log(dataset2)
            let avg = (male + female)
            setTimeout(() => {
                // ['Male Over 50yrs', 'Male 30 - 50yrs', 'Male Under 30yrs','Female Over 50yrs', 'Female 30 - 50yrs', 'Female Under 30yrs']
                showHorizontalBarChart(dataset1, 'hr3', { labels: ['Over 50yrs', '30 - 50yrs', 'Under 30yrs'] })
                showStackedBarChart(dataset2, 'hr4', { labels: ['>50yrs', '30 - 50yrs', '<30yrs', '>50yrs', '30 - 50yrs', '<30yrs'] })
            }, 200)

            console.log(recent_employee_data, male, female)
        } else {
            setTimeout(() => {
                showDummyGraph('hr3', 'No Data Found')
                showDummyGraph('hr4', 'No Data Found')
            }, 200)
        }
    }
    const showDummyGraph = (canvasId, text) => {
        if (Chart.getChart(canvasId) !== undefined) {
            Chart.getChart(canvasId).destroy();
        }
        const canvas = document.getElementById(canvasId);
        const ctx = canvas.getContext('2d');
        const fontSize = 20; // Adjust the font size as needed
        ctx.font = `${fontSize}px Arial`;

        // Measure the width of the text
        const textWidth = ctx.measureText(text).width;

        // Calculate the center coordinates
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;

        // Set the text alignment to center
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        // Draw the text at the center
        ctx.fillText(text, centerX, centerY);
    }
    const showVeritcalGroupBarChart = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        // img.src = require('../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []

        datasets = chartdata


        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()


                let highestArray = []
                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {

                        ds.data.forEach((j, indx) => {

                            let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                            let y = chart.getDatasetMeta(ind).data[indx].y - 6
                            if (custom.type === 0) {
                                if (custom.incomplete[indx] === null || custom.incomplete[indx] === false) {
                                    // ★
                                    ctx.fillStyle = "red";
                                    ctx.font = "12px Arial";
                                    ctx.fillText('●', x, y)
                                }
                            } else if (custom.type === 1) {

                                if (ind === 0) {
                                    console.log(ind, custom.sc_ic)
                                    if (custom.sc_ic[indx] === null || custom.sc_ic[indx] === false) {
                                        // ★
                                        ctx.fillStyle = "red";
                                        ctx.font = "12px Arial";
                                        ctx.fillText('●', x, y)
                                    }
                                } else if (ind === 1) {
                                    console.log(ind, custom.fg_ic)
                                    if (custom.fg_ic[indx] === null || custom.fg_ic[indx] === false) {
                                        // ★
                                        ctx.fillStyle = "red";
                                        ctx.font = "12px Arial";
                                        ctx.fillText('●', x, y)
                                    }
                                } else if (ind === 2) {
                                    console.log(ind, custom.mc_ic)
                                    if (custom.mc_ic[indx] === null || custom.mc_ic[indx] === false) {
                                        // ★
                                        ctx.fillStyle = "red";
                                        ctx.font = "12px Arial";
                                        ctx.fillText('●', x, y)
                                    }
                                }
                            }

                            //    ctx.fillText(indx, chart.getDatasetMeta(ind).data[indx].x - (30 / 2), chart.getDatasetMeta(ind).data[indx].y - 25, 35)



                        })

                    }
                })




            }
        }

        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                datasets
            },
            options: {

                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x'
                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },


                legend: {
                    display: false,
                },

                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    labels: {
                        render: (args) => {
                            return "";
                        },
                    },

                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL, incomplete],
        })

    }
    const showHorizontalBarChart = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        // img.src = require('../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []

        datasets = chartdata


        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()


                let highestArray = []
                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {

                        ds.data.forEach((j, indx) => {

                            let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                            let y = chart.getDatasetMeta(ind).data[indx].y - 6
                            ctx.fillStyle = "red";
                            ctx.font = "12px Arial";
                            ctx.fillText(j, x + 8, y + 8)

                        })

                    }
                })

            }
        }

        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                datasets
            },
            options: {
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x'
                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },


                legend: {
                    display: false,
                },

                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    labels: {
                        render: (args) => {
                            return "";
                        },
                    },

                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL, incomplete],
        })

    }
    const showStackedBarChart = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        // img.src = require('../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []

        datasets = chartdata


        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };
        const category = {
            id: 'category',
            afterDraw: (chart) => {
                const ctx = chart.ctx;
                const xAxis = chart.scales['x'];
                const yAxis = chart.scales['y'];
                const chartWidth = xAxis.right - xAxis.left;
                const chartHeight = yAxis.bottom - yAxis.top;
                const xOffset = xAxis.left + chartWidth / 4; // Half of the chart width
                const yOffset = yAxis.bottom + 25; // Adjust the offset as needed
                ctx.save();
                ctx.textAlign = 'center';
                ctx.textBaseline = 'top';
                ctx.fillStyle = 'black'; // Adjust the color as needed
                ctx.fillText('Male', xOffset, yOffset);
                ctx.fillText('Female', xOffset + chartWidth / 2, yOffset);
                ctx.restore();
            }
        }
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {

                const { ctx, chartArea } = chart;
                ctx.save();
                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {
                        ds.data.forEach((dataset, datasetIndex) => {
                            const meta = chart.getDatasetMeta(datasetIndex);

                            let x = chart.getDatasetMeta(ind).data[datasetIndex].x
                            let y = (chart.getDatasetMeta(ind).data[datasetIndex].base + chart.getDatasetMeta(ind).data[datasetIndex].y) / 2;
                            const data = dataset;
                            const text = data.toString(); // Get the label text

                            ctx.fillStyle = '#000'; // Set text color
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            ctx.fillText(text, x, y);

                        });
                    }
                })


                ctx.restore();

            }
        }
        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x',
                    },
                },
                layout: {
                    padding: {
                        top: 0
                    }
                },

                scales: {
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        type: 'linear',
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        }, grid: {
                            drawOnChartArea: true
                        },

                    },
                    x: {

                        stacked: true,
                        grid: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            minRotation: 0,

                        }
                    }
                },
                legend: {
                    display: false,
                },

                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    labels: {
                        render: (args) => {
                            return "";
                        },
                    },

                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL, incomplete, category],
        })

    }
    const showStackedBarChartScope2 = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        // img.src = require('../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []

        datasets = chartdata


        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()


                let highestArray = []
                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {

                        ds.data.forEach((j, indx) => {

                            let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                            let y = chart.getDatasetMeta(ind).data[indx].y - 6

                            let hindex = highestArray.findIndex((hi) => { return hi.label === indx })
                            if (hindex === -1) {
                                highestArray.push({ label: indx, array: [{ x, y }] })
                            } else {
                                highestArray[hindex].array.push({ x, y })
                            }
                            //    ctx.fillText(indx, chart.getDatasetMeta(ind).data[indx].x - (30 / 2), chart.getDatasetMeta(ind).data[indx].y - 25, 35)



                        })

                    }
                })
                highestArray.forEach((item, ind) => {

                    let filtered_y = item.array.sort((a, b) => { return a.y - b.y })[0]

                    if (custom.incomplete[ind] === null || custom.incomplete[ind] === false) {
                        // ★
                        ctx.fillStyle = "red";
                        ctx.font = "12px Arial";
                        ctx.fillText('●', filtered_y.x, filtered_y.y)
                    }



                })



            }
        }
        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x',
                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },

                scales: {
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        type: 'linear',
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        }, grid: {
                            drawOnChartArea: true
                        },

                    },
                    x: {

                        stacked: true,
                        grid: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            maxTicksLimit: 12,
                            maxRotation: 60,
                            minRotation: 60,

                        }
                    }
                },
                legend: {
                    display: false,
                },

                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    labels: {
                        render: (args) => {
                            return "";
                        },
                    },

                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL, incomplete],
        })

    }
    const showStackedBarChartScope2_ = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        // img.src = require('../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []

        datasets = chartdata


        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()


                let highestArray = []
                scope1c.config.data.datasets.forEach((ds, ind) => {
                    if (scope1c.isDatasetVisible(ind)) {

                        ds.data.forEach((j, indx) => {

                            let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                            let y = chart.getDatasetMeta(ind).data[indx].y - 6

                            let hindex = highestArray.findIndex((hi) => { return hi.label === indx })
                            if (hindex === -1) {
                                highestArray.push({ label: indx, array: [{ x, y }] })
                            } else {
                                highestArray[hindex].array.push({ x, y })
                            }
                            //    ctx.fillText(indx, chart.getDatasetMeta(ind).data[indx].x - (30 / 2), chart.getDatasetMeta(ind).data[indx].y - 25, 35)



                        })

                    }
                })
                highestArray.forEach((item, ind) => {

                    let filtered_y = item.array.sort((a, b) => { return a.y - b.y })[0]

                    if (ind === 0 || ind === 1 || ind === 2) {
                        ctx.fillStyle = "deepskyblue";
                        ctx.font = "12px Arial";
                        ctx.fillText('✓', filtered_y.x, filtered_y.y)
                    } else if (custom.incomplete[ind] === null || custom.incomplete[ind] === false) {
                        // ★
                        ctx.fillStyle = "red";
                        ctx.font = "12px Arial";
                        ctx.fillText('●', filtered_y.x, filtered_y.y)
                    }



                })



            }
        }
        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x',
                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },

                scales: {
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        type: 'linear',
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        }, grid: {
                            drawOnChartArea: true
                        },

                    },
                    x: {

                        stacked: true,
                        grid: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            maxTicksLimit: 12,
                            maxRotation: 60,
                            minRotation: 60,

                        }
                    }
                },
                legend: {
                    display: false,
                },

                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    labels: {
                        render: (args) => {
                            return "";
                        },
                    },

                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL, incomplete],
        })

    }
    const showDoughnutChart = (id, chartdata, labels, custom) => {
        let chartref = document.getElementById(id);
        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        let scope1 = chartref.getContext("2d");
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {

                const { ctx, data, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;
                const { datasets } = data;
                console.log(datasets)
                const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                const originalRadius = chart.width / 2;
                const innerRadius = chart.chartArea.width / 24;
                const reducedRadiusPercentage = 0.8;
                const adjustedRadius = (originalRadius - innerRadius) * reducedRadiusPercentage + innerRadius;
                ctx.save()
                if (scope1c !== undefined) {
                    console.log(scope1c)
                    scope1c.config.data.datasets.forEach((ds, ind) => {
                        if (scope1c.isDatasetVisible(ind)) {

                            ds.data.forEach((element, indx) => {

                                const startAngle = chart.getDatasetMeta(0).data[indx].startAngle;
                                const angle = startAngle + (Math.PI * 2 * element) / 100 / 2;

                                const x = centerX + Math.cos(angle) * adjustedRadius * 0.5;
                                const y = centerY + Math.sin(angle) * adjustedRadius * 0.5;
                                ctx.textAlign = 'center';
                                ctx.textBaseline = 'middle';
                                ctx.fillStyle = '#fff';
                                const label = element;

                                console.log(label)

                                ctx.fillText(label + ' %', x, y);



                            });
                        }
                    });


                }





            }
        }
        var scope1c = new Chart(scope1, {
            type: 'doughnut',
            data: {
                labels,
                datasets: [{
                    data: chartdata,
                    backgroundColor: [color[3], color[1]]
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                plugins: {
                    legend: {
                        onClick: () => { },
                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },

                    },


                },
                animation: {
                    animateRotate: true,
                    animateScale: true,
                    duration: 2500,
                },
            },
            plugins: [ChartDL, incomplete]
        });


    }
    const showPieChart = (id, chartdata, labels, custom) => {
        let chartref = document.getElementById(id);
        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        let scope1 = chartref.getContext("2d");
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {

                const { ctx, data, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;
                const { datasets } = data;
                console.log(datasets)
                const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                const originalRadius = chart.width / 2;
                const innerRadius = chart.chartArea.width / 24;
                const reducedRadiusPercentage = 0.8;
                const adjustedRadius = (originalRadius - innerRadius) * reducedRadiusPercentage + innerRadius;
                ctx.save()
                if (scope1c !== undefined) {
                    console.log(scope1c)
                    scope1c.config.data.datasets.forEach((ds, ind) => {
                        if (scope1c.isDatasetVisible(ind)) {

                            ds.data.forEach((element, indx) => {

                                const startAngle = chart.getDatasetMeta(0).data[indx].startAngle;
                                const angle = startAngle + (Math.PI * 2 * element) / 100 / 2;

                                const x = centerX + Math.cos(angle) * adjustedRadius * 0.5;
                                const y = centerY + Math.sin(angle) * adjustedRadius * 0.5;
                                ctx.textAlign = 'center';
                                ctx.textBaseline = 'middle';
                                ctx.fillStyle = '#fff';
                                const label = element;

                                console.log(label)

                                ctx.fillText(label + ' %', x, y);



                            });
                        }
                    });


                }





            }
        }
        var scope1c = new Chart(scope1, {
            type: 'pie',
            data: {
                labels,
                datasets: [{
                    data: chartdata,
                    backgroundColor: [color[0], color[2], color[4]]
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                plugins: {
                    legend: {
                        onClick: () => { },
                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },

                    },


                },
                animation: {
                    animateRotate: true,
                    animateScale: true,
                    duration: 2500,
                },
            },
            plugins: [ChartDL, incomplete]
        });


    }
    const openItem = (index, view) => {
        let site_ids = []
        let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
        siteRawList.forEach((i) => {
            country.push({ name: i.name, id: i.id })
            i.locationTwos.forEach((j) => {
                city.push({ name: j.name, id: j.id })
                j.locationThrees.forEach((k) => {
                    site.push({ name: k.name, id: k.id })
                    site_ids.push(k.id)
                })
            })
        })
        setSelectedList({ country, city, site })
        setSelected({ ...selected, country: 0, city: 0, site: 0 })

        if (index === 0 && view !== true) {

            updateHRData(site_ids, selected.year)



        } else if (index === 1 && view !== true) {

            updatePUEGraph(site_ids, selected.year)

            updateSCGraph(site_ids, selected.year)

            updateS1Graph(site_ids, selected.year)
        }
        indicator.forEach((item, ind) => {
            if (ind === index) {

                item.selected = item.selected === undefined ? true : !item.selected;
                forceUpdate()

            } else {
                item.selected = false
            }
        })

        forceUpdate()
    }
    const updateSiteSelection = (obj, val) => {
        let loc = selected
        loc[obj] = val
        let site_ids = []
        if (obj === 'country') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === val || val === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        j.locationThrees.forEach((k) => {
                            site_ids.push(k.id)
                            site.push({ name: k.name, id: k.id })
                        })
                    })
                }
            })
            loc.city = 0
            loc.site = 0
            setSelectedList({ country, city, site })
        } else if (obj === 'city') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === val || val === 0) {
                            j.locationThrees.forEach((k) => {
                                site_ids.push(k.id)
                                site.push({ name: k.name, id: k.id })
                            })
                        }
                    })
                }
            })

            loc.site = 0
            setSelectedList({ country, city, site })
        } else if (obj === 'site') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === loc.city || loc.city === 0) {
                            j.locationThrees.forEach((k) => {
                                if (k.id === val || val === 0) {
                                    site_ids.push(k.id)
                                }

                                site.push({ name: k.name, id: k.id })


                            })
                        }
                    })
                }
            })
            setSelectedList({ country, city, site })
        }

        updateHRData(site_ids, selected.year)
        setSelected(loc)
        forceUpdate()
    }
    const updateSiteSelection_ = (obj, val) => {
        let loc = selected
        loc[obj] = val
        let site_ids = []
        if (obj === 'country') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === val || val === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        j.locationThrees.forEach((k) => {
                            site_ids.push(k.id)
                            site.push({ name: k.name, id: k.id })
                        })
                    })
                }
            })
            loc.city = 0
            loc.site = 0
            setSelectedList({ country, city, site })
        } else if (obj === 'city') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === val || val === 0) {
                            j.locationThrees.forEach((k) => {
                                site_ids.push(k.id)
                                site.push({ name: k.name, id: k.id })
                            })
                        }
                    })
                }
            })

            loc.site = 0
            setSelectedList({ country, city, site })
        } else if (obj === 'site') {
            let country = [{ name: 'All', id: 0 }], city = [{ name: 'All', id: 0 }], site = [{ name: 'All', id: 0 }]
            siteRawList.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.country || loc.country === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === loc.city || loc.city === 0) {
                            j.locationThrees.forEach((k) => {
                                if (k.id === val || val === 0) {
                                    site_ids.push(k.id)
                                }
                                site.push({ name: k.name, id: k.id })


                            })
                        }
                    })
                }
            })
            setSelectedList({ country, city, site })
        }
        if (obj === 'site' && val === 142 && selected.year === 2023) {
            updatePUEGraph_(site_ids, selected.year)
        } else {
            updatePUEGraph(site_ids, selected.year)
        }

        updateSCGraph(site_ids, selected.year)
        updateS1Graph(site_ids, selected.year)
        setSelected(loc)
        forceUpdate()
    }
    return (
        <div className="col-12 font-lato">
            <div >
                <div className="col-12 flex justify-content-center" >
                    <label className="text-big-one clr-navy flex fs-20 fw-6 flex justify-content-start" >Data Approver
                    </label>
                </div>
                <div className="col-12 grid" >
                    <div className='col-6 grid align-items-center'>
                        <div className="col-3">
                            <label className="fs-16 fw-5">Select Year</label>
                        </div>
                        <div className="col-8">
                            <Dropdown optionLabel="name" optionValue="year" style={{ width: 200 }} options={[{ name: '2021-2022', year: 2022 }, { name: '2022-2023', year: 2023 }, { name: '2023-2024', year: 2024 }]} value={selected.year} onChange={(e) => { onSelectedChange('year', e.value) }} />
                        </div>
                    </div>

                </div>
                <div>
                    <label className="text-big-one clr-gray-900 fw-7 p-2 flex fs-16 flex"> Your Approvals </label>
                    <div>
                        <div className="col-12 grid">
                            <div className="col-4">
                                <div className="admin-card flex justify-content-between" onClick={() => { setActive(0) }} style={{ height: 150, background: active === 0 ? 'lightgrey' : 'white' }}>
                                    <div className="justify-content-between grid">
                                        <div className="big-number-navy fs-36">3</div>
                                        <div className="icon-layout" style={{ background: 'rgba(128, 234, 213, 0.20)' }}>
                                            <img src={require('../../assets/images/newui/admin/barchart.png').default} width={24} height={23} />

                                        </div>

                                    </div>
                                    <div >
                                        <div className="text-bold clr-navy fs-16"></div>

                                    </div>
                                    <div className="justify-content-between grid">
                                        <div className="clr-navy fs-12 text-bold">QUANTITATIVE INDICATORS</div>
                                        <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-4">
                                <div className="admin-card flex justify-content-between" onClick={() => { setActive(1) }} style={{ height: 150, background: active === 1 ? 'lightgrey' : 'white' }}>
                                    <div className="justify-content-between grid">
                                        <div className="big-number-navy fs-36">1</div>
                                        <div className="icon-layout" style={{ background: 'rgba(128, 234, 213, 0.20)' }}>
                                            <img src={require('../../assets/images/newui/admin/barchart.png').default} width={24} height={23} />
                                        </div>

                                    </div>
                                    <div >
                                        <div className="text-bold clr-navy fs-16"></div>

                                    </div>
                                    <div className="justify-content-between grid">
                                        <div className="clr-navy fs-12 text-bold">QUALITATIVE INDICATORS</div>
                                        <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-4">
                                <div className="admin-card flex justify-content-between" onClick={() => { setActive(2) }} style={{ height: 150, background: active === 2 ? 'lightgrey' : 'white' }}>
                                    <div className="justify-content-between grid">
                                        <div className="big-number-navy fs-36">76 %</div>
                                        <div className="icon-layout" style={{ background: 'rgba(128, 234, 213, 0.20)' }}>
                                            <img src={require('../../assets/images/newui/admin/barchart.png').default} width={24} height={23} />

                                        </div>

                                    </div>
                                    <div >
                                        <div className="text-bold clr-navy fs-16"></div>

                                    </div>
                                    <div className="justify-content-between grid">
                                        <div className="clr-navy fs-12 text-bold">PENDING DATA SUBMISSIONS</div>
                                        <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {active === 0 && <div>{indicator.map((item, index) => {
                    return (
                        <div>
                            <div onClick={() => { openItem(index, item.selected) }} className="p-2 m-2 fw-5 fs-16 flex br-1 justify-content-between align-items-center bg-navy" style={{ color: 'white' }}> <div> {item.title} - {selected.year}  </div>
                                {(item.selected === false || item.selected === undefined) ? <i style={{
                                    background: 'white',
                                    color: '#003153',
                                    padding: '3px',
                                    borderRadius: '20px'
                                }} className="material-icons">navigate_next</i>
                                    : <i className="material-icons" style={{
                                        background: 'white',
                                        color: '#003153',
                                        padding: '3px',
                                        borderRadius: '20px'
                                    }}>expand_less</i>
                                }

                            </div>
                            {item.selected && index === 0 && <div>
                                <div className="col-12 grid">
                                    <div className='col-4 grid align-items-center'>
                                        <div className="col-3">
                                            <label className="fs-16 fw-5"> Country</label>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.country} options={selectedList.country} onChange={(e) => { updateSiteSelection('country', e.value) }} />
                                        </div>
                                    </div>
                                    <div className='col-4 grid align-items-center'>
                                        <div className="col-3">
                                            <label className="fs-16 fw-5">Functional Unit</label>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.city} options={selectedList.city} onChange={(e) => { updateSiteSelection('city', e.value) }} />
                                        </div>
                                    </div>
                                    <div className='col-4 grid align-items-center'>
                                        <div className="col-3">
                                            <label className="fs-16 fw-5">Site</label>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.site} options={selectedList.site} onChange={(e) => { updateSiteSelection('site', e.value) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 grid ">
                                    <div className="col-6 flex justify-content-center" >
                                        <label className="clr-stt fw-7 fs-16">Employee Gender Breakup %</label>
                                    </div>
                                    <div className="col-6 flex justify-content-center">
                                        <label className="clr-stt fw-7 fs-16">Employee Diversity by Age group & Gender</label>
                                    </div>

                                </div>
                                <div className="col-12 grid ">
                                    <div className="col-6 flex justify-content-center align-items-center" >
                                        <canvas id='hr1' className="col-4" />
                                        {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                    </div>
                                    <div className="col-6 flex justify-content-center align-items-center">
                                        <canvas id='hr2' className="col-4" />
                                        {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_2_i.png').default} /> */}
                                    </div>


                                </div>
                                <div className="col-12 grid">
                                    <div className="col-6 flex justify-content-center">
                                        <label className="clr-stt fw-7 fs-16">Employee Diversity by Age  & Category</label>
                                    </div>
                                    <div className="col-6 flex justify-content-center" >
                                        <label className="clr-stt fw-7 fs-16">Diversity by employee category, gender & age</label>
                                    </div>

                                </div>
                                <div className="col-12 grid ">
                                    <div className="col-6 flex justify-content-center align-items-center">
                                        <canvas id='hr3' className="col-4" />
                                        {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_2_i.png').default} /> */}
                                    </div>
                                    <div className="col-6 flex justify-content-center align-items-center" >
                                        <canvas id='hr4' className="col-4" />
                                        {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                    </div>


                                </div>
                            </div>

                            }
                            {item.selected && index === 1 && <div>
                                <div className="col-12 grid">
                                    <div className='col-4 grid align-items-center'>
                                        <div className="col-3">
                                            <label className="fs-16 fw-5">Country</label>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.country} options={selectedList.country} onChange={(e) => { updateSiteSelection_('country', e.value) }} />
                                        </div>
                                    </div>
                                    <div className='col-4 grid align-items-center'>
                                        <div className="col-3">
                                            <label className="fs-16 fw-5">Functional Unit</label>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.city} options={selectedList.city} onChange={(e) => { updateSiteSelection_('city', e.value) }} />
                                        </div>
                                    </div>
                                    <div className='col-4 grid align-items-center'>
                                        <div className="col-3">
                                            <label className="fs-16 fw-5">Site</label>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selected.site} options={selectedList.site} onChange={(e) => { updateSiteSelection_('site', e.value) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 grid ">
                                    <div className="col-6 flex justify-content-center" >
                                        <label className="clr-stt fw-7 fs-16">Energy Consumption Mix</label>
                                    </div>
                                    <div className="col-6 flex justify-content-center">
                                        <label className="clr-stt fw-7 fs-16">Energy Consumption Trends</label>
                                    </div>

                                </div>

                                <div className="col-12 grid ">
                                    <div className="col-6" >
                                        <canvas id='s2_1' className="col-4" />
                                        {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                    </div>
                                    <div className="col-6">
                                        <canvas id='s2_2' className="col-4" />
                                        {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_2_i.png').default} /> */}
                                    </div>

                                </div>

                                <div className="col-12 grid ">
                                    <div className="col-4 flex justify-content-center" >
                                        <a onClick={() => { setShowAppDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Computation data sources</a>
                                    </div>
                                    <div className="col-4 flex justify-content-center">
                                        <span className="fw-5 fs-16"> <span className="clr-delete ">● - Incomplete data</span>  <span className="ml-2" style={{ color: 'deepskyblue' }}>✓ - Authorised data</span> </span>
                                    </div>
                                    <div className="col-4 flex justify-content-center">
                                        <a onClick={() => { setShowPenDialog(true) }} className="text-underline fw-5 fs-16 cur-pointer">Missing data inputs </a>
                                    </div>

                                </div>
                                {selected.site !== 0 && 
                                <div className='col-12 flex justify-content-between'>
                                    {(selected.site === 142 && selected.year === 2023) ? <div className="col-3"></div> : <Button title="Approve" disabled={status.one !== true} onClick={() => { submitPUE(1) }} label="Approve (Q1)" className={"col-3 br-1 " + (status.one ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />}
                                    <Button title="Approve" disabled={status.two !== true} onClick={() => { submitPUE(1) }} label="Approve (Q2)" className={"col-3 br-1 " + (status.two ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />
                                    <Button title="Approve" disabled={status.three !== true} onClick={() => { submitPUE(1) }} label="Approve (Q3)" className={"col-3 br-1 " + (status.three ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />
                                    <Button title="Approve" disabled={status.four !== true} onClick={() => { submitPUE(1) }} label="Approve (Q4)" className={"col-3 br-1 " + (status.four ? "active-btn bg-navy" : 'inactive-btn')} style={{ color: "white" }} />
                                </div>}
                                <div className="col-12 grid ">
                                    <div className="col-6 flex justify-content-center" >
                                        <label className="clr-stt fw-7 fs-16">Fuel Usage ( Tonnes )</label>
                                    </div>
                                    <div className="col-6 flex justify-content-center">
                                        <label className="clr-stt fw-7 fs-16">Energy Consumption (TJ)</label>
                                    </div>

                                </div>

                                <div className="col-12 grid ">
                                    <div className="col-6" >
                                        <canvas id='s1_1' className="col-4" />
                                        {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                    </div>
                                    <div className="col-6">
                                        <canvas id='s1_2' className="col-4" />
                                        {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_2_i.png').default} /> */}
                                    </div>

                                </div>
                                <div className="col-12 grid ">
                                    <div className="col-6 flex justify-content-center" >
                                        <label className="clr-stt fw-7 fs-16">Distribution of Scope 1 Emissions</label>
                                    </div>
                                    <div className="col-6 flex justify-content-center">
                                        <label className="clr-stt fw-7 fs-16">Scope 1 Emissions</label>
                                    </div>

                                </div>

                                <div className="col-12 grid ">
                                    <div className="col-6" >
                                        <canvas id='s1_t1' className="col-4" />
                                        {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_3.png').default} /> */}
                                    </div>
                                    <div className="col-6">
                                        <canvas id='s1_t2' className="col-4" />
                                        {/* <img width={'100%'} style={{ maxHeight: 180 }} src={require('../../assets/images/pue_2_i.png').default} /> */}
                                    </div>

                                </div>
                            </div>

                            }
                        </div>
                    )
                })
                }

                </div>}

            </div>
            <Dialog visible={showappdialog} header={'Data Sources (' + approvedList.length + ')'} style={{ width: '80%' }} onHide={() => { setShowAppDialog(false) }} className="dialog-box" >
                <div >
                    <DataTable scrollable value={approvedList} >
                        <Column header='Form' body={dcfTemplate} />
                        <Column header='Site' field='site_.name' />
                        <Column header='Reporting Period' field='rp' />
                        <Column header='Submitter' body={submitterTemplate} />
                        <Column header='Submitted On' body={submittedDateTemplate} />
                    </DataTable>
                </div>
            </Dialog>
            <Dialog visible={showpendialog} header={'Pending data submissions (' + pendingList.length + ')'} style={{ width: '80%' }} onHide={() => { setShowPenDialog(false) }} className="dialog-box" >
                <div >
                    <DataTable scrollable value={pendingList} >
                        <Column header='Form' field='dcf.title' />
                        <Column header='Site' field='site.name' />
                        <Column header='Reporting Period' field='reporting_period' />
                        <Column header='Submitter' body={submitterTemplate_} />
                        <Column header='Overdue Day(s)' field='overdue' />

                    </DataTable>
                </div>
            </Dialog>
        </div>
    )
}

export default Approver