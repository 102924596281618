import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';
import { Legend } from 'recharts';

const goals = [
    { label: 'Embed sustainability into Supply Chain', value: 'supply_chain' },
    { label: 'Improve energy efficiency', value: 'energy_efficiency' },
    // Add more goals as needed
];

const targets = [
    { name: 'Sustainability procurement certification', value: 34, status: 'Achieved' },
    { name: 'Reduce freshwater consumption in operations', value: 34, status: 'Under progress' },
    // Add more targets as needed
];

const GoalTargetsComponent = () => {
    const [selectedGoal, setSelectedGoal] = React.useState(goals[0].value);

    const legendData = [
        { name: 'Under progress', color: '#F5C37B' },
        { name: 'Achieved', color: '#29C76F' },
        { name: 'Completed', color: '#5B8FF7' },
        { name: 'Ahead of time', color: '#315975' },
        { name: 'Delayed', color: '#EE5724' }
    ];

    return (
        <div className="card">
            <h3>Goals, Targets and Initiatives</h3>
            <p>Track, analyze, and optimize your organization's goals, targets, and initiatives</p>
            <hr />
            <div className="legend">
                {legendData.map((item, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
                        <i className="pi pi-circle-on" style={{ color: item.color, marginRight: '5px' }}></i>
                        {item.name}
                    </div>
                ))}
            </div>

            <div className='row'>
                <div className='col'>
                    <div className="goals-section">
                        <h4>Goals</h4>
                        <p>Select goal to know about its employee distribution</p>
                        <Dropdown
                            value={selectedGoal}
                            options={goals}
                            onChange={(e) => setSelectedGoal(e.value)}
                            placeholder="Select a Goal"
                        />
                    </div>
                </div>
                <div className='col'>
                    <div className="targets-section">
                        <h4>Targets</h4>
                        {targets.map((target, index) => (
                            <div key={index} style={{ marginBottom: '20px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                    <i className="pi pi-circle-on" style={{ color: legendData.find(item => item.name === target.status).color, marginRight: '5px' }}></i>
                                    {target.name}
                                </div>
                                <ProgressBar value={target.value} color={legendData.find(item => item.name === target.status).color} />
                                <div style={{ marginTop: '5px' }}>{`+${target.value}% achieved`}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>



        </div>
    );
};

export default GoalTargetsComponent;
