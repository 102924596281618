import React, { useEffect, useState } from "react";
import Editable from "react-bootstrap-editable";
import Swal from "sweetalert2";
import '../Styles/ui_ux.css' 
import { Tag } from 'primereact/tag';
        

const List = (props) => {

    const handleDelete = (id) => {
        const customSwal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-secondary'
            },
            buttonsStyling: false
          })


          const customSwal2 = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-primary',
              
            },
            buttonsStyling: false
          })
          customSwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
          
            confirmButtonText: 'Delete'
          }).then((result) => {
            if (result.isConfirmed) {
              props.onDelete(id)
              customSwal2.fire(
                'Deleted!',
                '',
                'success'
              )
            }
          })
        // Swal.fire({
        //     title: 'Do you want to delete?',
        //     showCancelButton: true,
        //     confirmButtonText: 'Delete',
        //     confirmButtonColor: '#d33',
        //     cancelButtonColor: '#3085d6',
           
        //   }).then((result) => {
        //     /* Read more about isConfirmed, isDenied below */
        //     console.log(result)
        //     if (result.value) {
        //       props.onDelete(id)
        //       Swal.fire('Deleted!', '', 'success')
        //     } 
        //   })
       
    }

  
    
    return (
        <>
        {
            props.topics.map((topic) => {
                return (
                    <div key={topic.id} onClick={(e) => {props.onClick(e, topic.id)}} className={"cursor-pointer mail-list " +  (props.listId === topic.id ? "active" : "")}>
           
                          
                            {/* <div className="details"> */}
                                {/* <p className="sender-name pencil-icon" > Add highlighted text for subtopics */}
                                    {props.isSubTopic === true && topic.clients === null ? <Tag severity="info" value="Default" rounded ></Tag> :''}
                                    {props.isSubTopic === true && topic.clients !== null ? <Tag severity="danger" value="Clients" rounded></Tag> :''}
                                {/* </p> */}
                            {/* </div> */}
                            <div className="content">
                                <p className="sender-name pencil-icon">

                                      <Editable
                                          onSubmit={(value) => props.onListChange(value, topic.id)}
                                          initialValue={topic.name}
                                          mode="inline"
                                          
                                      />
                                      
                                  </p>
                              
                            </div>
                            
                            
                            <div className="details">
                                <i onClick={(e) => handleDelete(topic.id)} className="mdi mdi-delete text-danger"></i>
                                <i className="mdi mdi-chevron-right"></i>
                            </div>
                            
                    </div>
                )
            })
        }
       
        
       
        </>
        
    );
}

export default List;


